"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var TooltipController = /** @class */ (function (_super) {
    __extends(TooltipController, _super);
    function TooltipController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.connect = function () {
            if (_this.hasTriggerTarget && _this.triggerTarget) {
                _this.triggerTarget.addEventListener('mouseenter', _this.showTooltip);
                _this.triggerTarget.addEventListener('mouseleave', _this.hideTooltip);
            }
        };
        _this.showTooltip = function () {
            if (_this.hasTooltipTarget && _this.tooltipTarget) {
                var toggleClass = _this.data.get('toggle-class');
                if (toggleClass) {
                    _this.tooltipTarget.classList.add(toggleClass);
                }
            }
        };
        _this.hideTooltip = function () {
            if (_this.hasTooltipTarget && _this.tooltipTarget) {
                var toggleClass = _this.data.get('toggle-class');
                if (toggleClass) {
                    _this.tooltipTarget.classList.remove(toggleClass);
                }
            }
        };
        return _this;
    }
    TooltipController.targets = ['trigger', 'tooltip'];
    return TooltipController;
}(stimulus_1.Controller));
exports.default = TooltipController;
