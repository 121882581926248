// @flow
import React from 'react'
import Modal from 'react-responsive-modal'
import {observer} from 'mobx-react'
import FormBuilder from './form_builder'
import FormModalPreview from './form_with_modal_preview/FormModalPreview'
import FormWithModalPreviewStore, {type Props} from '~/stores/forms/form_with_modal_preview_store.js'

type State = {
  store: FormWithModalPreviewStore
}

@observer
class FormWithModalPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      store: new FormWithModalPreviewStore(props)
    }
  }

  render() {
    const {store} = this.state

    return (
      <div className="formsFormWithModalPreview-Form">
        <div className="formsFormWithModalPreview-Form_Title">{store.title}</div>
        <FormBuilder store={store.formBuilderStore}/>
        {store.invalid && <p className="formsFormWithModalPreview-Form_Alert">入力されていない必須項目があります</p>}
        <div className="formsFormWithModalPreview-Form_Trigger" onClick={store.openModal}>
          確認画面へ
        </div>
        <Modal
          open={store.open}
          onClose={store.closeModal}
          center
          showCloseIcon={false}
          classNames={{modal: "formsFormWithModalPreview-PreviewModal"}}
        >
          <FormModalPreview
            action={store.action}
            method={store.method}
            inputStores={store.formBuilderStore.inputStores}
            closeModal={store.closeModal}
            onSubmit={store.onSubmit}
          />
        </Modal>
      </div>
    )
  }
}

export default FormWithModalPreview
