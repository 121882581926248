import Validator from '~/validator/validator'
import DialogStore from '~/stores/dialog_store'
import {observable} from 'mobx'

// FIXME: review_store_base に寄せていきたい
export default class ReviewFormStoreBase {
  get STATE_INIT() {
    return 0
  }
  get STATE_IDLE() {
    return 1
  }
  get STATE_SUCCESS() {
    return 2
  }

  @observable services = [];
  @observable user_info = null;
  @observable state = 0;
  @observable reviewId = 0;
  @observable defaultServiceId = 0;
  @observable selectedServiceId = 0;
  @observable rating = 0;
  @observable rateStarHoverIndex = null;
  @observable requireEmailInput = false;
  @observable nickname = '';
  @observable email = '';
  @observable content = '';
  @observable userInputEnterpriseName = '';
  @observable userInputMapName = '';
  @observable updatesNickname = null;
  @observable asksIfUpdatesNickname = false;
  @observable selectedEstimateRequestId = null;
  @observable selectedShopId = undefined;
  @observable isHistoryBackable = false;

  constructor() {
    this.init()
  }

  init() {
    jQuery.ji_popup_with_react.set_title('クチコミ投稿')
    this.fetchReviewInit()
  }

  getInitApiUrl() {
    return window.jis_.c_root_uri + "/api/reviews/forms/"
  }

  getPostApiUrl() {
    return window.jis_.c_root_uri + "/api/reviews/reviews"
  }

  fetchReviewInit() {
    if (this.services.length > 0) {
      this.gotoIdle()
      return true
    }

    const requestData = {}
    const url = this.getInitApiUrl()

    const request = {
      url: url,
      dataType: "json",
      type: "GET",
      request_data: requestData,
      onSuccess: (function(json) {
        this.onInitSuccess(json)
      }).bind(this),
      onError: function(request, textStatus, errorThrown) { // eslint-disable-line no-unused-vars
        // FIXME: HIKAKAKU-3030
        const response = request.responseJSON
        if (response && response.version === 'v1') {
          DialogStore.showErrorDialog(response.message, response.details)
        } else {
          DialogStore.showErrorDialog()
        }
      }
    }
    window.JiApiManager.request(request)

    return true
  }

  onInitSuccess(json) {
    this.services = json.services
    if (!json.user_info) {
      this.requireEmailInput = true
      this.user_info = {email: '', nick_name: ''}
    } else {
      this.user_info = json.user_info
      this.email = json.user_info.email
      this.nickname = json.user_info.nick_name || ''
      this.asksIfUpdatesNickname = true
    }
    this.gotoIdle()
  }

  gotoIdle() {
    this.state = this.STATE_IDLE
  }

  setSuccess() {
    this.state = this.STATE_SUCCESS
  }

  handlePostSuccess() {}

  setSelectedServiceId(id) {
    this.selectedServiceId = id
  }

  setSelectedServiceImageUrl(id) {
    this.setSelectedServiceImageUrl = id
  }

  setRating(rating) {
    this.rating = rating
  }

  setRateStarHoverIndex(index) {
    this.rateStarHoverIndex = index
  }

  setNickName(text) {
    this.user_info.nick_name = text
  }

  setEmail(text) {
    this.user_info.email = text
  }

  setSelectedEstimateRequestId(id) {
    this.selectedEstimateRequestId = id
  }

  postReview(params) {
    if (this.validation(params) === false) {
      return
    }
    this.fetchPostReview(params)
  }

  validation(params) {
    let errorMessage = ''

    //名前が入力されている
    if (Validator.isEntered(params.review.reviewer_name) === false) {
      errorMessage += 'ニックネームを入力してください<br />'
    }

    //テキストが10文字以上入力されている
    if (Validator.isEntered(params.review.content) === false ||
      params.review.content.length < 10) {
      errorMessage += 'レビュー内容は10文字以上入力してください<br />'
    }

    //レビューの星が入力されていて、星の数が0より大きい
    if (params.review.rating <= 0 ||
      Validator.isEntered(params.review.rating) === false) {
      errorMessage += '星の数を入力してください<br />'
    }

    //serviceのIDが入力されている
    if (Validator.isEntered(params.review.service_id) === false) {
      errorMessage += '業者を入力してください<br />'
    }

    if (Validator.isEntered(errorMessage) === true) {
      DialogStore.showDialog({
        title: "入力に不備があります",
        description: errorMessage,
        btnList: [
          {
            title: "閉じる",
            callback: function() {
              DialogStore.hideDialog()
            },
            customClass: "btn-blue"
          },
        ],
        show_time: -1
      })

      return false
    }
    return true
  }

  fetchPostReview(params) {

    const url = this.getPostApiUrl()

    DialogStore.showDialog({
      title: "送信中...",
      description: "しばらくお待ちください",
      show_time: -1
    })

    const request = {
      url: url,
      dataType: "json",
      type: "POST",
      request_data: params,
      onSuccess: (function() {
        DialogStore.hideDialog()
        DialogStore.showDialog({
          title: "投稿が完了しました",
          description: "承認されると表示されますので、しばらくお待ちください。",
          btnList: this.takeButtonList(),
          show_time: -1
        })
      }).bind(this),
      onError: function(request, textStatus, errorThrown) { // eslint-disable-line no-unused-vars
        // FIXME: HIKAKAKU-3030
        DialogStore.hideDialog()
        const response = request.responseJSON
        if (response && response.version === 'v1') {
          DialogStore.showErrorDialog(response.message, response.details)
        } else {
          DialogStore.showErrorDialog()
        }
      }
    }
    window.JiApiManager.request(request)
  }

  getDefaultServiceId() {
    let defaultServiceId = this.defaultServiceId
    let services = this.services
    let default_service = undefined
    services.forEach((value) => {
      if (value.id !== defaultServiceId) {
        return
      }
      default_service = value.id
    })
    if (typeof default_service === "undefined") {
      return undefined
    }
    return default_service
  }

  setDefaultServiceId(id) {
    this.defaultServiceId = id
  }

  setIsHistoryBackable(previewable) {
    this.isHistoryBackable = previewable
  }

  takeButtonList() {
    return (this.isHistoryBackable && history.length > 1) ? [
      {
        title: "続けて投稿する",
        callback: () => {
          this.handlePostSuccess()
          $.ji_popup.close_popup()
          DialogStore.hideDialog()
        },
        customClass: "btn-blue"
      },
      {
        title: "元のページに戻る",
        callback: () => {
          history.back()
        },
        customClass: "btn-orange"
      }
    ] : [
      {
        title: "閉じる",
        callback: () => {
          this.handlePostSuccess()
          $.ji_popup.close_popup()
          DialogStore.hideDialog()
        },
        customClass: "btn-blue"
      }
    ]
  }
}
