import $ from 'jquery'
import SmoothScroller from '~/jquery-modules/smooth_scroller/smooth_scroller.js'

const SCROLLER_SELECTOR_CLASS = ".js-kaitori-history-item-scroller"

class KaitoriHistoryCarouselManager {
  listen() {
    if (document) {
      $(document).ready(this.onReady)
    }
  }

  onReady() {
    new SmoothScroller().onReady(SCROLLER_SELECTOR_CLASS)
  }
}

new KaitoriHistoryCarouselManager().listen()
