// @flow

import React from 'react'
import {observer} from 'mobx-react'
import ShopPrefectureListStore from '~/stores/shop_prefecture_list_store'
import classNames from 'classnames'

type Prefecture = {
  id: number,
  name: string
}

type Props = {
  param: Prefecture,
  store: ShopPrefectureListStore
}

@observer
class PrefectureSelectBtn extends React.Component<Props> {
  render() {
    const {param, store} = this.props
    return (
      <a
        href={store.getPrefecturePath(param.id)}
        className={classNames('btn', (store.isCurrentPrefecture(param.id) ? 'active' : ''))}
      >
        {param.name}
      </a>
    )
  }
}

export default PrefectureSelectBtn
