import Util from '@hikakaku/shared/util/util'

class Validator {
  constructor() {
  }

  //入力済み
  isEntered(value) {
    const regex = /^\s*$/
    return !(
      typeof value === 'undefined' ||
      value === null ||
      value.length <= 0 ||
      this.regexCheck(value, regex)
    )
  }

  //URL
  validateUrl(value) {
    const regex = /^\S+:\/\/\S+\.\S+.+$/
    return this.regexCheck(value, regex)
  }

  //ヒカカク！で許可するメール形式かチェック
  validateEmail(value) {
    const regex = /^([a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-](?!.*(\.\.))[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]|[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
    value = Util.multiByteStringToSingleByteString(value)
    return this.regexCheck(value, regex)
  }

  //メールアドレスのフォーマットであるかチェック(半角全角などは判定しない)
  validateEmailFormat(value) {
    const regex = /^.+@.+$/
    value = Util.multiByteStringToSingleByteString(value)
    return this.regexCheck(value, regex)
  }

  //電話
  validateTel(value) {
    const regex = /^[0-9-]{9,11}$|^[0-9-]{12,14}$/
    value = Util.multiByteStringToSingleByteString(value)
    return this.regexCheck(value, regex)
  }

  //数字
  validateNumber(value) {
    const regex = /^[0-9]*$/
    value = Util.multiByteStringToSingleByteString(value)
    return this.regexCheck(value, regex)
  }

  regexCheck(value, regex) {
    const pattern = new RegExp(regex)
    return pattern.test(value)
  }
}
export default new Validator()
