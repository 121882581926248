"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reviewStarClassName = void 0;
var reviewStarClassName = function (rating) {
    if (rating < 0) {
        return 's0-0';
    }
    if (rating > 5) {
        return 's5-0';
    }
    var n = Math.round(rating * 2) / 2 * 10;
    return "s".concat(Math.floor(n / 10), "-").concat(n % 10);
};
exports.reviewStarClassName = reviewStarClassName;
