import $ from 'jquery'
import eventManager from '~/util/event_manager'
import InView from 'in-view'
import googleAnalyticsManager from '@hikakaku/shared/util/google_analytics_manager'
class GoogleAnalyticsEventTracker {
  constructor() {
    this.onClick = this.onClick.bind(this)
    this.onInView = this.onInView.bind(this)
    eventManager.onReady((() => {
      this.onReady()
    }))
  }

  onReady() {
    $(document).on('click', '[data-analytics-track-event]', this.onClick)
    InView('[data-analytics-track-event]').on('enter', this.onInView)
    this.onVisit()
  }

  onClick(e) {
    this.postClickEvent(e)
  }

  onInView(el) {
    this.postImpression(el)
  }

  onVisit() {
    $(document).find('[data-analytics-track-visit-event]').each((i, e) => {
      this.postVisitEvent(e)
    })
  }

  postClickEvent(e) {
    const target = $(e.currentTarget)
    const args = target.data('analytics-track-event').split(' ')
    this.postGAEventWithArgs(args)
    return true
  }

  postImpression(el) {
    const target = $(el)
    if (target.attr('data-ga_imp') === 'true' || target.attr('data-ga-disable-track-impression') === 'true') {
      return false
    }
    target.attr('data-ga_imp', 'true')
    const args = target.data('analytics-track-event').split(' ')
    this.postGAEventImpression(args[0], args[2])
    return true
  }

  postVisitEvent(e) {
    const target = $(e)
    const args = target.data('analytics-track-visit-event').split(' ')
    this.postGAEventWithArgs(args)
  }

  postGAEventWithArgs(args) {
    if (args.length > 3 && !isNaN(args[3])) {
      this.postGAEventWithValue(args[0], args[1], args[2], args[3])
    } else {
      this.postGAEvent(args[0], args[1], args[2])
    }
  }

  postGAEvent(category, action, label) {
    return googleAnalyticsManager.postGAEvent(category, action, label)
  }

  postGAEventWithValue(category, action, label, value) {
    return googleAnalyticsManager.postGAEventWithValue(category, action, label, value)
  }

  postGAEventImpression(category, label) {
    return googleAnalyticsManager.postGAEventImpression(category, label)
  }
}
export default new GoogleAnalyticsEventTracker()
