/**
 * @deprecated
 * app/assets/javascripts/srcは削除予定の為、今後は使用禁止。
 * @see https://github.com/jiraffeinc/hikakaku-cms/pull/2712#issuecomment-428079713
 */
import axios from 'axios'

$(document).ready(() => {
  axios.defaults.headers['X-CSRF-TOKEN'] = $('meta[name=csrf-token]').attr('content')
})
