import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerMagiNickname extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.handleChangeMagiNickName = this.handleChangeMagiNickName.bind(this)
  }

  handleChangeMagiNickName(e) {
    this.props.reviewFormStore.magi_nickname = e.target.value
  }

  render() {
    return (
      <div className="reviewpopup-Form_content">
        <label className="reviewpopup-Form_title" htmlFor="review_name">magiニックネーム</label><span className="required">※必須</span>
        <input className="reviewpopup-Form_textinput" type="text" id="review_name" name="review_nickname" placeholder="magiで使用しているニックネーム" value={this.props.reviewFormStore.magi_nickname} onChange={this.handleChangeMagiNickName}/>
      </div>
    )
  }
}
