// @flow
import * as React from "react"
import _ from "lodash"

type Cell = {
  link: string,
  label: string
}

type Props = {
  list: Array<Cell>,
  hideThreshold?: number,
  liClassName?: string,
  ulClassName?: string
}

type State = {
  open: boolean
}

type ListProps = {
  list: Array<Cell>,
  liClassName: ?string,
  ulClassName: ?string
}

type ToggleLinkProps = {
  open: boolean,
  toggle: () => void
}

const List = ({list, liClassName, ulClassName}: ListProps) => (
  <ul className={ulClassName ? ulClassName : "category-LinkList_Cell"}>
    {
      list.map((cell, index) => (
        <li className={liClassName ? liClassName : "category-LinkList_Cell"} key={index}>
          {cell.link ? (
            <a className="category-LinkList_Link" href={cell.link}>
              {cell.label}
            </a>
          ) : (
            <span> {cell.label} </span>
          )}
        </li>
      ))
    }
  </ul>
)

const ToggleLink = ({open, toggle}: ToggleLinkProps) => {
  const text = open ? "閉じる" : "もっと見る"
  const arrowClass = open ? "jirafficon-arrow-up" : "jirafficon-arrow-down"

  return (
    <div className="category-LinkList_ToggleLinkWrapper">
      <span className={`jirafficon ${arrowClass}`}/>
      <a className="category-LinkList_ToggleLink" onClick={toggle}>
        {text}
      </a>
    </div>
  )
}

class LinkList extends React.Component<Props, State> {
  state = {
    open: false
  }

  toggle = () => {
    const {open} = this.state
    this.setState({open: !open})
  }

  isRenderToggleLink() {
    const {list, hideThreshold} = this.props
    return hideThreshold && hideThreshold < list.length
  }

  render() {
    const {open} = this.state
    const {list, hideThreshold, liClassName, ulClassName} = this.props

    return (
      <div className="category-LinkList">
        <List list={_.take(list, hideThreshold)} liClassName={liClassName} ulClassName={ulClassName}/>
        {open && <List list={list.slice(hideThreshold)} liClassName={liClassName} ulClassName={ulClassName}/>}
        {this.isRenderToggleLink() && <ToggleLink open={open} toggle={this.toggle}/>}
      </div>
    )
  }
}

export default LinkList
