import React from 'react'
import axios from 'axios'
import classnames from 'classnames'
import DialogStore from '~/stores/dialog_store'
import QaLikeImage from "images/questions/qa_like.png"

class AnswerLikeBtn extends React.Component {

  constructor(props) {
    super()
    this.state = {
      liked: props.liked,
      count: props.count,
    }
    this.onClick = this.onClick.bind(this)
    this.onError = this.onError.bind(this)
  }

  onClick(e) {
    if (this.props.href) {
      return
    }
    e.preventDefault()
    if (this.props.selfAnswer) {
      this.onSelfAnswer()
      return
    }
    if (this.state.liked) {
      return
    }
    this.state.liked = true
    this.setState((state) => ({
      liked: true,
      count: state.count + 1,
    }))
    axios.post(`/api/answers/${this.props.answer.id}/answer_likes`).catch(this.onError)
  }

  onError() {
    this.setState((state) => ({
      liked: false,
      count: state.count - 1,
    }))
    DialogStore.showDialog({
      title: '回答へのいいねに失敗しました',
      description: "ネットワークなどをご確認の上、再度お試しください",
      btnList: [
        {
          title: '閉じる',
          customClass: 'btn-gray',
          callback() {
            DialogStore.hideDialog()
          },
        },
      ],
      show_time: -1,
    })
  }

  onSelfAnswer() {
    DialogStore.showDialog({
      title: '自分の回答にはいいね出来ません',
      btnList: [
        {
          title: '閉じる',
          customClass: 'btn-gray',
          callback() {
            DialogStore.hideDialog()
          },
        },
      ],
      show_time: -1,
    })
  }

  render() {
    return (
      <div className={classnames("question__content__row__buttons__like", {done: this.state.liked})}>
        <a onClick={this.onClick} href={this.props.href} className="btn__flat gray_outline inline_flex radius">
          <img src={QaLikeImage} alt="" width="34" className="lazyload" />
          いいね
        </a>
        <small className="btn__flat gray_outline inline_flex radius">
          {this.state.count}
        </small>
      </div>
    )
  }
}

export default AnswerLikeBtn
