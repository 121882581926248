import React from 'react'
import ReactDOM from 'react-dom'
import ItemCell from '../../cell/item_cell/item_cell'

class ItemList extends React.Component {
  constructor(props) {
    super(props)
  }

  createList() {
    let list = this.props.items
    let ret = list.map((cell, index) => {
      return (
        <ItemCell
          item={cell}
          key={index}
        />
      )
    })
    return ret
  }

  render() {
    let items = this.createList()
    return (
      <ul className="product_list">
        {items}
      </ul>
    )
  }
}

export default ItemList
