import React from 'react'
import PropTypes from 'prop-types'
import FixedEnterpriseReviewFormInner from '~/components/forms/review_form/fixed_enterprise_review_form_inner'
import BulkEstimateSiteReviewFormStore from '~/stores/forms/reviews/enterprises/brokers/bulk_estimate_site_review_form_store'

export default class FixedBulkEstimateSiteReviewForm extends React.Component {
  static propTypes = {
    service_id: PropTypes.number,
    is_history_backable: PropTypes.bool
  }

  constructor(props) {
    super(props)
  }

  render() {
    const reviewFormStore = new BulkEstimateSiteReviewFormStore()
    reviewFormStore.setSelectedServiceId(this.props.service_id)
    reviewFormStore.setIsHistoryBackable(this.props.is_history_backable)
    return <FixedEnterpriseReviewFormInner reviewFormStore={reviewFormStore} serviceReadonlyTitle="一括査定サイト名" />
  }
}
