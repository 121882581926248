import Util from '@hikakaku/shared/util/util'

class UrlHashManager {


  getHashObject() {
    // substrで#を取り除く
    const hash = Util.getHashParams().substr(1)
    if (hash === "") {
      return
    }
    const hashObject = {}
    const hashArray = hash.split("&")
    hashArray.forEach((item) => {
      const splitHashArray = item.split("=")
      hashObject[splitHashArray[0]] = splitHashArray[1]
    })
    return hashObject
  }

  getValue(target) {
    // substrで#を取り除く
    const hash = Util.getHashParams().substr(1)
    if (hash === "") {
      return
    }
    const splitHash = hash.split("=")
    const hashObject = {[splitHash[0]]:  splitHash[1]}
    return hashObject[target]
  }
}

export default new UrlHashManager()
