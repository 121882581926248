import eventManager from '~/util/event_manager'
import googleAnalyticsManager from '@hikakaku/shared/util/google_analytics_manager'

class SiteHeaderLinkEventTracker {
  constructor() {
    eventManager.onReady((() => {
      this.onReady()
    }))
  }

  onReady() {
    const targetLinkContainerSelectors = [".js-site-header", ".js-site-header-nav-content"]
    targetLinkContainerSelectors.forEach((selector) => {
      const elem = document.querySelector(selector)
      if (!elem) {
        return
      }

      const links = elem.querySelectorAll("a")
      Array.prototype.forEach.call(links, (link) => {
        link.addEventListener("click", this.onClick)
      })
    })
  }

  onClick(e) {
    const url = e.currentTarget.getAttribute("href")
    googleAnalyticsManager.postGAEvent("ヘッダー", "click", url)
  }
}
export default new SiteHeaderLinkEventTracker()
