// @flow

// FIXME: ここにあるのはおかしい
//        SSR の対象が containers/ 以下になったらそこに移動
import React from 'react'
import InputFilesStore from '~/stores/forms/builder/input_files_store'
import InputPhotosView from '~/components/forms/inputs/input_photos'

type Props = {
  inputName: string,
  count: number
}

export default class EstimateFormInputPhoto extends React.Component<Props> {
  render() {
    const store = new InputFilesStore({
      name: this.props.inputName,
      inputOptions: {
        count: this.props.count,
      }
    })
    return (
      <div>
        <InputPhotosView store={store} />
      </div>
    )
  }
}
