import $ from 'jquery'
import eventManager from '~/util/event_manager'
import googleAnalyticsManager from '@hikakaku/shared/util/google_analytics_manager'

class FormTracker {
  constructor() {
    this.onSubmit = this.onSubmit.bind(this)
    eventManager.onReady((() => {
      this.onReady()
    }))
  }

  onReady() {
    $(document).on('submit', '[data-analytics-track-form-submit-event]', this.onSubmit)
  }

  onSubmit(e) {
    const target = $(e.currentTarget)
    const args = target.data('analytics-track-form-submit-event').split(' ')
    this.postGAEvent(args)
  }

  postGAEvent(args) {
    if (args.length > 3 && !isNaN(args[3])) {
      googleAnalyticsManager.postGAEventWithValue(args[0], args[1], args[2], args[3])
    } else {
      googleAnalyticsManager.postGAEvent(args[0], args[1], args[2])
    }
  }
}
export default new FormTracker()
