import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerServiceInput extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.onChangeServiceName = this.handleChangeServiceName.bind(this)
  }

  handleChangeServiceName(e) {
    this.props.reviewFormStore.userInputEnterpriseName = e.target.value
  }

  render() {
    const reviewFormStore = this.props.reviewFormStore

    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="review_service">買取業者名</label><span className="required">※必須</span>
        </div>
        <div>
          <input className="reviewpopup-Form_textinput" type="text" id="review_service" name="reviewer_service" placeholder="買取業者名を入力してください" value={reviewFormStore.userInputEnterpriseName} onChange={this.onChangeServiceName}/>
        </div>
      </div>
    )
  }
}
