import {action, observable} from 'mobx'

class OverlayModalStore {
  @observable active = false
  @observable content = null

  @action
  close() {
    this.active = false
  }

  @action
  show(content) {
    this.content = content
    this.active = true
  }
}

export default new OverlayModalStore()
