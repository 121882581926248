// @flow
import React from 'react'
import {observer} from 'mobx-react'
import CategorySelectorStore from '~/stores/searchform/category_selector_store'
import CategorySelectorInner from '~/components/searchform/category_selector_inner.jsx'

type Props = {
  category_name: string,
  slug: string,
  tax: string,
}

@observer
class CategorySelector extends React.Component<Props, void> {
  store: CategorySelectorStore
  UNSAFE_componentWillMount() {
    this.store = new CategorySelectorStore(this.props)
  }
  render() {
    return (
      <CategorySelectorInner store={this.store} />
    )
  }
}

export default CategorySelector
window.reactComponents.CategorySelector = CategorySelector
