// @flow
import _ from 'lodash'

export function keyboardShown(): Promise<*> {
  const waitTime: number = 50
  const body: ?HTMLElement = document.querySelector('body')
  if (!body) {
    return Promise.resolve(null)
  }
  return new Promise((resolve, reject) => {
    let bodyTop
    // バーチャルキーボードが出現するまでまつ
    const waitKeyboadShown: Function = () => {
      const currentBodyTop = body.getBoundingClientRect().top
      if (!_.isNil(bodyTop) && 0 === bodyTop - currentBodyTop) {
        resolve()
        return
      }
      bodyTop = currentBodyTop
      setTimeout(waitKeyboadShown, waitTime)
    }
    setTimeout(waitKeyboadShown, waitTime)
  })
}
