// @flow
import {observable} from 'mobx'
import StoreBase from '~/stores/store_base'
import InputNestedSingleSelectStore from '~/stores/forms/builder/input_nested_single_select_store'
import type {NestedOptionParam, NestedSelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputNestedOptionStore extends StoreBase {
  @observable value = '';
  @observable title = '';
  @observable description = '';
  children: InputNestedSingleSelectStore | typeof undefined

  constructor(param: NestedOptionParam, selectParam: NestedSelectParam) {
    super()
    this.init(param, selectParam)
  }

  init(param: NestedOptionParam, selectParam: NestedSelectParam) {
    this.value = param.value
    this.label = param.label
    this.description = param.description || ''
    if (param.options) {
      this.children = new InputNestedSingleSelectStore({...selectParam, options: param.options, depth: selectParam.depth + 1})
    }
  }

  handleChange(): void {
    this.children && this.children.handleChange()
  }
}
