import $ from 'jquery'
import eventManager from '~/util/event_manager'
import Tabs from '~/jquery-modules/tabs.js'
import GoogleMapsCreator from '~/jquery-modules/google_maps_creator.js'

class StoreInfoTabs extends Tabs {

  constructor(elm) {
    super(elm)
    this.googleMap = undefined
  }

  doCallBack(e) {
    let target = $(e.currentTarget)
    let id = target.data('tab-id')
    if (id !== 'map') {
      return
    }
    this.loadGoogleMap()
  }

  loadGoogleMap() {
    let target = this.elm.find('.google_maps')
    target.map(((index, elm) => {
      let jqElm = $(elm)

      //ロード済みの場合は再描画
      if (typeof this.googleMap !== 'undefined') {
        this.googleMap.redraw()
        return
      }

      GoogleMapsCreator.create(jqElm)
    }))
  }

}

eventManager.onReady(() => {
  let tabs = $('.js-store-info-tabs')
  tabs.map((index, tab) => {
    new StoreInfoTabs($(tab))
  })
})
