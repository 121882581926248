import React from 'react'
import PropTypes from 'prop-types'

export default class ReviewFormInnerService extends React.Component {
  static propTypes = {
    serviceId: PropTypes.number,
    services: PropTypes.object
  }

  renderServiceImage() {
    const serviceId = this.props.serviceId
    const targetService = this.props.services.find((i) => i.id === serviceId)

    if (targetService) {
      return (
        <img src={targetService.image_url} className="lazyload" />
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="reviewpopup-Form_storeimage">
        {this.renderServiceImage()}
      </div>
    )
  }
}
