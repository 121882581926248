"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var scrollIconWrapperHiddenClass = 'blogTable-ScrollIcon_Wrapper-hidden';
var TableController = /** @class */ (function (_super) {
    __extends(TableController, _super);
    function TableController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.connect = function () {
            _this.element.addEventListener('scroll', function () {
                _this.scrollIconWrapperTarget && _this.scrollIconWrapperTarget.classList.add(scrollIconWrapperHiddenClass);
            });
        };
        return _this;
    }
    TableController.targets = ['scrollIconWrapper'];
    return TableController;
}(stimulus_1.Controller));
exports.default = TableController;
