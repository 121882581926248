// @flow
import {sprintf} from 'sprintf-js'
import $ from 'jquery'

type Offset = {
  top: number,
  left: number
}

class Util {
  scrollTop = 0
  isScrollLock = false
  visited = undefined

  constructor() {}

  /*
   * @brief オブジェクトの配列をキーでソート
   * @param オブジェクトの配列 [Array]
   * @param ソートの軸にしたいキー名 [String]
   * @param 降順(desc)か昇順(asc)か [String]
   * */
  objectArraySort(data: Object, path: string, order: string = 'desc'): Object {
    //デフォは降順(DESC)
    let num_a: number = -1
    let num_b: number = 1

    if (order === 'asc') { //指定があれば昇順(ASC)
      num_a = 1
      num_b = -1
    }

    return data.sort((a: Object, b: Object) => {
      const x: any = this.getDataByPath(path, a)
      const y: any = this.getDataByPath(path, b)
      if (x > y) {
        return num_a
      }
      if (x < y) {
        return num_b
      }
      return 0
    })
  }

  getDataByPath(path: string, obj: Object): any {
    const path_ar = path.split('.')
    let ret = obj
    for (let i = 0, len = path_ar.length; i < len; ++i) {
      ret = ret[path_ar[i]]
      if (typeof ret === 'undefined') {
        console.log('error undefined data :' + path)
        return ret
      }
    }
    return ret
  }

  // 小数点n位までを残す関数
  // number=対象の数値
  // n=残したい小数点以下の桁数
  floatFormat(number: number, n: number) {
    const _pow = Math.pow(10, n)
    return Math.round(number * _pow) / _pow
  }

  substr(str: ?string, len: number, pref: string = "...") {
    let ret = str

    if (typeof ret !== 'string') {
      return ret
    }

    if (len <= ret.length - 1) {
      ret = ret.substr(0, len)
      ret += pref
    }
    return ret
  }

  isPast(date: Date) {
    return date.getTime() < Date.now()
  }

  roundHalf(num: number = 0) {
    return (Math.round(num * 2)) / 2
  }

  createStarRatingClass(rating: number) {
    let ret = this.roundHalf(rating)
    ret = sprintf('%.1f', ret)
    ret = ret.replace('.', '-')
    ret = 's' + ret
    return ret
  }

  multiByteStringToSingleByteString(str: string) {
    let ret = ''
    if (str.length <= 0) {
      return ret
    }

    const pattern: RegExp = new RegExp(/^[@a-zA-Z0-9\._-]$/u)
    const matches: ?Array<string> = str.match(/./ug)

    if (!Array.isArray(matches)) {
      return ret
    }

    matches.forEach((s) => {
      if (pattern.test(s)) {
        ret += s
        return
      }
      if (s === 'ー') {
        ret += '-'
        return
      }
      ret += String.fromCharCode(s.codePointAt(0) - 0xFEE0)
    })
    return ret
  }

  calcTotalOffset(elm: HTMLElement): Offset {
    let tmp: any = elm,
      left: number = 0,
      top: number = 0

    while (tmp) {
      left += tmp.offsetLeft
      top += tmp.offsetTop
      tmp = tmp.offsetParent
    }

    return {
      top: top,
      left: left
    }
  }
  // URLの#の値をテストする際に必要
  // テストでURLの値を書き換えてテストしようとすると「TypeError: Attempted to assign to readonly property」とエラーが出るため
  getHashParams(): string {
    return window.location && window.location.hash
  }

  log(text: string) {
    if (jis_.IS_DEBUG === true) {
      console.log(text)
    }
  }

  getUserAgent(): {Tablet: boolean, Mobile: boolean} {
    const u = window.navigator.userAgent.toLowerCase()
    return {
      Tablet: (u.indexOf("windows") !== -1 && u.indexOf("touch") !== -1) ||
      u.indexOf("ipad") !== -1 ||
      (u.indexOf("android") !== -1 && u.indexOf("mobile") === -1) ||
      (u.indexOf("firefox") !== -1 && u.indexOf("tablet") !== -1) ||
      u.indexOf("kindle") !== -1 ||
      u.indexOf("silk") !== -1 ||
      u.indexOf("playbook") !== -1,
      Mobile: (u.indexOf("windows") !== -1 && u.indexOf("phone") !== -1) ||
      u.indexOf("iphone") !== -1 ||
      u.indexOf("ipod") !== -1 ||
      (u.indexOf("android") !== -1 && u.indexOf("mobile") !== -1) ||
      (u.indexOf("firefox") !== -1 && u.indexOf("mobile") !== -1) ||
      u.indexOf("blackberry") !== -1
    }
  }

  isMobile(): boolean {
    const ua = this.getUserAgent()
    let ret = true
    ret = ua.Mobile
    return ret
  }

  scrollLock(enable: boolean) {
    if (enable === false) {
      $("body").removeClass("no_scroll").css('top', 0)
      $(window).scrollTop(this.scrollTop)
    } else {
      this.scrollTop = $(window).scrollTop()
      $('body').addClass('no_scroll').css('top', (-this.scrollTop) + 'px')
    }
  }

  fitImageSize(imgObj: any, maxSize: number) {
    if (typeof imgObj === 'undefined') {
      this.log('imgObj is undefined')
    }
    if (typeof maxSize === 'undefined') {
      this.log('maxSize is undefined')
    }
    //画像のサイズを変更する
    const img: Image = new Image()
    img.src = imgObj.attr("src")
    const width: number = imgObj.width() // 幅
    const height: number = imgObj.height() // 高さ

    if (height < width) {
      imgObj.css('width', maxSize + 'px')
    } else {
      const w: number = width * (maxSize / height)
      w.toString()
      imgObj.css('width', w + 'px')
    }
  }

}

export default new Util()
if (typeof window !== 'undefined') {
  window.ju_kaitori_ = new Util()
}
