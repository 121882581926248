// @flow
import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import CategorySelectorList from '~/components/searchform/category_selector_list.jsx'

const CategorySelectorLoading = observer(({store}) => {
  if (!store.loading) {
    return null
  }
  return (
    <span className="loading">
      <span className="load_icon_fast"></span>
      <span className="load_icon"></span>
      <span className="load_icon_slow"></span>
    </span>
  )
})
CategorySelectorLoading.displayName = 'CategorySelectorLoading'

const CategorySelectorHead = observer(({store}) => (
  <h3 className="head">
    {store.hasParent ? (
      <a href="" className="back" onClick={store.onClickFetch(store.parentSlug, store.parentName, true)}>戻る</a>
    ) : null}
    <span className="title">{store.headName}</span>
    <a href="" className="select" onClick={store.onClickSelect(store.headSlug, store.headName)}>選択</a>
  </h3>
))
CategorySelectorHead.displayName = 'CategorySelectorHead'

type Props = {
  store: any,
}

@observer
class CategorySelectorInner extends React.Component<Props, void> {
  node: ?HTMLElement

  componentDidMount() {
    this.props.store.setNode(this.node)
  }

  render() {
    const {store} = this.props
    return (
      <div className="taxonomy_selector_box fnt-size-10" ref={(node) => {
        this.node = node
      }} style={{marginRight: 0}}>
        <input type="hidden" name={store.requestData.tax} value={store.currentSlug} />
        <a href="" className={classNames("show_selector", "selected_list", store.getSelectorClass())} onClick={store.onClickTogglePopup}>
          <span className="selected_name">{store.currentName}</span>
          <span className="arrow_icon jirafficon jirafficon-arrow-down"></span>
        </a>
        <div
          className={classNames("taxonomy_selector_root areas fnt-size-10", {is_show: store.showPopup})}
          style={{display: store.showPopup ? 'block' : 'none'}}
          onClick={store.onClickStopPropagation}
        >
          <span className="selector_arrow" />
          <CategorySelectorLoading store={store} />
          <div className="table_view">
            <CategorySelectorHead store={store} />
            <CategorySelectorList store={store} />
          </div>
        </div>
      </div>
    )
  }
}

export default CategorySelectorInner
