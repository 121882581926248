import React from 'react'
import {observer} from 'mobx-react'
import PropTypes from 'prop-types'
import ReviewFormInnerContent from '~/components/forms/review_form/review_form_inner_content'
import ReviewFormInnerServiceInput from '~/components/forms/review_form/review_form_inner_service_input'
import ReviewFormInnerRateStar from "~/components/forms/review_form/review_form_inner_rate_star"
import ReviewFormInnerMagiNickname from "~/components/forms/review_form/review_form_inner_magi_nickname"
import ReviewFormInnerMagiEmail from "~/components/forms/review_form/review_form_inner_magi_email"

@observer
export default class MagiReviewFormInner extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  get defaultValueOfSelector() {
    return this.props.reviewFormStore.selectedServiceId
  }
  get selectorWidth() {
    return 210
  }

  constructor(props) {
    super(props)
    this.state = {
      services: props.reviewFormStore.services || "undefined",
      user_info: props.reviewFormStore.user_info
    }
    this.onClickSubmit = this.handleClickSubmit.bind(this)
  }

  handleClickSubmit() {
    this.props.reviewFormStore.postReview(this.createPostParams())
  }

  createPostParams() {
    const reviewData = {
      review: {
        reviewer_name: this.props.reviewFormStore.magi_nickname,
        reviewer_email: this.props.reviewFormStore.magi_email,
        content: this.props.reviewFormStore.content,
        rating: this.props.reviewFormStore.rating,
        service_id: this.props.reviewFormStore.selectedServiceId,
        user_input_enterprise_name: this.props.reviewFormStore.userInputEnterpriseName,
        update_nickname: this.props.reviewFormStore.updatesNickname,
        magi_nickname: this.props.reviewFormStore.magi_nickname,
        magi_email: this.props.reviewFormStore.magi_email
      }
    }
    return reviewData
  }

  render() {
    return (
      <div className="reviewpopup-Inner">
        <form className="hikakaku_form">
          <div className="reviewpopup-Form">
            <div className="reviewpopup-Form_content info">
              <ReviewFormInnerRateStar reviewFormStore={this.props.reviewFormStore} />
            </div>
            <ReviewFormInnerServiceInput reviewFormStore={this.props.reviewFormStore} />
            <ReviewFormInnerContent reviewFormStore={this.props.reviewFormStore} />
            <ReviewFormInnerMagiNickname reviewFormStore={this.props.reviewFormStore}/>
            <ReviewFormInnerMagiEmail reviewFormStore={this.props.reviewFormStore}/>
            <div colSpan="2" className="reviewpopup-CheckConfirm">
              <div colSpan="2"><input className="reviewpopup-SubmitButton" type="button" value="クチコミを投稿する" onClick={this.onClickSubmit} /></div>
              <div className="reviewpopup-CheckConfirm_attention">
                投稿と同時に<a href="http://hikakaku.com/magi/review_term/" target="_blank" rel="noopener noreferrer">クチコミ利用規約</a>に同意したとみなされます
              </div>
            </div>
            <dl className="reviewpopup-CampagnDescription">
              <dd><strong>＼クチコミキャンペーン開催中！／</strong></dd>
              <dt className="reviewpopup-CampagnDescription_term">◆キャンペーン期間</dt>
              <dd>2019年12月25日（水）12:00～2020年1月5日（日）23:59</dd>
              <dd>※ポイント付与は、2020年1月中旬を予定しています。</dd>
              <dt className="reviewpopup-CampagnDescription_term">◆ポイント付与条件</dt>
              <dd>・magiの会員登録ユーザーであること</dd>
              <dd>・ヒカカク！でのクチコミ投稿時に入力いただく、メールアドレスとニックネームがmagiで登録しているものと一致していること</dd>
              <dd>・クチコミの内容が承認されたこと</dd>
              <dt className="reviewpopup-CampagnDescription_term">◆注意事項</dt>
              <dd>・magiポイントは1ポイント=1円として1ポイントからmagiでの購入に利用できるもので、有効期限は付与日から150日間です。</dd>
              <dd>・入力していただくmagiニックネームは、ヒカカク！サイト上にそのまま投稿者として記載させていただきます。</dd>
              <dd>・重複したクチコミを投稿された場合は対象外とさせていただき、重複した分は１回分のみの投稿を有効とさせていただきます。</dd>
              <dd>・前回キャンペーンと同一のクチコミに関しましては、重複に該当され無効となってしまいますのでお気をつけください。</dd>
              <dd>・株式会社ジラフの運営する屋号magi、トレカスター、スママdash、最安修理ドットコムへご投稿いただいたクチコミはキャンペーン対応外とさせていただきます。</dd>
              <dd>・ポイント付与対象者へはmagi運営事務局よりアプリのメールボックス宛に通知させていただきます。また、当社からの連絡はmagi運営事務局よりアプリのメールボックス宛に通知のみ行い、それ以外から連絡は致しませんのでご注意ください。</dd>
              <dd>・本キャンペーンは株式会社ジラフ「magi」が主催しているものであり、Apple Inc.、アップルジャパン合同会社によるものではありません。</dd>
              <dd>・その他ヒカカク！が不正と判断した場合、無効となることがございます。予めご了承ください。</dd>
              <dd>・本キャンペーンは予告なく変更・中止する場合がございます。</dd>
            </dl>
          </div>
        </form>
      </div>
    )
  }
}
