import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerEmail extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.onChangeEmail = this.handleChangeEmail.bind(this)
  }

  handleChangeEmail(e) {
    this.props.reviewFormStore.email = e.target.value
  }

  render() {
    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label className="reviewpopup-Form_title" htmlFor="review_email">メールアドレス</label><span className="optional">任意</span>
        </div>
        <input className="reviewpopup-Form_textinput" type="text" id="review_email" name="reviewer_email" placeholder="test@test.com" value={this.props.reviewFormStore.email} onChange={this.onChangeEmail}/>
      </div>
    )
  }
}
