// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type {InputParam} from '~/flowtyped/stores/forms/form_builder_store_types'

/*
 * テキストエリア
 * */
@observer
class InputTextAreaView extends React.Component<Props<string, InputParam>> {

  onChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const {store} = this.props
    store.setValue(e.target.value)
  }

  onFocus = (): void => {
    const {store} = this.props
    store.uncheck()
  }

  onBlur = (): void => {
    const {store} = this.props
    store.validate()
    store.handleBlur()
  }

  render() {
    const {store} = this.props
    return (
      <textarea
        value={store.getValue()}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        name={store.getName()}
        maxLength={store.maxlength}
        placeholder={store.placeholder}
        className="box input" ></textarea>
    )
  }
}

export default InputTextAreaView
