"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var StickyEstimateButtonController = /** @class */ (function (_super) {
    __extends(StickyEstimateButtonController, _super);
    function StickyEstimateButtonController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.initialize = function () {
            _this.moveButtonTarget = document.getElementById(_this.data.get('moveButtonId') || '');
            _this.destinationTarget = document.getElementById(_this.data.get('destinationId') || '');
            if (!_this.moveButtonTarget || !_this.destinationTarget || !_this.stickyAreaTarget) {
                return;
            }
            // stimulus の target-action に scroll が無いため initialize で設定
            window.addEventListener('scroll', _this.handlePositionChanged);
            _this.handlePositionChanged();
        };
        _this.handlePositionChanged = function () {
            if (!_this.moveButtonTarget || !_this.destinationTarget || !_this.stickyAreaTarget) {
                return;
            }
            if (_this.moveButtonTarget.getBoundingClientRect().bottom < 0 && _this.destinationTarget.getBoundingClientRect().top > window.innerHeight) {
                _this.stickyAreaTarget.style.height = "".concat(_this.stickyAreaTarget.scrollHeight, "px");
            }
            else {
                _this.stickyAreaTarget.style.height = '0';
            }
        };
        return _this;
    }
    StickyEstimateButtonController.targets = ['stickyArea'];
    return StickyEstimateButtonController;
}(stimulus_1.Controller));
exports.default = StickyEstimateButtonController;
