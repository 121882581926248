"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var BLANK_CLASS = 'usersProfiles-Form_Blank';
var NOTIFY_BLANK_CLASS = 'usersProfiles-Form_NotifyMessageWrapper-blank';
var NOTIFY_FILLED_CLASS = 'usersProfiles-Form_NotifyMessageWrapper-filled';
var NOTIFY_BLANK_ICON_CLASS = 'fa fa-exclamation-triangle mr5';
var NOTIFY_FILLED_ICON_CLASS = 'jirafficon jirafficon-check-circle-filled mr5';
var NotifyBlankController = /** @class */ (function (_super) {
    __extends(NotifyBlankController, _super);
    function NotifyBlankController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.connect = function () {
            _this.handleChanged();
        };
        _this.handleChanged = function () {
            var _a;
            if (!_this.inputTargets ||
                !_this.notifyMessageWrapperTarget ||
                !_this.notifyMessageTarget ||
                !_this.notifyIconTarget) {
                return;
            }
            (_a = _this.inputTargets) === null || _a === void 0 ? void 0 : _a.forEach(function (element) {
                if (element.value) {
                    element.classList.remove(BLANK_CLASS);
                }
                else {
                    element.classList.add(BLANK_CLASS);
                }
            });
            var blank_element_count = _this.inputTargets.length - (_this.inputTargets.filter(function (element) { return element.value; })).length;
            if (blank_element_count > 0) {
                _this.notifyMessageTarget.innerText = "\u672A\u5165\u529B\u9805\u76EE\u304C".concat(blank_element_count, "\u4EF6\u3042\u308A\u307E\u3059");
                _this.notifyMessageWrapperTarget.classList.remove(NOTIFY_FILLED_CLASS);
                _this.notifyMessageWrapperTarget.classList.add(NOTIFY_BLANK_CLASS);
                _this.notifyIconTarget.className = NOTIFY_BLANK_ICON_CLASS;
            }
            else {
                _this.notifyMessageTarget.innerText = 'すべて入力済みです';
                _this.notifyMessageWrapperTarget.classList.remove(NOTIFY_BLANK_CLASS);
                _this.notifyMessageWrapperTarget.classList.add(NOTIFY_FILLED_CLASS);
                _this.notifyIconTarget.className = NOTIFY_FILLED_ICON_CLASS;
            }
        };
        return _this;
    }
    NotifyBlankController.targets = ['input', 'notifyMessageWrapper', 'notifyMessage', 'notifyIcon'];
    return NotifyBlankController;
}(stimulus_1.Controller));
exports.default = NotifyBlankController;
