import {observable, action} from 'mobx'
import UrlManager from '~/util/url_manager'

export default class ShopPrefectureListStore {
  prefectures = {};
  storeId = undefined
  currentId = undefined
  isMobile = false
  @observable isOpenAccordion = true

  constructor(props) {
    this.prefectures = props.prefectures
    this.storeId = props.storeId
    this.isMobile = props.isMobile
    this.isOpenAccordion = props.isOpenAccordion
    this.currentId = props.currentId
  }

  @action.bound onClickToggleBtn() {
    this.isOpenAccordion = !this.isOpenAccordion
  }

  @action.bound onClickShowPopup() {
    if (typeof jQuery === 'undefined') {
      return
    }
    jQuery.ji_popup_with_react.show_popup_with_component_name('PrefectureSelectorPopupWrapper', '地域を絞り込む', {store: this})
  }

  getPrefectureUrl(prefecture_id) {
    if (prefecture_id === undefined) {
      return UrlManager.getShopsListUrl(this.storeId)
    } else {
      return UrlManager.getShopsPrefectureUrl(this.storeId, prefecture_id)
    }
  }

  getPrefecturePath(prefecture_id) {
    if (prefecture_id === undefined) {
      return UrlManager.getShopsListPath(this.storeId)
    } else {
      return UrlManager.getShopsPrefecturePath(this.storeId, prefecture_id)
    }
  }

  checkIncludePrefecture(param) {
    return param.some((prefecture) => prefecture.id === this.currentId)
  }

  isCurrentPrefecture(prefecture_id) {
    return prefecture_id === this.currentId
  }

  isPrefectureSelected() {
    return this.currentId !== undefined
  }
}
