import React from 'react'
import ReactDOM from 'react-dom'

const DEFAULT_INITIAL_BLOG_SIZE = 5
const DEFAULT_MORE_BLOG_SIZE = 10
const DEFAULT_TITLE_CLASSES = 'headline_og'

class BlogSearchResultBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRequesting: false,
      blogs: [],
      totalCount: 0
    }
  }

  componentDidMount() {
    this.eventBind()
    this.init()
  }

  eventBind() {
    this.onClickMoreBtn = this.onClickMoreBtn.bind(this)
  }

  init() {
    this.pageLoad(this.initialBlogSize())
  }

  initialBlogSize() {
    if (this.props.initial_blog_size === undefined) {
      return DEFAULT_INITIAL_BLOG_SIZE
    } else {
      return this.props.initial_blog_size
    }
  }

  onClickMoreBtn() {
    this.pageLoad(this.moreBlogSize())
  }

  moreBlogSize() {
    if (this.props.more_blog_size === undefined) {
      return DEFAULT_MORE_BLOG_SIZE
    } else {
      return this.props.more_blog_size
    }
  }

  titleClasses() {
    if (this.props.title_classes === undefined) {
      return DEFAULT_TITLE_CLASSES
    } else {
      return this.props.title_classes
    }
  }

  pageLoad(limit) {
    this.setState({isRequesting: true})

    const data = {
      search_word: this.props.search_words,
      limit: limit,
      offset: this.state.blogs.length,
    }

    const requestData = {
      url: window.jis_.c_root_uri + "/api/blogs/more",
      dataType: 'json',
      type: 'GET',
      request_data: data,
      onSuccess: (result) => {
        this.setState({
          isRequesting: false,
          blogs: this.state.blogs.concat(result.blogs),
          totalCount: result.count
        })
      },
      onError: (request, textStatus, errorThrown) => {
        // FIXME: HIKAKAKU-3030
        window.alert("API ERROR")
      }
    }

    window.JiApiManager.request(requestData)
  }

  getMoreBtn() {
    if (this.state.totalCount <= this.state.blogs.length) {
      return (
        <span></span>
      )
    } else if (this.state.isRequesting) {
      return (
        <div>
          <span className="page_loader loading" style={{display: "block"}}>
            <span className="load_icon_fast"/>
            <span className="load_icon"/>
            <span className="load_icon_slow"/>
          </span>
        </div>
      )
    }

    return (
      <a onClick={this.onClickMoreBtn}>
        <span className="btn-low">ここから続きを表示</span>
      </a>
    )
  }

  render() {
    const countText = `${this.state.blogs.length}/${this.state.totalCount}件表示中`

    return (
      <div>
        <h2 className={this.titleClasses()}>{this.props.search_words}に関するコラム</h2>
        <ul className="blog_list">
          {this.renderBlogCell()}
          {this.renderSpacerCell()}
        </ul>
        <div className="alignright">
          <span className="page_number fnt-size-10">
            {countText}
          </span>
        </div>
        <div id="next">
          {this.getMoreBtn()}
        </div>
      </div>
    )
  }

  renderBlogCell() {
    return (
      <div>please override it</div>
    )
  }

  renderSpacerCell() {
    return Array.from({length: this.initialBlogSize() - this.state.blogs.length}).map((i) => {
      return (<li key={i} className="blog_spacer_cell"></li>)
    })
  }
}

export default BlogSearchResultBase
