import $ from 'jquery'
import eventManager from '~/util/event_manager'
import SingleMaps from '~/jquery-modules/googlemaps/single_maps'
import GoogleMapsLoader from 'google-maps'

class GoogleMapsCreator {
  get MAP_TYPE_SINGLE_PIN() {
    return 'singePin'
  }
  get MAP_TYPE_MULTI_PIN() {
    return 'multiPin'
  }
  get MAP_TYPE_ROUTE() {
    return 'route'
  }

  constructor() {
  }

  create(elm) {
    let type = this.MAP_TYPE_SINGLE_PIN
    let ret = undefined
    switch (type) {

    case this.MAP_TYPE_SINGLE_PIN:
      ret = this.singlePinMap(elm)
      break

    case this.MAP_TYPE_MULTI_PIN:
      ret = this.multiPinMap(elm)
      break

    case this.MAP_TYPE_ROUTE:
      ret = this.routeMap(elm)
      break

    default:
      ret = this.singlePinMap(elm)
      break
    }
    return ret
  }

  singlePinMap(elm) {
    return new SingleMaps(elm)
  }

  multiPinMap(elm) {
  }

  routeMap(elm) {
  }

}

export default new GoogleMapsCreator()
eventManager.onReady(() => {
  $('.google_maps[data-lazy!="true"]').map((index, obj) => {
    new GoogleMapsCreator().create($(obj))
  })
})
