// @flow
import React from 'react'
import InputStore from '~/stores/forms/builder/input_store'

type Props = {
  action: string,
  method: string,
  inputStores: Array<InputStore<any, any>>,
  closeModal: () => void,
  onSubmit: () => void
}

const FormModalPreview = ({action, method, inputStores, closeModal, onSubmit}: Props) => {
  return (
    <form action={action} method={method} onSubmit={onSubmit} className="formsFormWithModalPreview-Preview">
      {
        inputStores.map((inputStore, index) => (
          <input type="hidden" name={inputStore.getName()} value={inputStore.getValue()} key={index} />
        ))
      }

      <div className="formsFormWithModalPreview-Preview_Container">
        <div className="formsFormWithModalPreview-Preview_Title">以下の内容で送信してよろしいですか？</div>
        {
          inputStores
            .filter((inputStore) => inputStore.type !== "hidden")
            .map((inputStore, index) => (
              <div className="formsFormWithModalPreview-Preview_Input" key={index}>
                <div className="formsFormWithModalPreview-Preview_Label">
                  {inputStore.label}
                </div>
                <div className="formsFormWithModalPreview-Preview_Value">
                  {inputStore.getValueText()}
                </div>
              </div>
            ))
        }
      </div>
      <div className="formsFormWithModalPreview-Preview_Buttons">
        <button type="button" className="formsFormWithModalPreview-Preview_CloseBtn" onClick={closeModal}>
          もどる
        </button>
        <input type="submit" value="送信する" className="formsFormWithModalPreview-Preview_SubmitBtn" />
      </div>
    </form>
  )
}

export default FormModalPreview
