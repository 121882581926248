"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var InputImageController = /** @class */ (function (_super) {
    __extends(InputImageController, _super);
    function InputImageController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.connect = function () {
            if (_this.inputTarget) {
                _this.inputTarget.addEventListener('change', _this.handleChangeImage);
            }
        };
        _this.click = function () {
            if (_this.inputTarget) {
                _this.inputTarget.click();
            }
        };
        _this.handleChangeImage = function (e) {
            var target = e.target;
            var file = target.files && target.files[0];
            if (!file) {
                return;
            }
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                if (_this.imageTarget) {
                    _this.imageTarget.src = event.target.result; // FIXME 型が不明
                }
            };
            fileReader.readAsDataURL(file);
        };
        return _this;
    }
    InputImageController.targets = ['input', 'image'];
    return InputImageController;
}(stimulus_1.Controller));
exports.default = InputImageController;
