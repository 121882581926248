import $ from 'jquery'
import eventManager from '~/util/event_manager'
import GoogleMapsLoader from 'google-maps'

class SingleMaps {

  constructor(elm, opt) {
    this.map = undefined
    this.elm = elm
    this.opt = opt
    this.onLoad = this.onLoad.bind(this)
    this.init(elm, opt)
  }

  init(opt) {
    //APIリクエストが増えるのを防止する
    if (this.elm.children().size() >= 1) {
      return
    }

    GoogleMapsLoader.KEY = 'AIzaSyAor8U6kBuDl8jPVUJo6_lTawIpRqdPhp0'
    GoogleMapsLoader.load(this.onLoad)
  }

  onLoad(google) {
    let lat = this.elm.data('lat')
    let lng = this.elm.data('lng')
    let latlng = new google.maps.LatLng(lat, lng)
    let defaultOpt = {
      zoom: 16, //Googleマップのズーム率
      center: latlng, //表示するGoogleマップの中心地点
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      zoomControl: true,
      disableDoubleClickZoom: true,
      draggable: true,
      mapTypeControl: false,
      panControl: false,
      scaleControl: false,
      streetViewControl: false
    }
    this.opt = defaultOpt

    this.map = new google.maps.Map(this.elm.context, this.opt)
    new google.maps.Marker({position: latlng, map: this.map, title: latlng.toString(), draggable: false})

  }

  redraw() {
    this.map.checkResize()
  }
}
export default SingleMaps
