import React from 'react'
import PropTypes from 'prop-types'
import FixedEnterpriseReviewFormInner from '~/components/forms/review_form/fixed_enterprise_review_form_inner'
import CtocReviewFormStore from '~/stores/forms/reviews/ctoc_review_form_store'

export default class FixedCtocReviewForm extends React.Component {
  static propTypes = {
    service_id: PropTypes.number,
    is_history_backable: PropTypes.bool
  }

  constructor(props) {
    super(props)
  }

  render() {
    const reviewFormStore = new CtocReviewFormStore()
    reviewFormStore.setSelectedServiceId(this.props.service_id)
    reviewFormStore.setIsHistoryBackable(this.props.is_history_backable)
    return <FixedEnterpriseReviewFormInner reviewFormStore={reviewFormStore} serviceReadonlyTitle="オークション・フリマアプリ名"/>
  }
}
