// @flow
import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputOptionStore from '~/stores/forms/builder/input_option_store'
import InputStore from '~/stores/forms/builder/input_store'
import type {SelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputSingleSelectStore extends InputStore<string, SelectParam> {
  @observable options = [];
  constructor(param: SelectParam) {
    super(param)
  }

  init(param: SelectParam): void {
    super.init(param)
    param.options.forEach(((option) => {
      this.options.push(new InputOptionStore(option))
    }))
  }

  getValueText(): string | typeof undefined {
    //確認用の値はlabelを返す
    let value = this.getValue()
    let selectedOption

    if (value === null || typeof value === 'undefined') {
      return undefined
    }

    this.options.forEach((option) => {
      if (option.value.toString() === value.toString()) {
        selectedOption = option
      }
    })
    if (typeof selectedOption === 'undefined') {
      return undefined
    }
    return selectedOption.label || undefined
  }
}
