import React from 'react'
import ReactDOM from 'react-dom'
import BlogSearchResultBase from '~/components/search/blog_search_result_base'
import BlogCellSmartphone from '~/components/cell/blog_cell/blog_cell_smartphone'

class BlogSearchResultSmartphone extends BlogSearchResultBase {
  constructor(props) {
    super(props)
    this.state = {
      isRequesting: false,
      blogs: [],
      totalCount: 0
    }
  }

  renderBlogCell() {
    return this.state.blogs.map((blog) => {
      return (
        <BlogCellSmartphone key={blog.id} blog={blog} searchWords={this.props.search_words}/>
      )
    })
  }
}

export default BlogSearchResultSmartphone
