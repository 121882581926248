import React from 'react'
import ReactDOM from 'react-dom'
import RegisterUser from '~/components/common/register_user.jsx'
import ReviewsRegisterUserStore from '~/stores/forms/reviews/reviews_register_user_store'

export default class PopupRegisterUser extends React.Component {

  constructor(props) {
    super(props)

    this.clientInfo = {
      nick_name: this.props.reviewFormStore.review.reviewer_name,
      email: this.props.reviewFormStore.review.reviewer_email,
      loadingBarStore: this.props.store
    }
  }

  renderRegisterUser() {
    const store = new ReviewsRegisterUserStore(this.clientInfo)
    return <RegisterUser store={store}/>
  }

  onClickCloseBtn() {
    $.ji_popup.close_popup()
  }

  render() {
    return (
      <div className="register_user_popup_container">
        <div className="popup_review_inner">
          <h3 className="title">投稿が完了しました</h3>
          <p className="discription">承認されると表示されますので、しばらくお待ちください。</p>
          <div className="register_user_popup_inner__alert">
            {this.renderRegisterUser()}
          </div>
        </div>
        <button className="btn-gray" onClick={(e) => this.onClickCloseBtn(e)}>
          <span>閉じる</span>
        </button>
      </div>
    )
  }

}
