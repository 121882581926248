// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputCheckboxView from '~/components/forms/inputs/input_checkbox.jsx'
import InputNumberView from '~/components/forms/inputs/input_number.jsx'
import InputSelectView from '~/components/forms/inputs/input_select.jsx'
import InputNestedSelectView from '~/components/forms/inputs/input_nested_select.jsx'
import InputTelView from '~/components/forms/inputs/input_tel.jsx'
import InputTextView from '~/components/forms/inputs/input_text.jsx'
import InputTextareaView from '~/components/forms/inputs/input_text_area.jsx'
import InputReactSelectAsyncView from '~/components/forms/inputs/input_react_select_async.jsx'
import InputRadioView from '~/components/forms/inputs/input_radio.jsx'
import InputPhotosView from '~/components/forms/inputs/input_photos.jsx'
import InputSimpleEmailView from '~/components/forms/inputs/input_simple_email.jsx'
import FormBuilderValidator from '~/validator/forms/form_builder_validator'
import type InputStore from '~/stores/forms/builder/input_store'

export type Props<V, P> = {
  store: InputStore<V, P>
}

@observer
export default class InputWrapperView extends React.Component<Props<any, any>> {

  inputClasses() {
    return {
      [formBuilderTypes.FORM_INPUT_TYPE_CHECKBOX]: InputCheckboxView,
      [formBuilderTypes.FORM_INPUT_TYPE_EMAIL]: InputSimpleEmailView,
      [formBuilderTypes.FORM_INPUT_TYPE_NUMBER]: InputNumberView,
      [formBuilderTypes.FORM_INPUT_TYPE_SELECT]: InputSelectView,
      [formBuilderTypes.FORM_INPUT_TYPE_NESTED_SELECT]: InputNestedSelectView,
      [formBuilderTypes.FORM_INPUT_TYPE_TEL]: InputTelView,
      [formBuilderTypes.FORM_INPUT_TYPE_TEXT]: InputTextView,
      [formBuilderTypes.FORM_INPUT_TYPE_TEXTAREA]: InputTextareaView,
      [formBuilderTypes.FORM_INPUT_TYPE_REACT_SELECT]: InputReactSelectAsyncView,
      [formBuilderTypes.FORM_INPUT_TYPE_TEXT_SUGGESTION]: InputReactSelectAsyncView,
      [formBuilderTypes.FORM_INPUT_TYPE_RADIO]: InputRadioView,
      [formBuilderTypes.FORM_INPUT_TYPE_PHOTOS]: InputPhotosView,
    }
  }

  createInput() {
    const {store} = this.props
    if (store.disabled === true) {
      const showValue = store.getValueText()
      return (
        <div>
          <div className="fnt-size-10">
            {showValue}
          </div>
          <input type="hidden" name={store.getName()} value={store.getValue()} />
        </div>
      )
    }
    const component = this.inputClasses()[store.type]
    if (typeof component === 'undefined') {
      return <InputTextView store={store}/>
    }
    return React.createElement(component, {store: store})
  }

  renderAlert(): ?React.Node {
    const {store} = this.props
    if (!store.alertText || !store.useAlert) {
      return null
    }
    return (<span className="form_alert_label">{store.alertText}</span>)
  }

  renderNecessary() {
    const {store} = this.props
    let necessary_text = ''
    if (store.necessary === true) {
      necessary_text = '※必須'
    }
    return (
      <span className="alert_text co-red">
        {necessary_text}
        {this.renderAlert()}
      </span>
    )
  }

  renderOptionalText() {
    const {store} = this.props
    if (store.necessary === true || typeof store.necessary === 'undefined') {
      return
    }
    return (
      <span className="optional_text">任意</span>
    )
  }

  checkmarkClassName() {
    const {store} = this.props
    if (store.isChecked) {
      return "check-mark is-checked"
    }
    return "check-mark"
  }

  render() {
    const {store} = this.props

    if (store.type == 'hidden') {
      return <input type="hidden" name={store.getName()} value={store.getValue()} />
    }

    return (
      <div className={"input_wrapper type-" + store.type} >
        <label className="label">
          <span className="title"><label htmlFor={store.idForLabel()}>{store.label || '未指定'}</label></span>
          {this.renderOptionalText()}
          {this.renderNecessary()}
          <p className="input_description" dangerouslySetInnerHTML={{__html: store.description}}></p>
        </label>
        <div className={this.checkmarkClassName()}>
          {this.createInput()}
        </div>
      </div>
    )
  }
}
