import $ from 'jquery'

class Scroller {

  constructor(elm) {
    if (typeof document === 'undefined') {
      return
    }
    this.body = $("html, body")
    this.defaultDuration = '500'
    this.defaultMarginTop = 100
  }

  scrollTo(targetElm, {
    container = this.body,
    duration = this.defaultDuration,
    marginTop = this.defaultMarginTop,
    paddingForScroll = false,
    callback = () => {}
  }) {

    if (targetElm instanceof $ === false) {
      targetElm = $(targetElm)
    }

    if (container instanceof $ === false) {
      container = $(container)
    }

    const offsetTop = this.calcOffsetTop(targetElm, container)
    const scrollTop = offsetTop - marginTop

    if (paddingForScroll === true) {
      const paddingBottom = this.calcScrollPaddingBottom(targetElm, container)
      container.contents().animate({paddingBottom: paddingBottom}, duration, 'swing')
    }

    container.animate({scrollTop: scrollTop}, duration, 'swing', () => {
      callback()
    })
  }

  calcOffsetTop(targetElm, container) {
    if (container === this.body) {
      return targetElm.offset().top
    }
    const targetTop = targetElm[0].getBoundingClientRect().top
    const containerScrollTop = container.scrollTop()
    const containerTop = container.offset().top
    return containerScrollTop + (targetTop - containerTop)
  }

  calcScrollPaddingBottom(targetElm, container) {
    const offsetTop = this.calcOffsetTop(targetElm, container)
    const maxScrollTop = container.contents().height() - container.height()
    const paddingBottom = offsetTop - maxScrollTop
    return 0 < paddingBottom ? paddingBottom : 0
  }
}
export default new Scroller()
