"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendPrefixToInputName = exports.appendPrefixToFormInputNames = void 0;
var input_store_factory_1 = require("@hikakaku/shared/stores/estimate_form/common/input/input_store_factory");
var MULTI_INPUT_FORM_TYPE = [
    input_store_factory_1.InputType.PHOTOS,
    input_store_factory_1.InputType.CHECKBOX
];
var appendPrefixToFormInputNames = function (formParams, prefix) { return (formParams.map(function (params) { return (0, exports.appendPrefixToInputName)(params, prefix); })); };
exports.appendPrefixToFormInputNames = appendPrefixToFormInputNames;
var appendPrefixToInputName = function (params, prefix) {
    var name = MULTI_INPUT_FORM_TYPE.indexOf(params.type) !== -1
        ? "".concat(prefix, "[").concat(params.name, "][]")
        : "".concat(prefix, "[").concat(params.name, "]");
    return __assign(__assign({}, params), { rawName: params.name, name: name });
};
exports.appendPrefixToInputName = appendPrefixToInputName;
