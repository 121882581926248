import React from 'react'
import ShopPrefectureListStore from '~/stores/shop_prefecture_list_store'
import PrefectureSelector from '~/components/prefecture_selector/prefecture_selector'
import {toCamelcaseKeys} from '@hikakaku/shared/util/convert_case_keys'

export default class ShopListSelectPrefecture extends React.Component {
  constructor(props) {
    super(props)
    this.store = new ShopPrefectureListStore(toCamelcaseKeys(props, {deep: true}))
  }

  selectorRender() {
    if (this.store.isMobile === true) {
      return (
        <div className="shoplist__select_prefecture">
          <a className="shoplist__select_prefecture__popupbtn btn-low pc_none" onClick={this.store.onClickShowPopup}>
            地域で絞り込む
          </a>
        </div>
      )
    }
    return (
      <div className="prefecture_selector pc_onlyshow">
        <PrefectureSelector store={this.store} />
      </div>
    )
  }

  render() {
    return (
      <section className="shoplist__select_prefecture">
        {this.selectorRender()}
      </section>
    )
  }
}
