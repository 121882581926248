// @flow
import React from 'react'

type Props = {
  onClick: () => void
}

const GuidanceSliderModalTrigger = ({onClick}: Props) => (
  <div
    className="modalGuidanceSliderModal-GuidanceSlider_ModalTrigger"
    onClick={onClick}
  />
)

export default GuidanceSliderModalTrigger
