"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DialogStore = /** @class */ (function () {
    function DialogStore() {
        var _this = this;
        this.setDialog = function (dialog) {
            _this.dialog_ = dialog;
        };
        this.showDialog = function (data) {
            if (_this.dialog_ === undefined) {
                // eslint-disable-next-line no-console
                console.log('missed dialog @showDialog', data);
                return;
            }
            _this.dialog_.showDialog(data);
        };
        this.hideDialog = function () {
            if (_this.dialog_ === undefined) {
                // eslint-disable-next-line no-console
                console.log('missed dialog @hideDialog');
                return;
            }
            _this.dialog_.hideDialog();
        };
        this.showErrorDialog = function (title, description) {
            if (title === void 0) { title = '予期せぬエラーが発生しました'; }
            if (description === void 0) { description = 'ネットワークなどをご確認の上、再度お試しください'; }
            var flattened_description = Array.isArray(description) ? description.join('<br/>') : description;
            _this.showDialog({
                title: title,
                description: flattened_description,
                btnList: [
                    {
                        title: "閉じる",
                        callback: function () { return _this.hideDialog(); },
                        customClass: "btn-blue"
                    },
                ],
                show_time: -1
            });
        };
        this.showDialogWithCloseButton = function (title, description, options) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            var callBack = (options === null || options === void 0 ? void 0 : options.callBack) || (function () { });
            _this.showDialog({
                title: title,
                description: description,
                btnList: [
                    {
                        title: "閉じる",
                        callback: function () {
                            callBack();
                            _this.hideDialog();
                        },
                        customClass: "btn-blue"
                    },
                ],
                show_time: -1
            });
        };
    }
    return DialogStore;
}());
exports.default = new DialogStore();
