// @flow
import _ from 'lodash'
import {observable} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputStore from '~/stores/forms/builder/input_store'
import type {ImageFile, DropzoneParam} from '~/flowtyped/stores/forms/form_builder_store_types'
import {getImageOrientation} from '~/services/get_image_orientation_service'
import ThumbnailDefaultPath from 'images/ui/ui-dropzone.png'
interface Delegate {
  handleChange(): void;
}
const MAX_UPLOAD_SIZE_IN_MB = 5

export interface DropzoneStore {
  name: string | typeof undefined;
  value: ?ImageFile;
  hasDragEnter: boolean;
  orientation: number;
  accept: Array<string>;
  disabled: boolean;
  +thumbnail: string;
  +maxUploadSizeInMB: number;
  +maxUploadSize: number;
  +usesDefaultField: boolean;

  getName(): string | typeof undefined;
  getValue(): ImageFile | typeof undefined;
  setValue(value: ?ImageFile): void;
  handleDragEnter(): void;
  handleDragLeave(): void;
  resetValue(): void;
  updateValue(file: ?ImageFile): void;
}

export default class InputDropzoneStore extends InputStore<ImageFile, DropzoneParam> implements DropzoneStore {
  @observable hasDragEnter: boolean = false;
  @observable thumbnail: string = ThumbnailDefaultPath;
  @observable orientation: number = 1;
  @observable value: ?ImageFile = null;
  accept: Array<string> = ["image/jpeg", "image/png", "image/gif", "image/pjpeg"];
  delegate: ?Delegate;

  constructor(param: DropzoneParam): void {
    super(param)
    const self = (this: any)
    self.handleChange = this.handleChange.bind(this)
  }

  init(param: DropzoneParam): void {
    super.init(param)
    param = Object.assign({}, formBuilderTypes.DEFAULT_INPUT_PARAM, param)
    this.value = param.defaultValue || null
  }

  get maxUploadSizeInMB(): number {
    return MAX_UPLOAD_SIZE_IN_MB
  }

  get maxUploadSize(): number {
    return this.maxUploadSizeInMB * 1024 * 1024
  }

  get usesDefaultField(): boolean {
    return true
  }

  handleDragEnter(): void {
    this.hasDragEnter = true
  }

  handleDragLeave(): void {
    this.hasDragEnter = false
  }

  updateOrientation(file: ?ImageFile) {
    if (file instanceof File === false ||
    file === undefined ||
    file === null) {
      this.orientation = 1
      return
    }
    getImageOrientation(file, (orientation: number) => {
      this.orientation = orientation
    })
  }

  resetValue: () => void = () => {
    this.updateValue(null)
  }

  setDelegate(delegate: Delegate): void {
    this.delegate = delegate
  }

  getValue(): ImageFile | typeof undefined {
    return this.value || undefined
  }

  updateValue(file: ?ImageFile): void {
    this.setValue(file)
    this.updateThumbnail(file)
    this.updateOrientation(file)
    this.handleChange()
  }

  setValue(file: ?ImageFile): void {
    this.value = file
  }

  updateThumbnail(file: ?ImageFile): void {
    if (typeof file === 'undefined' || file === null) {
      this.thumbnail = ThumbnailDefaultPath
      return
    }
    this.thumbnail = file.preview
  }

  handleChange(): void {
    super.handleChange()
    if (this.delegate) {
      this.delegate.handleChange()
    }
  }
}
