import {observable} from 'mobx'
// @flow
class LoadingBarStore {
  @observable isLoading: boolean = false;

  constructor() {
  }

  showLoading(enabled: boolean) {
    this.isLoading = enabled
  }
}
export default new LoadingBarStore()
