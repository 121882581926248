// @flow
import _ from 'lodash'
import {observable} from 'mobx'
import InputResizingDropzoneStore from '~/stores/forms/builder/input_resizing_dropzone_store'
import InputStore from '~/stores/forms/builder/input_store'
import type {IObservableArray} from 'mobx'
import type {DataUrlInputParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputPhotosStore extends InputStore<string[], DataUrlInputParam> {
  @observable options = [];
  @observable dropzoneStores: IObservableArray<InputResizingDropzoneStore> = [];
  maxCount: number;
  onChangeDropzone: ?((InputStore<any, any>) => void | Array<(InputStore<any, any>) => void>);

  constructor(param: DataUrlInputParam) {
    super(param)
    const self = (this: any)
    self.handleChange = this.handleChange.bind(this)
    this.init(param)
  }

  init(param: DataUrlInputParam) {
    super.init(param)
    this.maxCount = param.inputOptions.count
    this.onChangeDropzone = param.onChangeDropzone

    this.dropzoneStores.clear()
    const defaultValues = (param.defaultValue instanceof Array) ? param.defaultValue : []
    defaultValues.slice(0, this.maxCount).forEach((defaultValue) => {
      const store = new InputResizingDropzoneStore({name: this.getName(), onChange: this.onChangeDropzone, defaultValue: defaultValue})
      store.setDelegate(this)
      this.dropzoneStores.push(store)
    })
    this.addNextDropzone()
  }

  getValue(): string[] {
    return this.createValue(this.dropzoneStores)
  }

  handleChange(): void {
    super.handleChange()
    this.dropzoneStores = this.dropzoneStores.filter((store) => store.value)
    this.addNextDropzone()
    this.validate()
  }

  createValue(stores: IObservableArray<InputResizingDropzoneStore>): string[] {
    const values: (?string)[] = stores.map((store) => store.value)
    return _.compact(values)
  }

  getValueText(): string | typeof undefined {
    const values = this.createValue(this.dropzoneStores)
    return values.length > 0 ? '(ファイルアップロード済)' : undefined
  }

  addNextDropzone() {
    if (this.dropzoneStores.length >= this.maxCount) { return }

    const store = new InputResizingDropzoneStore({name: this.getName(), onChange: this.onChangeDropzone, defaultValue: undefined})
    store.setDelegate(this)
    this.dropzoneStores.push(store)
  }
}
