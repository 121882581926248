// @flow
import React from 'react'
import InputCheckboxCellView from '~/components/forms/inputs/input_checkbox_cell.jsx'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type {MultiSelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

/*
 * @brief チェックボックスラッパー
 * */
@observer
export default class InputCheckboxView extends React.Component<Props<string[], MultiSelectParam>> {

  createCheckboxes() {
    const {store} = this.props
    return store.options.map((option, index) => {
      return (
        <InputCheckboxCellView store={option} name={store.getName()} key={index} />
      )
    })
  }

  render() {
    return (
      <div className="input" data-inputtype="checkbox" data-necessary="false">
        {this.createCheckboxes()}
      </div>
    )
  }
}
