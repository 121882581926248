// @flow

/*
 * XXX: DEPRECATED
 *
 * 現状 InputFilesStore は Dropzone の accept 設定や preview の関係上、写真しか受け付けない
 * 写真をアップロードさせる場合は、リサイズ機能がついた InputPhotosStore を使うようにする
 * 現状の InputFilesStore は後方互換 (EstimateFormInputPhoto などで使っている) のために残しておくが、
 * 今後の展開次第で削除 or 再定義する
 */

import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputDropzoneStore from '~/stores/forms/builder/input_dropzone_store'
import InputStore from '~/stores/forms/builder/input_store'
import type {IObservableArray} from 'mobx'
import type {ImageFile, FileInputParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputFilesStore extends InputStore<ImageFile[], FileInputParam> {
  @observable options = [];
  @observable dropzoneStores: IObservableArray<InputDropzoneStore> = [];

  constructor(param: FileInputParam) {
    super(param)
    const self = (this: any)
    self.handleChange = this.handleChange.bind(this)
    this.init(param)
  }

  init(param: FileInputParam) {
    super.init(param)
    this.dropzoneStores.clear()
    const count = param.inputOptions.count
    _.times(count).forEach(() => {
      const store = new InputDropzoneStore({name: this.getName(), onChange: param.onChangeDropzone})
      store.setDelegate(this)
      this.dropzoneStores.push(store)
    })
  }

  getValue(): ImageFile[] {
    return this.createValue(this.dropzoneStores)
  }

  handleChange(): void {
    super.handleChange()
    this.validate()
  }

  createValue(stores: IObservableArray<InputDropzoneStore>): ImageFile[] {
    const values = stores.map((store) => store.value)
    return _.compact(values)
  }

  getValueText(): string | typeof undefined {
    const values = this.createValue(this.dropzoneStores)
    return values.length > 0 ? '(ファイルアップロード済)' : undefined
  }
}
