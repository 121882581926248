class GoogleAnalyticsManager {
  postGAEvent(category, action, label) {
    gtag('event', action, {
      event_category: category,
      event_label: label
    })
    return true
  }
  postGAEventWithValue(category, action, label, value) {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    })
    return true
  }
  postGAEventImpression(category, label) {
    gtag('event', 'impression', {
      event_category: category,
      event_label: label,
      non_interaction: true
    })
    return true
  }
}
export default new GoogleAnalyticsManager()
