// @flow
import React from 'react'
import axios from 'axios'
import {observable, computed, reaction} from 'mobx'
import loadingBarStore from '~/stores/loading_bar_store'

export default class RegisterUserStore {
  USER_API_URI: string = '/api/users';
  SUCCESS_TITLE: string = '会員登録が完了しました。';
  SUCCESS_DESCRIPTION: string = '認証のメールがお客様に送信されたのでご確認お願い致します。';
  FAILED_TITLE: string = '会員登録の処理に失敗しました。';
  FAILED_DESCRIPTION: string = '大変申し訳有りませんが時間を空けてからお試しください。';

  @observable _description: Function = () => {
    return (
      <div className="register_user_attention">
        <div className="register_user_eyecatch_word">
          <div className="register_user_up_word">
            お得なクーポンやサポート付き！
          </div>
          <div className="register_user_second_up_word">
            入力を簡単にして、他の持ち物もまとめて査定しよう！
          </div>
        </div>
        <br />
        会員登録に進みましょう！
      </div>
    )
  };
  @observable name: string;
  @observable nick_name: string;
  @observable email: string;
  @observable telephoneNumber: string;
  @observable address: string;
  @observable loadingBarStore: Object;
  @observable buttonClass: string = 'btn-orange assessment_btn';
  @observable isDisabled: boolean = false;

  onClick: Function;
  suceededAction: Function;
  failedAction: Function;

  constructor(clientInfo: Object) {
    this.onClick = this.onClick.bind(this)
    this.suceededAction = this.suceededAction.bind(this)
    this.failedAction = this.failedAction.bind(this)

    this.name = clientInfo.name
    this.nick_name = clientInfo.nick_name
    this.email = clientInfo.email
    this.telephoneNumber = clientInfo.telephoneNumber
    this.address = clientInfo.address
    this.loadingBarStore = clientInfo.loadingBarStore
  }

  get description(): () => Function {
    return this._description
  }

  set description(component: () => Function): void {
    this._description = component
  }

  onClick(): void {
    this.isDisabled = true
    this.buttonClass = 'btn-gray assessment_btn'
    loadingBarStore.showLoading(true)
    axios.post(this.USER_API_URI, {
      user: {
        name: this.name,
        nick_name: this.nick_name,
        email: this.email,
        telephone_number: this.telephoneNumber,
        address: this.address
      }
    })
      .then(this.suceededAction)
      .catch(this.failedAction)
  }

  suceededAction(): void {
    loadingBarStore.showLoading(false)
    this.suceededDialog()
  }

  failedAction(error: {response: {data: {messages: string[]}, status: number}}): void {
    this.isDisabled = false
    this.buttonClass = 'btn-orange assessment_btn'
    loadingBarStore.showLoading(false)
    const description: string[] = error.response.status === 400 ? error.response.data.messages : [this.FAILED_DESCRIPTION]
    this.failedDialog(description.join('<br/>'))
  }

  suceededDialog(): void {
    this.showDialog(this.SUCCESS_TITLE, this.SUCCESS_DESCRIPTION)
  }

  failedDialog(description: string): void {
    this.showDialog(this.FAILED_TITLE, description)
  }

  showDialog(title: string, description: string): void {
    throw new Error("子クラスで実装してください")
  }
}
