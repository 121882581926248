import '@hikakaku/shared/polyfill/index'
import '~/lib/error_notifier'
import ReactRailsUJS from 'react_ujs'
import jquery from 'jquery'
import moment from 'moment'
import 'moment/locale/ja'
import 'lazysizes'

require.context('images/', true)

window.$ = window.jQuery = jquery
moment.locale('ja')

import DialogStore from '~/stores/dialog_store'
window.DialogStore = DialogStore

const componentRequireContext = require.context("~/components", true)
ReactRailsUJS.useContext(componentRequireContext)

import RailsUJS from '@rails/ujs'
RailsUJS.start()

import '@stimulus/polyfills'
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./stimulus", true, /\.(js|ts)x?$/)
application.load(definitionsFromContext(context))

import 'bootstrap-sass'

import 'number-to-locale-string-polyfill'
import '~/cars'
import '~/util/settings'
import '@hikakaku/shared/util/util'
import '~/util/url_manager'
import '~/util/api_manager'
import '~/util/sns_buttons'
import '~/components/AppConstants'
import '~/wp-app'
import '~/components/components'
import '~/components/hypernova'
import '~/jquery-modules/modules'
import '~/containers/containers'
import '~/legacy/common_legacy'
import '~/legacy/single_product_legacy'
