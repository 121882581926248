import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerNickname extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.onChangeNickName = this.handleChangeNickName.bind(this)
  }

  handleChangeNickName(e) {
    this.props.reviewFormStore.nickname = e.target.value
  }

  render() {
    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="review_name">ニックネーム</label><span className="required">※必須</span>
        </div>
        <input className="reviewpopup-Form_textinput" type="text" id="review_name" name="review_name" placeholder="ニックネームを入力してください" value={this.props.reviewFormStore.nickname} onChange={this.onChangeNickName}/>
      </div>
    )
  }
}
