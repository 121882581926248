import $ from 'jquery'
import Validator from '~/validator/validator'

const formCheckMark = () => {
  const $checkMarkElms = $('.js-form-check-mark')
  if ($checkMarkElms.length === 0) {
    return
  }
  const changeStyle = (elm, value) => {
    if (value && value !== '') {
      $(elm).addClass('is-checked')
    } else {
      $(elm).removeClass('is-checked')
    }
  }
  const bindChange = (elm) => {
    elm.childNodes[0].addEventListener('change', (e) => {
      if (elm.childNodes[0].type === 'email') {
        return
      }
      changeStyle(elm, e.currentTarget.value)
    })
    elm.childNodes[0].addEventListener('keyup', (e) => {
      if (elm.childNodes[0].type === 'email' && !Validator.validateEmail(e.currentTarget.value)) {
        changeStyle(elm)
      } else {
        changeStyle(elm, e.currentTarget.value)
      }
    })
  }
  $checkMarkElms.each((idx, elm) => {
    bindChange(elm)
    changeStyle(elm, elm.childNodes[0].value)
  })
}

formCheckMark()
