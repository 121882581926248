// @flow
import {observable} from 'mobx'
import {ImageResizer} from '@hikakaku/shared/lib/image_resizer'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputStore from '~/stores/forms/builder/input_store'
import DialogStore from '~/stores/dialog_store'
import type {ResizingDropzoneParam} from '~/flowtyped/stores/forms/form_builder_store_types'
import ThumbnailDefaultPath from 'images/ui/ui-dropzone.png'

interface Delegate {
  handleChange(): void;
}

const MAX_IMAGE_SIZE  = 1200

export interface ResizingDropzoneStore {
  name: string | typeof undefined;
  value: ?string;
  hasDragEnter: boolean;
  orientation: ?null;
  accept: Array<string>;
  disabled: boolean;
  +maxUploadSizeInMB: number | null;
  +maxUploadSize: number | null;
  +thumbnail: string;
  +usesDefaultField: boolean;

  getName(): string | typeof undefined;
  getValue(): string | typeof undefined;
  setValue(value: ?string): void;
  handleDragEnter(): void;
  handleDragLeave(): void;
  resetValue(): void;
  updateValue(file: ?File): void;
}

export default class InputResizingDropzoneStore extends InputStore<string, ResizingDropzoneParam> implements ResizingDropzoneStore {
  @observable hasDragEnter: boolean = false;
  @observable value: ?string = undefined;
  @observable fileName: ?string = undefined;
  accept: Array<string> = ["image/jpeg", "image/png", "image/gif", "image/pjpeg"];
  delegate: ?Delegate;

  constructor(param: ResizingDropzoneParam = {}): void {
    super(param)
  }

  init(param: ResizingDropzoneParam): void {
    super.init(param)
    this.value = param.defaultValue || formBuilderTypes.DEFAULT_INPUT_PARAM.defaultValue || undefined
  }

  get thumbnail(): string {
    return this.value || ThumbnailDefaultPath
  }

  get maxUploadSizeInMB(): number | null  {
    return null
  }

  get maxUploadSize(): number | null  {
    return null
  }

  get usesDefaultField(): boolean {
    return false
  }

  handleDragEnter(): void {
    this.hasDragEnter = true
  }

  handleDragLeave(): void {
    this.hasDragEnter = false
  }

  resetValue: () => void = () => {
    this.fileName = undefined
    this.updateValue(null)
  }

  setDelegate(delegate: Delegate): void {
    this.delegate = delegate
  }

  getFileName(): string | typeof undefined {
    return typeof this.fileName === 'string' ? this.fileName : undefined
  }

  getValue(): string | typeof undefined {
    return typeof this.value === 'string' ? this.value : undefined
  }

  updateValue(file: ?File): void {
    if (file) {
      ImageResizer.resize(file, MAX_IMAGE_SIZE, MAX_IMAGE_SIZE, (dataUrl: string) => {
        this.fileName = file.name
        this.setValue(dataUrl)
        this.handleChange()
      }, (e: Error) => {
        if (ImageResizer.isImageResizerError(e)) {
          DialogStore.showDialogWithCloseButton(ImageResizer.errorMesssage(e))
        }
        throw e
      })
    } else {
      this.setValue(null)
      this.handleChange()
    }
  }

  setValue(value: ?string): void {
    this.value = value
  }

  handleChange(): void {
    super.handleChange()
    if (this.delegate) {
      this.delegate.handleChange()
    }
  }
}
