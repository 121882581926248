import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'
import {CSSTransitionGroup} from 'react-transition-group'

@observer
class OverlayModal extends React.Component {
  constructor(props) {
    super(props)
    this.onClickContainer = this.onClickContainer.bind(this)
    this.onClickContent = this.onClickContent.bind(this)
  }

  onClickContainer() {
    const {store} = this.props
    store.close()
  }

  onClickContent(e) {
    e.stopPropagation()
  }

  renderContainer() {
    const {store} = this.props

    if (!store.active) {
      return <div style={{ display: 'none' }} />
    }

    return (
      <div className="overlay-modal">
        <div className="overlay-modal-container" onClick={this.onClickContainer}>
          <div className="overlay-modal-content" onClick={this.onClickContent}>
            {store.content}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="overlay-modal-root">
        <CSSTransitionGroup
          transitionName="fade"
          transitionEnter={true}
          transitionEnterTimeout={200}
          transitionLeave={true}
          transitionLeaveTimeout={100}
          component="div">
          {this.renderContainer()}
        </CSSTransitionGroup>
      </div>
    )
  }
}

OverlayModal.propTypes = {
  store: PropTypes.shape({
    active: PropTypes.bool,
    content: PropTypes.node,
    fireEvents: PropTypes.func,
    close: PropTypes.func
  })
}

// Fix: Create container components.
import overlayModalStore from '~/stores/overlay_modal_store'

const Modal = () => <OverlayModal store={overlayModalStore} />

export default Modal
