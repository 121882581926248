"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var get_or_default_1 = require("@hikakaku/shared/util/get_or_default");
var ContentAccordionController = /** @class */ (function (_super) {
    __extends(ContentAccordionController, _super);
    function ContentAccordionController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.boundaryHeight = 126; // px
        _this.openButtonHideClass = '';
        _this.closeButtonHideClass = '';
        _this.connect = function () {
            if (!_this.containerTarget || !_this.openButtonTarget || !_this.closeButtonTarget) {
                return;
            }
            _this.boundaryHeight = Number(_this.data.get('boundary-height'));
            _this.openButtonHideClass = (0, get_or_default_1.getOrDefault)(_this.data, 'open-button-hide-class');
            _this.closeButtonHideClass = (0, get_or_default_1.getOrDefault)(_this.data, 'close-button-hide-class');
            _this.openButtonTarget.classList.add(_this.openButtonHideClass);
            _this.closeButtonTarget.classList.add(_this.closeButtonHideClass);
            if (_this.containerTarget.scrollHeight < _this.boundaryHeight) {
                return;
            }
            _this.openButtonTarget.classList.remove(_this.openButtonHideClass);
            _this.containerTarget.style.height = "".concat(_this.boundaryHeight, "px");
            _this.openButtonTarget.addEventListener('click', _this.open);
            _this.closeButtonTarget.addEventListener('click', _this.close);
        };
        _this.open = function () {
            if (!_this.containerTarget || !_this.openButtonTarget || !_this.closeButtonTarget) {
                return;
            }
            _this.containerTarget.style.height = "".concat(_this.containerTarget.scrollHeight, "px");
            _this.openButtonTarget.classList.add(_this.openButtonHideClass);
            _this.closeButtonTarget.classList.remove(_this.closeButtonHideClass);
        };
        _this.close = function () {
            if (!_this.containerTarget || !_this.openButtonTarget || !_this.closeButtonTarget) {
                return;
            }
            _this.containerTarget.style.height = "".concat(_this.boundaryHeight, "px");
            _this.openButtonTarget.classList.remove(_this.openButtonHideClass);
            _this.closeButtonTarget.classList.add(_this.closeButtonHideClass);
        };
        return _this;
    }
    ContentAccordionController.targets = ['container', 'openButton', 'closeButton'];
    return ContentAccordionController;
}(stimulus_1.Controller));
exports.default = ContentAccordionController;
