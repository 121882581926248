// @flow
import * as React from 'react'
import {observer} from 'mobx-react'
import InputDropzoneView from '~/components/forms/inputs/input_dropzone.jsx'
import PhotoBigView from '~/components/forms/inputs/photo_big_view.jsx'
import type {DropzoneStore} from '~/stores/forms/builder/input_dropzone_store'
import type {ResizingDropzoneStore} from '~/stores/forms/builder/input_resizing_dropzone_store'

interface Store {
  dropzoneStores: Array<DropzoneStore | ResizingDropzoneStore>;
}

type Props = { store: Store };

/*
 * @brief InputPhotos
 * */
@observer
export default class InputPhotosView extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  renderDropzones(): React.Node[] {
    const {store} = this.props
    return store.dropzoneStores.map((dropzoneStore, index) => {
      const {thumbnail, orientation, resetValue, value} = dropzoneStore
      return (
        <div className="cell" key={index}>
          <InputDropzoneView store={dropzoneStore}>
            <PhotoBigView thumbnail={thumbnail}
              orientation={orientation}
              withDeleteButton={Boolean(value)}
              resetValue={resetValue} />
          </InputDropzoneView>
        </div>
      )
    })
  }

  render() {
    return (<div className="dropzone-wrapper">
      {this.renderDropzones()}
    </div>)
  }
}
