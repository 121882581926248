import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import UrlManager from '../../../util/url_manager'

class ItemCell extends React.Component {

  constructor(props) {
    super(props)
  }

  createImageNone() {

    let cats = this.props.item.categories

    let ids = ""
    cats.forEach((cat) => {
      ids += " id" + cat.id
    })

    return (
      <div className="image_wrapper">
        <div className={"taxonomy thumb_wrapper hover_bg " + ids}>
          <div className={"taxonomy thumb icon " + ids}>
          </div>
        </div>
      </div>
    )
  }

  getPrice() {
    let item = this.props.item
    if (item.current_highest_price <= 1 ||
      typeof item.current_highest_price === 'undefined' ||
      item.current_highest_price == null) {
      return (
        <p className="price fnt-size-8 co-gray-3" >
          <em>要見積もり</em>
        </p>
      )

    } else {
      return (
        <p className="price co-red" >
          ¥<em>{item.current_highest_price.toLocaleString()}</em>
        </p>
      )
    }
  }

  getImage() {
    let item = this.props.item
    if (!item.image_url ||
      typeof item.image_url === 'object' ||
      (typeof item.image_url === 'string' && item.image_url.search("noImg.png") != -1)
    ) {
      return this.createImageNone()

    } else {

      return (
        <div className="image_wrapper">
          <img src={item.image_url} style={{border: 0}} className="lazyload" />
        </div>
      )
    }
  }

  render() {
    let item = this.props.item

    return (
      <li className="product_cell">
        <a className="link" href={UrlManager.getItemPath(item.url)}>
          {this.getImage()}
          <div className="meta_info">
            <h3 className="fnt-size-8">{item.name}</h3>
            <p className="price_title">最高買取価格</p>
            {this.getPrice()}
          </div>
        </a>
      </li>
    )
  }
}

export default ItemCell
