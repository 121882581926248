import $ from 'jquery'

class ApiManager {
  constructor() {
    this.requestData = undefined
  }

  defaultRequestData() {
    return {
      url: '',
      dataType: '',
      request_data: {},
      type: 'GET',
      onSuccess: function(json) {},
      onError: function(request, textStatus, errorThrown) {}
    }
  }

  request(requestObject) {
    this.requestData = requestObject
    if (typeof this.requestData.type === "undefined") {
      this.requestData.type = 'GET'
    }
    $.ajax({
      url: requestObject.url,
      dataType: requestObject.dataType,
      data: requestObject.request_data,
      type: this.requestData.type,
      success: function(json) {
        requestObject.onSuccess(json)
      },
      error: function(request, textStatus, errorThrown) {
        requestObject.onError(request, textStatus, errorThrown)
      }
    })
  }
}

let apiManager = new ApiManager()
export default apiManager
window.JiApiManager = apiManager
