import React from 'react'
import UrlManager from '~/util/url_manager.js'

export default class Initializer extends React.Component {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.jsInitalize()
    }
  }
  jsInitalize() {
    window.jis_.root_uri = this.props.root_uri
    window.jis_.c_root_uri = this.props.c_root_uri
    UrlManager.init()
  }
  render() {
    return (
      <span className="js_initalizer">
      </span>
    )
  }
}
