"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var QuoteReviewModalStore = /** @class */ (function () {
    function QuoteReviewModalStore(_a) {
        var quotationCode = _a.quotationCode;
        var _this = this;
        this.isOpen = false;
        this.open = function () {
            _this.isOpen = true;
        };
        this.handleClose = function () {
            _this.isOpen = false;
        };
        this.quotationCode = quotationCode;
    }
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], QuoteReviewModalStore.prototype, "isOpen", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], QuoteReviewModalStore.prototype, "open", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], QuoteReviewModalStore.prototype, "handleClose", void 0);
    return QuoteReviewModalStore;
}());
exports.default = QuoteReviewModalStore;
