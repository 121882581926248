// @flow
import _ from 'lodash'
import {observable} from 'mobx'
import InputStore from '~/stores/forms/builder/input_store'
import InputStoreFactory from '~/stores/forms/builder/input_store_factory'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import Validator from '~/validator/forms/form_builder_validator'
import type {InputParam} from '~/flowtyped/stores/forms/form_builder_store_types'
import type {IObservableArray} from 'mobx'

export default class FormBuilderStore {
  @observable params: IObservableArray<InputParam> = [];
  @observable inputStores: IObservableArray<InputStore<any, any>> = [];

  constructor(params: Array<InputParam>): void {
    this.init(params)
    const self = (this: any)
    self.onSubmit = this.onSubmit.bind(this)
  }

  init(params: Array<InputParam>): void {
    this.initParams(params)
    this.inputStores.clear()
    this.params.forEach((param) => {
      this.addInputStoreByParams(param)
    })
  }

  createInputStoreByParams(param: InputParam): InputStore<any, InputParam> {
    return InputStoreFactory.createInputStore(param)
  }

  getInputStoreByName(name: string) {
    return _.find(this.inputStores, {name: name})
  }

  addInputStoreByParams(param: InputParam, at: ?number = undefined): void {
    const inputStore = this.createInputStoreByParams(param)
    this.addInputStore(inputStore, at)
  }

  /*
   * @param inputStore InputStore
   * @param at 追加するIndex位置
   * */
  addInputStore(inputStore: InputStore<any, any>, at: ?number = this.inputStores.length /*指定がない場合は末尾に追加する*/): void {
    this.inputStores.splice(at, 0, inputStore)
  }

  removeInputStoreByName(name: string): void {
    const index: number = _.findIndex(this.inputStores, {name: name})
    if (index < 0) {
      return
    }
    this.removeInputStore(index)
  }

  removeInputStore(index: number): void {
    this.inputStores.splice(index, 1)
  }

  getInputStores(): Array<InputStore<any, any>> {
    return this.inputStores
  }

  //postクエリ用のobjectを返す
  createValues(): Object {
    return _({}).tap((obj) => {
      this.inputStores.forEach((store: any) => {
        obj[store.name] = store.getValue()
      })
    }).value()
  }

  //ラベルを含む確認用のobjectの配列を返す
  createConfirmValues(): Array<Object> {
    return this.inputStores.map((store: InputStore<any, any>) => {
      return {
        value: store.getValueText(),
        label: store.label,
        name: store.name
      }
    })
  }

  validate(): boolean {
    return Validator.validate(this)
  }

  onSubmit(): void {
    this.validate()
  }

  initParams(params: Array<InputParam>): void {
    this.params.clear()
    params.forEach((param: InputParam) => {
      this.params.push(Object.assign({}, formBuilderTypes.DEFAULT_INPUT_PARAM, param))
    })
  }
}
