import $ from 'jquery'
import eventManager from '~/util/event_manager'
import InView from 'in-view'

class UserInsightEventTracker {
  constructor() {
    this.onClick = this.onClick.bind(this)
    this.onInView = this.onInView.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    eventManager.onReady((() => {
      this.onReady()
    }))
  }

  onReady() {
    $(document).on('click', '[data-user-insight-track-event-click]', this.onClick)
    InView('[data-user-insight-track-event-inview]').on('enter', this.onInView)
    $(document).on('submit', '[data-user-insight-track-event-submit]', this.onSubmit)
  }

  onClick(e) {
    const target = $(e.currentTarget)
    const args = target.data('user-insight-track-event-click').split(' ')
    this.postUIEvent(args)
    return true
  }

  onInView(e) {
    const target = $(e)
    if (target.data('user-insight_inviewed') === true) {
      return false
    }
    target.data('user-insight_inviewed', true)
    const args = target.data('user-insight-track-event-inview').split(' ')
    this.postUIEvent(args)
    return true
  }

  onSubmit(e) {
    const target = $(e.currentTarget)
    const args = target.data('user-insight-track-event-submit').split(' ')
    this.postUIEvent(args)
    return true
  }

  postUIEvent(args) {
    // 非同期読み込みのためにwindow._uiconvが未定義であることがある
    window._uiconv = typeof (window._uiconv) === "undefined" || !window._uiconv ? [] : window._uiconv
    window._uiconv.push({id: args[0], free_1: args[1]})
  }
}

export default new UserInsightEventTracker()
