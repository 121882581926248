//form constants
export const FORM_INPUT_TYPE_CHECKBOX = 'checkbox'
export const FORM_INPUT_TYPE_EMAIL = 'email'
export const FORM_INPUT_TYPE_NUMBER = 'number'
export const FORM_INPUT_TYPE_SELECT = 'selectbox'
export const FORM_INPUT_TYPE_NESTED_SELECT = 'nested_selectbox'
export const FORM_INPUT_TYPE_TEL = 'tel'
export const FORM_INPUT_TYPE_TEXT = 'text'
export const FORM_INPUT_TYPE_TEXTAREA = 'textarea'
export const FORM_INPUT_TYPE_REACT_SELECT = 'react_select' // deprecated: text_suggestion に統一
export const FORM_INPUT_TYPE_TEXT_SUGGESTION = 'text_suggestion'
export const FORM_INPUT_TYPE_RADIO = 'radio'
export const FORM_INPUT_TYPE_PHOTOS = 'photos'

export const DEFAULT_INPUT_PARAM = {
  type: FORM_INPUT_TYPE_TEXT,
  necessary: false,
  label: "タイトル",
  description: "",
  name: "example",
  options: [],
  defaultValue: "",
  placeholder: "",
  disabled: false,
  precheck: true,
  isChecked: false,
  useAlert: false,
  onChange: undefined, //function or functions array
  onBlur: undefined, //function
  inputOptions: {}
}

//option
export const DEFAULT_INPUT_OPTION = {
  label: "タイトル",
  value: "val",
  checked: false
}

export const REACT_SELECT_RESOUCE_TYPE_ITEM = 'item'
export const REACT_SELECT_RESOUCE_URLS = {
  [REACT_SELECT_RESOUCE_TYPE_ITEM]: '/api/elasticsearch/items/search_for_estimate'
}

export const DEFAULT_REACT_SELECT_INPUT_OPTIONS = {
  url: '', // resouce url
  requestDataOptions: {}, // request options
  scrollContainerSelector: 'body'
}
