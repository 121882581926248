import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

const TEXT_AREA_PLACEHOLDER =
  "実際にあなたが売った商品の名前・型番・査定金額・スタッフの対応など買取の体験談を教えてください。" +
  "また、買取に限らず販売・設備・立地などの感想でも投稿いただけます。\n" +
  "※重複したクチコミや暴言や誹謗中傷の場合は非公開とさせていただきます。"

@observer
export default class ReviewFormInnerContent extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.onChangeContent = this.handleChangeContent.bind(this)
  }

  handleChangeContent(e) {
    this.props.reviewFormStore.content = e.target.value
  }

  render() {
    const reviewFormStore = this.props.reviewFormStore

    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="review_textarea">クチコミ内容</label><span className="required">※必須</span>
        </div>
        <div>
          <textarea className="reviewpopup-Form_textarea" id="review_textarea" rows="16" cols="50" placeholder={TEXT_AREA_PLACEHOLDER} value={reviewFormStore.content} onChange={this.onChangeContent}></textarea>
        </div>
      </div>
    )
  }
}
