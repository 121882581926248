// @flow
import * as React from 'react'
import Modal from "react-responsive-modal"

type Props = {
  children: React.Node
} & typeof Modal

type State = {
  open: boolean
}

type TriggerProps = {
  onClick: () => void
}

type TriggerType = React.ComponentType<TriggerProps>

/**
 * Modalを作成するHOC.
 * 引数に渡したComponentをクリックすると表示するModalのComponentを返す。
 * 返り値のModalのchildとして渡せたComponentは、Modal内部でrenderされる.
 * @example
 * const Button = (props) => (<button onClick={props.onClick}>button</button>)
 * const ModalWithButton = createModalWith(Button);
 * const ModalWithContent = (props) => (
 *  <ModalWithButton center>
 *    <p>{props.text}</p>
 *  </ModalWithButton>
 */
const createModalWith = (Trigger: TriggerType) => {
  return class ModalWithTrigger extends React.Component<Props, State> {
    state = {
      open: false
    }

    openModal = () => {
      this.setState({
        open: true
      })
    }

    closeModal = () => {
      this.setState({
        open: false
      })
    }

    render() {
      const {open} = this.state
      const {children, ...otherProps} = this.props
      return (
        <div>
          <Trigger onClick={this.openModal}/>
          <Modal
            open={open}
            onClose={this.closeModal}
            center
            {...otherProps}
          >
            {children}
          </Modal>
        </div>
      )
    }
  }
}

export default createModalWith
