import $ from 'jquery'
import 'slick-carousel'
import eventManager from '~/util/event_manager'
import CategoryCarousel from '~/jquery-modules/carousels/category_carousel.js'

class CarouselManager {

  constructor() {
  }

  onReady() {
    new CategoryCarousel().onReady()
  }
}

eventManager.onReady(new CarouselManager().onReady)
