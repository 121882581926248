import $ from 'jquery'
import eventManager from '~/util/event_manager'
import scroller from '@hikakaku/shared/jquery-modules/scroller'
export default class Tabs {

  constructor(elm) {
    this.elm = elm
    this.init()
  }

  init() {
    this.onClickTab = this.onClickTab.bind(this)
    this.btns = this.elm.find(".js-tab__btn")
    this.cells = this.elm.find(".js-tab__cell")
    this.btns.on('click', this.onClickTab)
  }

  onClickTab(e) {
    let target = $(e.currentTarget)
    let id = target.data('tab-id')
    this.changeShowCell(id)
    this.changeActiveBtn(id)

    if (target.data('tab-with-scroll') === true) {
      this.scrollToTabTop()
    }

    this.doCallBack(e)
  }

  changeShowCell(id) {
    this.cells.removeClass('show')
    let showTarget = this.elm.find(`.js-tab__cell[data-tab-id="${id}"]`)
    showTarget.addClass('show')
  }

  changeActiveBtn(id) {
    this.btns.removeClass('active')
    this.elm.find(`.js-tab__btn[data-tab-id=${id}]`).addClass('active')
  }

  scrollToTabTop() {
    scroller.scrollTo(this.elm)
  }

  doCallBack(e) {
  }

}

class TabsManager {

  constractor() {
  }

  onReady() {
    let tabs = $('.js-tabs')
    tabs.map((index, tab) => {
      new Tabs($(tab))
    })
  }

}

eventManager.onReady(new TabsManager().onReady)
