"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Status;
(function (Status) {
    Status[Status["INITIAL"] = 0] = "INITIAL";
    Status[Status["LOADING"] = 1] = "LOADING";
    Status[Status["SUCCESS"] = 2] = "SUCCESS";
    Status[Status["FAILED"] = 3] = "FAILED";
})(Status || (Status = {}));
var ApiStatus = /** @class */ (function () {
    function ApiStatus(status) {
        this.status = status;
    }
    Object.defineProperty(ApiStatus.prototype, "isInitial", {
        get: function () {
            return this.status === Status.INITIAL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApiStatus.prototype, "isLoading", {
        get: function () {
            return this.status === Status.LOADING;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApiStatus.prototype, "isSuccess", {
        get: function () {
            return this.status === Status.SUCCESS;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApiStatus.prototype, "isFailed", {
        get: function () {
            return this.status === Status.FAILED;
        },
        enumerable: false,
        configurable: true
    });
    ApiStatus.INITIAL = new ApiStatus(Status.INITIAL);
    ApiStatus.LOADING = new ApiStatus(Status.LOADING);
    ApiStatus.SUCCESS = new ApiStatus(Status.SUCCESS);
    ApiStatus.FAILED = new ApiStatus(Status.FAILED);
    return ApiStatus;
}());
exports.default = ApiStatus;
