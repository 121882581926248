import './blog_card_phone_number'
import './tabs'
import './tabs/store_info_tabs'
import './google_maps_creator'
import './auto_input_form_address'
import './accordion'
import './header_navi'

if (typeof document !== 'undefined') {
  require('~/jquery-modules/carousel_manager.js')
  require('~/jquery-modules/google_analytics_event_tracker')
  require('~/jquery-modules/event_tracker/blog_content_tracker')
  require('~/jquery-modules/event_tracker/form_tracker')
  require('~/jquery-modules/event_tracker/site_header_link_event_tracker')
  require('~/jquery-modules/auto_focus')
  require('~/jquery-modules/scroll_to_target')
  require('~/jquery-modules/bulk_estimate_bottom_btn.js')
  require('~/jquery-modules/file_upload.js')
  require('~/jquery-modules/form/form_check_mark.js')
  require('~/jquery-modules/user_insight_event_tracker.js')
  require('~/jquery-modules/best_answer_btn')
  require('~/jquery-modules/kaitori_history_item_scroller_manager.js')
}
