import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerUpdateNickname extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.handleUpdateNickname = this.handleUpdateNickname.bind(this)
  }

  handleUpdateNickname(e) {
    this.props.reviewFormStore.updatesNickname = e.target.checked || null
  }

  render() {
    return (
      <label className="reviewpopup-CheckConfirm_wrapper">
        <input id="review_update_nickname" className="reviewpopup-CheckConfirm_checkbox" type="checkbox" value="1" onClick={this.handleUpdateNickname} />ニックネームを登録情報に反映する
      </label>
    )
  }
}
