import React from 'react'
import ReactDOM from 'react-dom'
import UrlManager from '~/util/url_manager'
import striptags from 'striptags'
import excerpt from 'excerpt'
import Highlighter from '~/util/highlighter'

const highlighter = new Highlighter()

export default class BlogCellSmartphone extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const blogText = highlighter.highlight(excerpt(
      striptags(this.props.blog.content),
      this.props.searchWords, 20
    ), this.props.searchWords)
    const thumbnail = this.props.blog.photo.thumb.url ? {
      backgroundImage: `url(${this.props.blog.photo.thumb.url})`
    } : {}
    const blogTitle = highlighter.highlight(this.props.blog.title, this.props.searchWords)
    return (
      <li className="blog_cell mini">
        <a href={this.props.blog.show_path}>
          <div className="blogtitle">
            <span>
              <span className="cell_thumb" style={thumbnail}>
              </span>
            </span>
          </div>
          <div className="blogright">
            <h3 className="title" dangerouslySetInnerHTML={{__html: blogTitle}}/>
            <span className="inner" dangerouslySetInnerHTML={{__html: blogText}}/>
          </div>
        </a>
      </li>
    )
  }
}
