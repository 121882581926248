// @flow
import React from "react"
import {Carousel} from 'react-responsive-carousel'
import title from "./images/title.png"
import firstPic from "./images/first.png"
import secondPic from "./images/second.png"
import thirdPic from "./images/third.png"
import fourthPic from "./images/fourth.png"
import fifthPic from "./images/fifth.png"

const slides = [
  {
    alt: 'first',
    src: firstPic
  },
  {
    alt: 'second',
    src: secondPic
  },
  {
    alt: 'third',
    src: thirdPic
  },
  {
    alt: 'fourth',
    src: fourthPic
  },
  {
    alt: 'fifth',
    src: fifthPic,
  },
]

type Props = {
  transite: () => void
}

const GuidanceSlider = ({transite}: Props) => {
  const handleClick: (number) => void = (index) => {
    if (index === slides.length - 1) {
      transite()
    }
  }

  const handleChange: (number) => void = (index) => {
    if (index === 0 && prevIndex === slides.length - 1) {
      transite()
    } else {
      prevIndex = index
    }
  }
  let prevIndex = 0 // ライブラリから前のスライドの位置を取得することができないので自前で管理している

  return (
    <div className="modalGuidanceSliderModal-GuidanceSlider_Container">
      <img className="modalGuidanceSliderModal-GuidanceSlider_Title lazyload" alt={"title"} src={title} />
      <Carousel
        showArrows={true}showThumbs={false} showStatus={false} swipeable={true} infiniteLoop={true}
        onClickItem={handleClick} onChange={handleChange}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={slide.src} alt={slide.alt} className="modalGuidanceSliderModal-GuidanceSlider_Image lazyload" />
          </div>))}
      </Carousel>
    </div>
  )
}

export default GuidanceSlider
