// @flow
import {observable} from 'mobx'
import InputNestedOptionStore from '~/stores/forms/builder/input_nested_option_store'
import InputStore from '~/stores/forms/builder/input_store'
import type {NestedSelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputNestedSingleSelectStore extends InputStore<string, NestedSelectParam> {
  @observable options: InputNestedOptionStore[] = [];
  depth: number;
  clearBtnHelpText: ?string;

  constructor(param: NestedSelectParam) {
    super(param)
  }

  init(param: NestedSelectParam): void {
    super.init(param)

    const param_ = {depth: 0, ...param}
    this.depth = param_.depth
    this.clearBtnHelpText = param_.clearBtnHelpText
    param_.options.forEach(((option) => {
      this.options.push(new InputNestedOptionStore(option, param_))
    }))
  }

  // 値を送信したくない場合は false を返す想定
  active(): boolean {
    const option = this.findOption()
    return option ? !('children' in option) : false // children がある場合はそちらを選択するという想定
  }

  getValueText(): string | typeof undefined {
    const option = this.findOption()
    return typeof option === 'undefined' ? undefined : option.label
  }

  handleChange(): void {
    super.handleChange()
    const option = this.findOption()
    option && option.handleChange()
  }

  findOption(): InputNestedOptionStore | typeof undefined {
    const value = this.getValue(true)
    if (value === null || typeof value === 'undefined') {
      return undefined
    }

    return this.options.find((option) => option.value.toString() === value.toString())
  }

  // 直下のオプションのうち、子に選択されたオプションがあるものを返す
  findOptionRecursively(): InputNestedOptionStore | typeof undefined {
    const value = this.getValue(true)
    if (value === null || typeof value === 'undefined') {
      return undefined
    }

    const findRec = (store: ?InputNestedSingleSelectStore): InputNestedOptionStore | typeof undefined => {
      if (!store || !store.options) { return undefined }
      const found = store.options.find((option) => option.value.toString() === value.toString())
      return found || store.options.find((option) => Boolean(findRec(option.children)))
    }
    return findRec(this)
  }

  clear(): void {
    this.setValue(null)
    this.uncheck()
  }
}
