import React from 'react'
import InputWrapperView from '~/components/forms/input_wrapper.jsx'
import {observer} from 'mobx-react'

@observer
export default class FormBuilder extends React.Component {
  renderInputs() {
    const {store} = this.props
    return store.getInputStores().map((inputStore) => {
      return <InputWrapperView store={inputStore} key={inputStore.name} />
    })
  }

  render() {
    return (
      <div className="form" >
        {this.renderInputs()}
      </div>)
  }
}
