import {sprintf} from 'sprintf-js'
import {captureMessage} from '@sentry/browser'
class UrlManager {

  constructor() {
    this.init()
    this.urlPattern = {
      item: '/%s',
      blog: '/blog/%s',
      shops_list: '/company/%s/shops/',
      store: '/company/%s',
      shops_prefecture: '/company/%s/shops/prefectures/%s/',
      company_assessment_achievements: '/company/%s/assessment_achievements',
    }
  }

  init() {
    if (typeof window !== 'undefined' && typeof window.jis_ !== 'undefined') {
      this.root_uri = window.jis_.root_uri
    }
  }

  // pathを正規化するためのもの。
  // 必要な場合のみpath文字列の最後に"/"をつける。
  normalizePath(path) {
    let ret = path
    if (ret[ret.length - 1] != '/') {
      ret += '/'
    }
    return ret
  }

  makeUrl(path) {
    let root = this.root_uri
    if (!root) {
      captureMessage(`rootがundefinedの状態でmakeUrlが呼ばれました。 path: ${path}`)
    }
    return (root + this.normalizePath(path))
  }

  getItemPath(slug) {
    return this.normalizePath(sprintf(this.urlPattern.item, slug))
  }

  getShopsListUrl(store_id) {
    return this.makeUrl(sprintf(this.urlPattern.shops_list, store_id))
  }

  getShopsListPath(store_id) {
    return this.normalizePath(sprintf(this.urlPattern.shops_list, store_id))
  }

  getShopsPrefecture(store_id, prefecture_id) {
    return this.makeUrl(sprintf(this.urlPattern.shops_prefecture, store_id, prefecture_id))
  }

  getShopsPrefecturePath(store_id, prefecture_id) {
    return this.normalizePath(sprintf(this.urlPattern.shops_prefecture, store_id, prefecture_id))
  }

  getStoreUrl(id) {
    return this.makeUrl(sprintf(this.urlPattern.store, id))
  }

  getStoreAssessmentAchievementsPath(slug) {
    return this.normalizePath(sprintf(this.urlPattern.company_assessment_achievements, slug))
  }

  getBlogUrl(slug) {
    return this.makeUrl(sprintf(this.urlPattern.blog, slug))
  }
}

export default new UrlManager()
