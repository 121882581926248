import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerMagiEmail extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.handleChangeMagiEmail = this.handleChangeMagiEmail.bind(this)
  }

  handleChangeMagiEmail(e) {
    this.props.reviewFormStore.magi_email = e.target.value
  }

  render() {
    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="review_email">magiメールアドレス</label><span className="required">※必須</span>
        </div>
        <input className="reviewpopup-Form_textinput" type="text" id="review_email" name="reviewer_email" placeholder="magiで使用しているメールアドレス" value={this.props.reviewFormStore.magi_email} onChange={this.handleChangeMagiEmail}/>
      </div>
    )
  }
}
