import {action} from "mobx"
import ReviewFormStoreBase from "~/stores/forms/reviews/review_form_store_base"

export default class KobutsuMarketStoreReviewFormStore extends ReviewFormStoreBase {
  init() {
    this.fetchReviewInit()
  }

  getInitApiUrl() {
    return "/api/reviews/kobutsu_market_stores/init"
  }

  getPostApiUrl() {
    return "/api/reviews/kobutsu_market_stores"
  }

  @action.bound
  handlePostSuccess() {
    this.rating = 0
    this.content = ''
  }
}
