// @flow
import React from 'react'
import GuidanceSlider from "./GuidanceSlider"
import GuidanceSliderModalTrigger from "./GuidanceSliderModalTrigger"
import createModalWith from '../common/createModalWith'
import googleAnalyticsManager from '@hikakaku/shared/util/google_analytics_manager'

type Props = {
  estimateFormPath: string
}
const ModalWithTrigger = createModalWith(GuidanceSliderModalTrigger)

const GuidanceSliderModal = ({estimateFormPath}: Props) => (
  <ModalWithTrigger>
    <GuidanceSlider transite={() => {
      googleAnalyticsManager.postGAEvent("カテゴリ一括査定", "click", "一括査定フォーム起動_guide")
      window.location.href = estimateFormPath
    }}/>
  </ModalWithTrigger>
)

export default GuidanceSliderModal
