// @flow
import Util from '@hikakaku/shared/util/util'

if (typeof window !== 'undefined') {
  if (typeof window.jis_ === 'undefined') {
    window.jis_ = {}
  }
  window.jis_.IS_DEBUG = false
  Util.log("----------start debug mode--------------")
}
