class ScrollManager {
  constructor() {
    this.events = []
    this.fire = this.fire.bind(this)
  }

  registerEventListener() {
    window.addEventListener('scroll', this.fire)
    return this
  }

  fire() {
    const currentHeightRate = this.currentHeightRate()
    this.events.forEach(({type, rate, callback}) => {
      if (type === 'over' && currentHeightRate >= rate) {
        callback()
      } else if (type === 'under' && currentHeightRate < rate) {
        callback()
      }
    })
  }

  onOverBorder(borderHeightRate, callback) {
    this.events.push({type: 'over', rate: borderHeightRate, callback})
  }

  onUnderBorder(borderHeightRate, callback) {
    this.events.push({type: 'under', rate: borderHeightRate, callback})
  }

  currentHeightRate() {
    return window.pageYOffset / document.body.clientHeight
  }
}

export default new ScrollManager().registerEventListener()
