// @flow
import * as React from 'react'
import {observer} from 'mobx-react'
import Dropzone from 'react-dropzone'
import DialogStore from '~/stores/dialog_store'
import type {DropzoneStore} from '~/stores/forms/builder/input_dropzone_store'
import type {ResizingDropzoneStore} from '~/stores/forms/builder/input_resizing_dropzone_store'
import type {ImageFile} from '~/flowtyped/stores/forms/form_builder_store_types'

type Props = {
  store: DropzoneStore | ResizingDropzoneStore,
  children?: React.Node
};

/*
 * @brief InputDropzoneView
 * */
@observer
export default class InputDropzoneView extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  get dropzoneClassName(): string {
    const {store} = this.props
    return store.hasDragEnter ? 'is-enter' : 'is-waiting'
  }

  onDrop(acceptedFiles: Array<ImageFile>, rejectedFiles: Array<ImageFile>) {
    const {store} = this.props
    const file: ?ImageFile = acceptedFiles[0]
    if (file === null) {
      this.showRejectedDialog(rejectedFiles)
      return
    }
    store.updateValue(file)
  }

  showRejectedDialog(rejectedFiles: Array<ImageFile>) {
    const {store} = this.props
    if (rejectedFiles.length <= 0) {
      return
    }
    if (store.maxUploadSizeInMB) {
      DialogStore.showDialogWithCloseButton('不正なデータです', `${store.maxUploadSizeInMB}MB以下の画像ファイルのみアップロード可能です`)
    } else {
      DialogStore.showDialogWithCloseButton('不正なデータです')
    }
  }

  onDragEnter(): void {
    const {store} = this.props
    store.handleDragEnter()
  }

  onDragLeave(): void {
    const {store} = this.props
    store.handleDragLeave()
  }

  render() {
    const {store, children} = this.props
    const name  = store.getName()
    const value = store.getValue()
    return (
      <div className="dropzone">
        {!store.usesDefaultField && value && <input type="hidden" name={name} value={value} />}
        {/* multiple を受け入れたいが、簡単のために一旦受け入れない */}
        <Dropzone name={store.usesDefaultField ? name : undefined} maxSize={store.maxUploadSize || undefined} multiple={false} onDrop={this.onDrop.bind(this)} onDragEnter={this.onDragEnter.bind(this)} onDragLeave={this.onDragLeave.bind(this)} className={'dropzone-content ' + this.dropzoneClassName} style={{}} accept={store.accept} disabled={store.disabled}>
          {children}
        </Dropzone>
      </div>
    )
  }
}
