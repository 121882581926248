import $ from 'jquery'
import _ from 'lodash'
import eventManager from '~/util/event_manager'
import DialogStore from '~/stores/dialog_store'

export default class FileUpload {

  initialize(): void {
    this.fileUpload()
  }

  fileUpload(): void {
    $('.change-image-input').on('change', (e) => {
      const index = $('.change-image-input').index($(e.currentTarget))
      // 選択されたファイルがない場合は何もせずにreturn
      const $target = e.currentTarget
      if (!$target.files.length) {
        return
      }
      let fileReader = new FileReader()
      // ファイル情報を取得
      const file = $target.files[0]
      if (file.size > 5000000 || !file.type.match(/^image/)) {
        DialogStore.showDialogWithCloseButton('不正なデータです', '5MB以下の画像ファイルのみアップロード可能です')
        $target.value = ''
        return
      }
      fileReader.readAsDataURL(file)
      // 読み込み成功時に実行
      fileReader.onload = (event) => {
        const fileName = fileReader.result
        // 読み込んだデータを設定
        $('.change-image').eq(index).attr('src', fileName)
      }
    })
  }

}

eventManager.onReady(() => {
  new FileUpload().initialize()
})
