import $ from 'jquery'
import _ from 'lodash'

export default class SmoothScroller {

  get DEFAULT_OPTIONS() {
    return {
      speedPerThumbnail:        6000,  // スクロール速度
      minimumThumbnalsToRotate: 4,     // スクロールさせる最低枚数
      timeDelayInClone:         1000,  // DOMクローンするタイミング（カクカクを防ぐためDOM操作を遅延させる）
    }
  }

  get DEFAULT_SELECTOR() {
    return ".smooth-scroller"
  }

  constructor() {
    this.getThumbnails               = this.getThumbnails.bind(this)
    this.getNthThumbnail             = this.getNthThumbnail.bind(this)
    this.cloneAndAppendThumbnail     = this.cloneAndAppendThumbnail.bind(this)
    this.smoothScroll                = this.smoothScroll.bind(this)
  }

  fetchOptions() {
    return {
      speedPerThumbnail:        this.targetScroller.data('speed'),
      minimumThumbnalsToRotate: this.targetScroller.data('minimum'),
      timeDelayInClone:         this.targetScroller.data('delay'),
    }
  }

  getSelector(selectorClass) {
    return selectorClass || this.DEFAULT_SELECTOR
  }

  getOptions() {
    return {
      ..._.compact(this.fetchOptions()),
      ...this.DEFAULT_OPTIONS,
    }
  }

  getThumbnails() {
    return this.targetScroller.children("li")
  }

  getNthThumbnail(nth) {
    return this.getThumbnails().eq(nth - 1)
  }

  hasEnoughThumbnailsToRotate() {
    return this.getThumbnails().length >= this.options.minimumThumbnalsToRotate
  }

  // 先頭からn番目のDOMをクローンして末尾にアペンドする
  cloneAndAppendThumbnail(nth) {
    const copy = this.getNthThumbnail(nth).clone()
    this.targetScroller.append(copy)
  }

  // カルーセルを動かす
  smoothScroll() {
    this.targetScroller.css(
      'transition',
      `all ${this.options.speedPerThumbnail / 1000.0}s linear`
    )
    this.targetScroller.css(
      'transform',
      `translateX(${-this.thumbnailUnitWidth}px)`
    )
  }

  initializeSettings(selectorClass) {
    this.targetScroller = $(this.getSelector(selectorClass))
    this.options        = this.getOptions()
  }

  onReady(selectorClass = null) {
    this.initializeSettings(selectorClass)
    if (!this.hasEnoughThumbnailsToRotate()) { return }
    this.thumbnailUnitWidth = this.getNthThumbnail(2).outerWidth(true) // 両端はmarginが異なる。中間のサムネールサイズをとる

    this.cloneAndAppendThumbnail(1)                // あらかじめ最初のサムネ２枚を後部に連結
    this.cloneAndAppendThumbnail(2)

    this.targetScroller.on("transitionend", () => {
      this.targetScroller.removeAttr('style')
      this.getNthThumbnail(1).remove()

      setTimeout(this.smoothScroll)               // 非同期で走らせる
      setTimeout(
        () => { this.cloneAndAppendThumbnail(2) }, // （タイミングをずらすため）２枚目をあらかじめコピーしておかないと見切れる
        this.options.timeDelayInClone              // css張り替え時の描画のちらつきを抑えたいので、タイミングをずらしてクローンしている
      )
    })
    this.smoothScroll()
  }
}
