// @flow
import {observable} from 'mobx'
import FormBuilderStore from './form_builder_store'
import googleAnalyticsManager from '@hikakaku/shared/util/google_analytics_manager'
import type {InputParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export type Props = {
  title: string,
  action: string,
  method: string,
  inputStoreProps: InputParam[],
  gaEventParams: string[]
}

export default class FormWithModalPreviewStore {
  title: string
  action: string
  method: string
  formBuilderStore: FormBuilderStore
  gaEventParams: string[]
  @observable open = false
  @observable invalid = false

  constructor(props: Props) {
    this.title = props.title
    this.action = props.action
    this.method = props.method
    this.formBuilderStore = new FormBuilderStore(props.inputStoreProps)
    this.gaEventParams = props.gaEventParams || []
  }

  openModal = (): void => {
    this.invalid = !this.formBuilderStore.validate()
    if (!this.invalid) {
      this.open = true
    }
  }

  closeModal = (): void => {
    this.open = false
  }

  onSubmit = (): void => {
    if (this.gaEventParams.length >= 3) {
      googleAnalyticsManager.postGAEvent(...this.gaEventParams)
    }
  }
}

