import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import {getImageOrientation} from '~/services/get_image_orientation_service'

export default class Result extends React.Component {
  constructor(props) {
    super(props)
  }

  createValueText(value = ''): string {
    value = _.isString(value) ? value : ''
    if (value === '') {
      value = 'なし'
    }
    return value
  }

  renderFiles(files: Array<File>) {
    return files.map((file, index) => {
      let fileOrientClassName = ''
      getImageOrientation(file, (orientation: number) => {
        fileOrientClassName = 'orient-' + orientation
      })
      return (
        <div className="result-file" key={index} >
          <div className={"thumbnail " + fileOrientClassName} style={{backgroundImage: `url(${file.preview})`}}></div>
        </div>)
    })
  }

  renderValue(value) {
    // files
    if (_.isObject(value) && value[0] instanceof File) {
      const result = this.renderFiles(value)
      return result
    }
    return this.createValueText(value)
  }

  renderInput(param) {
    return (
      <div className="input_wrapper" key={param.label}>
        <label className="label">{param.label}</label>
        <div className="value_wrapper">
          {this.renderValue(param.value)}
        </div>
      </div>
    )
  }

  renderInputs() {
    const {confirmParams} = this.props
    return confirmParams.map(((inputParam) => {
      return this.renderInput(inputParam)
    }))
  }

  render() {
    return (
      <div className="confirm_value_wrapper">
        {this.renderInputs()}
      </div>
    )
  }
}
