import $ from 'jquery'
import EventManager from '~/util/event_manager'
import ApiManager from '~/util/api_manager'
import DialogStore from '~/stores/dialog_store'

// http://zipcloud.ibsnet.co.jp/doc/api のAPIを使用して郵便番号を自動入力する
class AutoInputFormAddress {

  postalCodeSearchBtn;
  postalCodeFirst;
  postalCodeSecond;
  prefectureInputForm;
  addressInputForm;

  constructor() {
    this.onClickSearchButton = this.onClickSearchButton.bind(this)
    this.onReady = this.onReady.bind(this)
    this.onSuccessRequest = this.onSuccessRequest.bind(this)
  }

  init() {
    this.postalCodeSearchBtn = $('.postal_code_search_btn')
    this.postalCodeFirst = $('.postal_code_first')
    this.postalCodeSecond = $('.postal_code_second')
    this.prefectureInputForm = $('.prefecture_input_form')
    this.addressInputForm = $('.address_input_form')
  }

  getRequestObject(postal_code) {
    return {
      url: 'https://cms.hikakaku.com/zipcloud/proxy',
      dataType: 'jsonp',
      request_data: {
        zipcode: postal_code,
      },
      response_data: {},
      type: 'GET',
      onSuccess: this.onSuccessRequest,
      onError: function(request, textStatus, errorThrown) {
        // FIXME: HIKAKAKU-3030
      }
    }
  }

  onSuccessRequest(json) {
    if (json.status !== 200 ||
      typeof json.results === "undefined" ||
      json.results === null) {
      DialogStore.showDialogWithCloseButton('該当の住所がありません', '入力内容をご確認ください')
      return
    }
    const prefecture = json.results[0].prefcode
    const address = json.results[0].address2 + json.results[0].address3
    this.prefectureInputForm.val(prefecture)
    this.addressInputForm.val(address)
    return true
  }

  onClickSearchButton(e) {
    e.preventDefault()
    const postalCode = this.postalCodeFirst.val() + this.postalCodeSecond.val()
    const requestObject = this.getRequestObject(postalCode)
    ApiManager.request(requestObject)
    return true
  }

  onReady(e) {
    this.init()
    this.postalCodeSearchBtn.click(this.onClickSearchButton)
  }
}
const autoInputFormAddress = new AutoInputFormAddress()
export default autoInputFormAddress
EventManager.onReady(autoInputFormAddress.onReady)
