// FIXME: flow を導入
import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputStore from '~/stores/forms/builder/input_store'
import scroller from '@hikakaku/shared/jquery-modules/scroller'
import {keyboardShown} from '@hikakaku/shared/util/keyboard_shown'
import {toSnakeKeys} from '@hikakaku/shared/util/convert_case_keys'
import Util from '@hikakaku/shared/util/util'

// const Velocity = document !== undefined ? require('velocity-animate') : null;
const LOAD_DERAY = 400

export default class InputReactSelectAsyncStore extends InputStore {
  get menuHeight() {
    return this._menuHeight
  }
  set menuHeight(menuHeight) {
    this._menuHeight = menuHeight
  }
  @observable reactSelectWrapperElmStyle = {};
  STATE_MENU_CLOSE = 'menu-close';
  STATE_MENU_OPEN = 'menu-open';
  SCROLL_MARGIN_TOP = 0;

  //react selectの状態の変化をフックできるようにstateを保持する
  @observable menu_status = this.STATE_MENU_CLOSE;
  @observable inputValue = '';

  constructor(param) {
    super(param)
    this.onlyFromOptions = param.onlyFromOptions === true // undefined なら false

    this.loadOptions    = this.loadOptions.bind(this)
    this.onOpen         = this.onOpen.bind(this)
    this.onClose        = this.onClose.bind(this)
    this.onInputChange  = this.onInputChange.bind(this)
    this.onFocus        = this.onFocus.bind(this)
    this.filterOption   = this.filterOption.bind(this)

    const inputOptions = param.inputOptions || {}
    const optionDataSource = param.optionDataSource || {}

    // FIXME: inputOptions の url, requestDataOptions は後方互換; deprecated
    this.url                   = (optionDataSource || inputOptions).url
    this.query                 = optionDataSource.query || inputOptions.requestDataOptions

    this.scrollSelector        = inputOptions.scrollContainerSelector
    this.menuHeight            = 0
    this.reactSelectWrapperElm = undefined
    this.latestLoadKey_        = undefined
    this.cache_                = {}

    this.doFetchOptions = _.debounce((keyword, loadKey, callback) => {
      if (this.isRunning(loadKey)) {
        return
      }
      this.fetchOptions(keyword, this.createRequestData(keyword), callback)
    }, LOAD_DERAY)
  }

  // @brief: メニューが表示されたときの縦幅を設定する
  updateMarginBottom() {
    const menuHeight = this.calcMenuHeight()
    if (this.menuHeight === menuHeight) {
      return
    }
    this.menuHeight = menuHeight
    this.setMarginBottom(menuHeight)
  }

  setReactSelectWrapper(elm) {
    this.reactSelectWrapperElm = elm
  }

  scrollInputTop() {
    if (!Util.isMobile()) { return }
    scroller.scrollTo(
      this.reactSelectWrapperElm,
      {
        marginTop: this.SCROLL_MARGIN_TOP
      }
    )
  }

  loadOptions(keyword, callback) {
    if (!keyword) {
      callback()
      return
    }
    if (keyword in this.cache_) {
      callback(this.cache_[keyword])
      return
    }
    const loadKey = new Object() // ユニークキーとして使う
    this.latestLoadKey_ = loadKey
    this.doFetchOptions(keyword, loadKey, callback)
  }

  setValue(value) {
    super.setValue(value)
    this.validate()
  }

  onUpdate() {
    // storeのstate、inputValueが更新されたタイミングをフックしてmenuの高さを再計算する
    this.updateMarginBottom()
  }

  onOpen() {
    this.menu_status = this.STATE_MENU_OPEN
  }

  onClose(nextProps, nextState) {
    this.menu_status = this.STATE_MENU_CLOSE
  }

  onInputChange(inputValue, {action}) {
    this.inputValue = inputValue
    if (action === 'input-change') {
      this.setValue(inputValue)
    }
  }

  onFocus(e) {
    this.uncheck()
    this.inputValue = ''
    keyboardShown().then(() => this.scrollInputTop()).catch((error) => {
      if (error instanceof Error) {
        console.error(error)
      }
    })
  }

  onResetValue() {
    this.values = ''
    this.inputValue = ''
  }

  // defaultのfilterを吹っ飛ばす
  filterOption(option, filterString) {
    return filterString
  }

  calcMenuHeight() {
    const selectBox = document.querySelector('.Select-menu-outer')
    if (selectBox === null) {
      return 0
    }
    return selectBox.clientHeight
  }

  fetchOptions(keyword, requestData, callback) {
    $.ajax({
      type: 'GET',
      url: this.url,
      data: requestData,
      dataType: 'json',
      success: (data) => {
        const options = this.prepareOptions(data, keyword)
        this.setResponseCache(options, keyword)
        callback(options)
        this.updateMarginBottom()
      },
      error: () => {
        const options = this.findOptionForShorterKeywordFromCache(keyword) || []
        options.unshift({value: keyword, label: keyword})
        callback(options)
        this.updateMarginBottom()
      }
    })
  }

  prepareOptions(data, keyword) {
    const options = this.formattedResponseToJs(data)
    if (!this.onlyFromOptions) {
      // 検索結果に意図した商品が表示されないことがあるので、keyword自身を先頭に追加する
      options.unshift({value: keyword, label: keyword})
    }
    return options
  }

  createRequestData(searchWord) {
    return Object.assign({search_words: searchWord}, toSnakeKeys(this.query))
  }

  setResponseCache(options, keyword) {
    this.cache_[keyword] = options
  }

  isRunning(loadKey) {
    return loadKey !== this.latestLoadKey_
  }

  formattedResponseToJs(response) {
    return response.map((item) => {
      return {value: item.name, label: item.name}
    })
  }

  setMarginBottom(height) {
    this.reactSelectWrapperElmStyle = {marginBottom: height.toString() + 'px'}
  }

  findOptionForShorterKeywordFromCache(keyword) {
    if (keyword.length === 0) { return undefined }

    if (keyword in this.cache_) {
      return this.cache_[keyword].slice()
    } else {
      return this.findOptionForShorterKeywordFromCache(keyword.slice(0, keyword.length - 1))
    }
  }
}
