import React from 'react'
import {observer} from 'mobx-react'
import PropTypes from 'prop-types'
import ReviewFormInnerNickname from '~/components/forms/review_form/review_form_inner_nickname'
import ReviewFormInnerContent from '~/components/forms/review_form/review_form_inner_content'
import ReviewFormInnerEmail from '~/components/forms/review_form/review_form_inner_email'
import ReviewFormInnerMapInput from '~/components/forms/review_form/review_form_inner_map_input'
import ReviewFormInnerServiceInput from '~/components/forms/review_form/review_form_inner_service_input'
import ReviewFormInnerRateStar from "~/components/forms/review_form/review_form_inner_rate_star"
import ReviewFormInnerUpdateNickname from "~/components/forms/review_form/review_form_inner_update_nickname"

@observer
export default class ReviewFormInner extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  get defaultValueOfSelector() {
    return this.props.reviewFormStore.selectedServiceId
  }
  get selectorWidth() {
    return 210
  }

  constructor(props) {
    super(props)
    this.state = {
      services: props.reviewFormStore.services || "undefined",
      user_info: props.reviewFormStore.user_info
    }
    this.onClickSubmit = this.handleClickSubmit.bind(this)
  }

  handleClickSubmit() {
    this.props.reviewFormStore.postReview(this.createPostParams())
  }

  createPostParams() {
    const reviewData = {
      review: {
        reviewer_name: this.props.reviewFormStore.nickname,
        reviewer_email: this.props.reviewFormStore.email,
        content: this.props.reviewFormStore.content,
        rating: this.props.reviewFormStore.rating,
        service_id: this.props.reviewFormStore.selectedServiceId,
        user_input_enterprise_name: this.props.reviewFormStore.userInputEnterpriseName,
        update_nickname: this.props.reviewFormStore.updatesNickname,
        user_input_map_name: this.props.reviewFormStore.userInputMapName
      }
    }
    return reviewData
  }

  render() {
    return (
      <div className="reviewpopup-Inner">
        <form className="hikakaku_form">
          <div className="reviewpopup-Form">
            <div className="reviewpopup-Form_content info">
              <ReviewFormInnerRateStar reviewFormStore={this.props.reviewFormStore} />
            </div>
            <ReviewFormInnerServiceInput reviewFormStore={this.props.reviewFormStore} />
            <ReviewFormInnerContent reviewFormStore={this.props.reviewFormStore} />
            <ReviewFormInnerNickname reviewFormStore={this.props.reviewFormStore} />
            {
              this.props.reviewFormStore.requireEmailInput &&
              <ReviewFormInnerEmail reviewFormStore={this.props.reviewFormStore}/>
            }
            <ReviewFormInnerMapInput reviewFormStore={this.props.reviewFormStore}/>
            <div colSpan="2" className="reviewpopup-CheckConfirm">
              {
                this.props.reviewFormStore.asksIfUpdatesNickname &&
                  <ReviewFormInnerUpdateNickname reviewFormStore={this.props.reviewFormStore}/>
              }
              <div colSpan="2"><input className="reviewpopup-SubmitButton" type="button" value="クチコミを投稿する" onClick={this.onClickSubmit} /></div>
              <div className="reviewpopup-CheckConfirm_attention">
                投稿と同時に<a href="/review-term/" target="_blank" rel="noopener noreferrer">クチコミ利用規約</a>に同意したとみなされます
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
