import React from 'react'
import {observer} from 'mobx-react'
import PrefectureSelectBtn from './prefecture_select_btn'

@observer
class PrefectureSelector extends React.Component {

  renderCell(param) {
    return (
      <td key={param.id} className="prefecture-selector__td">
        <PrefectureSelectBtn param={param} store={this.props.store}/>
      </td>
    )
  }

  renderTitleCell(name) {
    return (
      <th key={`${name}-th`} className="prefecture-selector__th">
        {name}
      </th>
    )
  }

  renderList(name, params) {
    let title = this.renderTitleCell(name)
    let cells = params.map((param) => this.renderCell(param))
    return (
      <tr className="prefecture-selector__tr" key={`${name}-tr`}>
        {title}
        {cells}
      </tr>
    )
  }

  renderTable() {
    let prefs = this.props.store.prefectures
    let keys = Object.keys(prefs)
    let list = keys.map((key) => {
      let param = prefs[key]
      if (this.props.store.isOpenAccordion === false) {
        let result = this.props.store.checkIncludePrefecture(param)
        if (result === false) {
          return null
        }
      }
      return this.renderList(key, param)
    })
    return (
      <table className="prefecture-selector__table">
        <tbody>
          {list}
        </tbody>
      </table>
    )
  }

  renderHeader() {
    if (this.props.store.isOpenAccordion === false && this.props.store.isPrefectureSelected()) {
      return null
    }
    return (
      <div className="prefecture-selector__header">
        <div className="prefecture-selector__header__selectall">
          <PrefectureSelectBtn store={this.props.store} param={{name: "全国", id: undefined}} />
        </div>
        <p className="prefecture-selector__header__description">
          地域をクリックして絞り込み
        </p>
      </div>
    )
  }
  render() {
    const {isOpenAccordion} = this.props.store
    const table = this.renderTable()
    const toggleBtnText = isOpenAccordion ? "閉じる" : "他の地域を表示"
    const header = this.renderHeader()
    return (
      <div className="prefecture-selector" >
        <div className={"prefecture-selector__accordion__wrapper" + (isOpenAccordion ? "" : " close")}>
          {header}
          {table}
        </div>
        <div className="prefecture-selector__under">
          <a className={"prefecture-selector__togglebtn" + (isOpenAccordion ? "" : " close")} onClick={this.props.store.onClickToggleBtn}>
            {toggleBtnText}
          </a>
        </div>
      </div>
    )
  }
}
export default PrefectureSelector
