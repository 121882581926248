import {action, observable} from "mobx"
import Validator from '~/validator/validator'
import DialogStore from "~/stores/dialog_store"
import ReviewFormStoreBase from "~/stores/forms/reviews/review_form_store_base"

export default class MagiReviewFormStore extends ReviewFormStoreBase {
  @observable magi_nickname = '';
  init() {
    this.fetchReviewInit()
  }

  getInitApiUrl() {
    return "/api/reviews/stores/init"
  }

  getPostApiUrl() {
    return "/api/reviews/stores"
  }

  postReview(params) {
    if (this.validation(params) === false) {
      return
    }
    this.fetchPostReview(params)
  }

  validation(params) {
    let errorMessage = ''

    //名前が入力されている
    if (Validator.isEntered(params.review.reviewer_name) === false) {
      errorMessage += 'magiニックネームを入力してください<br />'
    }

    //emailの形式
    if (Validator.isEntered(params.review.reviewer_email) === false) {
      errorMessage += 'magiメールアドレスを入力してください<br />'
    } else if (Validator.validateEmail(params.review.reviewer_email) === false) {
      errorMessage += 'magiメールアドレスの形式が不正です<br />'
    }

    //テキストが10文字以上入力されている
    if (Validator.isEntered(params.review.content) === false ||
      params.review.content.length < 10) {
      errorMessage += 'レビュー内容は10文字以上入力してください<br />'
    }

    //レビューの星が入力されていて、星の数が0より大きい
    if (params.review.rating <= 0 ||
      Validator.isEntered(params.review.rating) === false) {
      errorMessage += '星の数を入力してください<br />'
    }

    //業者名が入力されている
    if (Validator.isEntered(params.review.user_input_enterprise_name) === false) {
      errorMessage += '業者を入力してください<br />'
    }

    if (Validator.isEntered(errorMessage) === true) {
      DialogStore.showDialog({
        title: "入力に不備があります",
        description: errorMessage,
        btnList: [
          {
            title: "閉じる",
            callback: function() {
              DialogStore.hideDialog()
            },
            customClass: "btn-blue"
          },
        ],
        show_time: -1
      })

      return false
    }
    return true
  }

  @action.bound
  handlePostSuccess() {
    this.rating = 0
    this.content = ''
    this.userInputEnterpriseName = ''
  }
}
