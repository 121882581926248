import $ from 'jquery'

class EventManager {

  constructor() {
    this.fired = false
    this.readyEvents = []
    this.fireReady = this.fireReady.bind(this)

    this.init()
  }

  init() {
    this.registReadyEvent()
  }

  registReadyEvent() {
    if (typeof document === "undefined") {
      return
    }
    $(document).ready(this.fireReady)
  }

  onReady(e) {
    if (this.fired) {
      e()
    } else {
      this.readyEvents.push(e)
    }
  }

  fireReady() {
    this.fired = true
    this.readyEvents.forEach((e) => {
      e()
    })
  }
}

export default new EventManager()
