import $ from 'jquery'
import EventManager from '~/util/event_manager'
import UrlHashManager from '~/util/url_hash_manager'
import InView from 'in-view'
import Scroller from '@hikakaku/shared/jquery-modules/scroller'

class AutoFocus {

  constructor() {
    this.execute = this.execute.bind(this)
  }

  getElement() {
    const targetSelector = '#' + UrlHashManager.getValue('focus')
    return $(targetSelector)
  }

  focus(element) {
    element.focus()
    if (!InView.is(element[0])) {
      // 端末によってはfocusが効いていない場合があるため、現在対象のelementが画面上に表示されていない場合はスクロールを行う。
      Scroller.scrollTo(element)
    }
  }

  execute() {
    const element = this.getElement()
    if (element.length === 0) {
      return
    }
    this.focus(element)
    return true
  }
}

const autoFocus = new AutoFocus()
export default autoFocus
EventManager.onReady(autoFocus.execute)
