import $ from 'jquery'
import 'slick-carousel'

export default class CarouselBase {

  get SELECTOR() {
    return ""
  }


  get DEFAULT_OPTIONS() {
    return {
      autoplay: true,
      dots: true, //下のドットは表示させるか
      arrows: false, //左右のナビゲーションは表示させるか
      easing: 'ease'
    }
  }

  constractor() {
  }

  getSelector() {
    return this.SELECTOR
  }
  getOptions() {
    return this.DEFAULT_OPTIONS
  }

  onReady() {
    this.el = $(this.getSelector())
    if (this.el.length <= 0) {
      return
    }
    this.el.on('init', (function(slick) {
      this.el.find('.slick-slide').css('display', 'block')
    }).bind(this))
    this.el.slick(this.getOptions())
  }

  createOptions(options) {
    if (typeof options !== 'object') {
      options = {}
    }
    return $.extend(this.DEFAULT_OPTIONS, options)
  }

}
