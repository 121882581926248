import React from 'react'
import ReactDOM from 'react-dom'
import ItemList from '~/components/list/item_list/item_list'

class ItemSearchResult extends React.Component {
  constructor(props) {
    super(props)

    let items = this.props.items.concat([])

    this.state = {
      currentPage: 1,
      items: items,
    }

    this.onClickMoreBtn = this.onClickMoreBtn.bind(this)
  }

  onClickMoreBtn() {
    this.setState({isRequesting: true})

    let data = {
      search_words: this.props.search_words,
      category: this.props.category,
      page: (this.state.currentPage + 1),
    }

    let requestData = {
      url: window.jis_.c_root_uri + "/api/elasticsearch/items/search",
      dataType: "json",
      type: 'GET',
      request_data: data,
      onSuccess: (function(json) {

        this.setState({
          currentPage: this.state.currentPage + 1,
          isRequesting: false,
          items: this.state.items.concat(json),
        })
      }).bind(this),
      onError: function(request, textStatus, errorThrown) {
        // FIXME: HIKAKAKU-3030
        window.alert("API ERROR")
      }
    }

    window.JiApiManager.request(requestData)
  }

  currentPageNum() {
    let totalCount = this.props.total_count
    let currentShowCount = this.state.currentPage * this.props.per_page

    if (totalCount <= currentShowCount) {
      currentShowCount = totalCount
    }

    return currentShowCount + "/" + totalCount
  }

  getMoreBtn() {
    let totalCount = this.props.total_count
    let currentShowCount = this.state.currentPage * this.props.per_page
    if (totalCount <= currentShowCount) {
      return (
        <span></span>
      )
    } else if (this.state.isRequesting === true) {
      return (
        <div>
          <span className="page_loader loading" style={{display: "block"}}>
            <span className="load_icon_fast"/>
            <span className="load_icon"/>
            <span className="load_icon_slow"/>
          </span>
        </div>
      )
    }

    return (
      <a onClick={this.onClickMoreBtn}>
        <span className="btn-low">ここから続きを表示</span>
      </a>
    )
  }

  render() {
    return (
      <div>
        <ItemList items={this.state.items} />
        <div className="alignright">
          <span className="page_number fnt-size-10">
            {this.currentPageNum()}件表示中
          </span>
        </div>
        <div id="next">
          {this.getMoreBtn()}
        </div>
      </div>
    )
  }

}
export default ItemSearchResult
