// @flow
import RegisterUserStoreBase from '~/stores/register_user_store.jsx'
import {observable} from 'mobx'
import DialogStore from '~/stores/dialog_store'

export default class ReviewsRegisterUserStore extends RegisterUserStoreBase {

  showDialog(title: string, description: string): void {
    DialogStore.showDialog({
      title,
      description,
      btnList: [
        {
          title: 'OK',
          customClass: 'btn-orange',
          callback: () => {
            $.ji_popup.remove_close_callback()
            window.hika_sp_popup_close()
            DialogStore.hideDialog()
          },
        },
      ],
      show_time: -1,
    })
  }

}
