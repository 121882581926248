"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDeviceType = exports.DeviceType = void 0;
var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["TABLET_ANDROID"] = 0] = "TABLET_ANDROID";
    DeviceType[DeviceType["TABLET_IOS"] = 1] = "TABLET_IOS";
    DeviceType[DeviceType["TABLET"] = 2] = "TABLET";
    DeviceType[DeviceType["MOBILE_ANDROID"] = 3] = "MOBILE_ANDROID";
    DeviceType[DeviceType["MOBILE_IOS"] = 4] = "MOBILE_IOS";
    DeviceType[DeviceType["MOBILE"] = 5] = "MOBILE";
    DeviceType[DeviceType["PC"] = 6] = "PC";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
var getDeviceType = function () {
    var u = window.navigator.userAgent.toLowerCase();
    if ((u.includes("android") && !u.includes("mobile")) ||
        (u.includes("firefox") && u.includes("tablet"))) {
        return DeviceType.TABLET_ANDROID;
    }
    if (u.includes("ipad")) {
        return DeviceType.TABLET_IOS;
    }
    if ((u.includes("windows") && u.includes("touch")) ||
        ["kindle", "silk", "playbook"].some(function (device) { return u.includes(device); })) {
        return DeviceType.TABLET;
    }
    if ((u.includes("android") && u.includes("mobile")) ||
        (u.includes("firefox") && u.includes("mobile"))) {
        return DeviceType.MOBILE_ANDROID;
    }
    if (u.includes("iphone")) {
        return DeviceType.MOBILE_IOS;
    }
    if ((u.includes("windows") && u.includes("phone")) ||
        u.includes("blackberry")) {
        return DeviceType.MOBILE;
    }
    return DeviceType.PC;
};
exports.getDeviceType = getDeviceType;
