import React from 'react'
import PropTypes from 'prop-types'
import FixedEnterpriseReviewFormInner from '~/components/forms/review_form/fixed_enterprise_review_form_inner'
import StoreReviewFormStore from '~/stores/forms/reviews/store_review_form_store'

export default class FixedStoreReviewForm extends React.Component {
  static propTypes = {
    service_id: PropTypes.number,
    estimate_request_id: PropTypes.number,
    shops: PropTypes.arrayOf(PropTypes.object),
    is_history_backable: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.reviewFormStore = new StoreReviewFormStore()
  }

  render() {
    this.reviewFormStore.setSelectedServiceId(this.props.service_id)
    this.reviewFormStore.setSelectedEstimateRequestId(this.props.estimate_request_id)
    this.reviewFormStore.setIsHistoryBackable(this.props.is_history_backable)
    return <FixedEnterpriseReviewFormInner reviewFormStore={this.reviewFormStore} serviceReadonlyTitle="買取業者名" shops={this.props.shops}/>
  }
}
