import EventManager from '~/util/event_manager'
import axios from 'axios'
import '~/config/axios'
import DialogStore from '~/stores/dialog_store'

window.axios = axios

export default class BestAnswerBtn {

  constructor() {
    this.onClickShowDialog = this.onClickShowDialog.bind(this)
  }

  onClickShowDialog(e) {
    e.preventDefault()
    const questionId = $(e.currentTarget).data('question-id')
    const answerId = $(e.currentTarget).data('answer-id')
    DialogStore.showDialog({
      title: 'この回答を「ベストアンサー」に選びます。',
      description: 'よろしいですか？',
      btnList: [
        {
          title: '閉じる',
          customClass: 'btn-gray',
          callback() {
            DialogStore.hideDialog()
          },
        },
        {
          title: '続ける',
          customClass: 'btn-orange',
          callback: () => {
            this.onClickUpdateBestAnswer(questionId, answerId)
          },
        }
      ],
      show_time: -1,
    })
  }

  onClickUpdateBestAnswer(id, best_answer) {
    axios.put(`/api/questions/questions/${id}`, {best_answer}).then(() => {
      window.location.reload()
    }).catch(() => {
      DialogStore.showDialog({
        title: '回答へのベストアンサーに失敗しました',
        description: "ネットワークなどをご確認の上、再度お試しください",
        btnList: [
          {
            title: '閉じる',
            customClass: 'btn-gray',
            callback() {
              DialogStore.hideDialog()
            },
          },
        ],
        show_time: -1,
      })
    })
  }

  onReady() {
    $(document).on(
      'click',
      '.js-best-answer-btn',
      this.onClickShowDialog
    )
  }
}

EventManager.onReady(() => new BestAnswerBtn().onReady())
