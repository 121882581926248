import React from 'react'
import ReactDOM from 'react-dom'

export default class PopupLoading extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div style={ {height: '500px'} }>
        <span className="popup_loading">
          <span className="loading">
            <span className="load_icon_fast"></span>
            <span className="load_icon"></span>
            <span className="load_icon_slow"></span>
          </span>
        </span>
      </div>
    )
  }
}
