// @flow
import React from 'react'

interface Props {
  thumbnail: string;
  orientation: ?number;
  withDeleteButton: boolean;
  resetValue: () => void;
}

export default function PhotoBigView(props: Props) {
  const onClickDeleteButton: (event: Event) => void = (event) => {
    event.stopPropagation()
    props.resetValue()
  }
  const thumbnailClassName = typeof props.orientation === 'number' ? `thumbnail orient-${props.orientation}` : 'thumbnail'

  return (
    <React.Fragment>
      <div className="loading-wrapper">
        <span className="loading mini">
          <span className="load_icon_fast"></span>
          <span className="load_icon"></span>
          <span className="load_icon_slow"></span>
        </span>
      </div>
      <div className={thumbnailClassName} style={{backgroundImage: `url(${props.thumbnail})`}}></div>
      {props.withDeleteButton && <button type="button" className="delete-button" onClick={onClickDeleteButton}>×</button>}
    </React.Fragment>
  )
}
