import $ from 'jquery'
import eventManager from '~/util/event_manager'

eventManager.onReady(() => {
  const slideSpeed = 500

  $('.js-accordion-container:not([data-accordion-default-open="true"])').hide()

  $('.js-accordion-headline').click(function(e) {
    $(e.currentTarget).find('.js-accordion-icon').toggleClass('is-accordion-icon-open')
    $(e.currentTarget).next('.js-accordion-container').slideToggle(slideSpeed)
  })

  $('.js-accordion-arrow-down-up-headline').click(function(e) {
    $(e.currentTarget).find('.js-accordion-down-up-icon').toggleClass('is-accordion-down-up-icon-open')
    $(e.currentTarget).next('.js-accordion-container').slideToggle(slideSpeed)
  })
})
