// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'
import type InputOptionStore from '~/stores/forms/builder/input_option_store'

type Props = {
  name?: string,
  store: InputOptionStore
}

/*
 * @brief チェックボックスセル
 * */
@observer
export default class InputCheckboxCellView extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  onChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const {store} = this.props
    store.enableCheck(e.target.checked)
  }

  onBlur = (): void => {
    const {store} = this.props
    store.handleBlur()
  }

  renderDescription() {
    const {store} = this.props
    return <span className="option_description">: {store.description}</span>
  }

  render() {
    const {store, name} = this.props
    return (
      <label className="option_wrapper checkbox_wrapper">
        <span className="right_wrapper">
          <input type="checkbox" onChange={this.onChange} name={name} value={store.value} onBlur={this.onBlur} checked={store.checked} />
        </span>
        <span className="option_text">{store.label}</span>
        {store.description !== '' && this.renderDescription()}
      </label>
    )
  }
}
