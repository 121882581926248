// @flow
import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import StoreBase from '~/stores/store_base'
import type {OptionParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputOptionStore extends StoreBase {
  @observable value = '';
  @observable title = '';
  @observable description = '';

  constructor(param: OptionParam) {
    super()
    this.init(param)
  }

  init(param: OptionParam) {
    this.value = param.value
    this.label = param.label
    this.description = param.description || ''
  }
}
