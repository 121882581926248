// @flow
import EXIF from 'exif-js'

type ExifFile = File & {exifdata: {Orientation: number}}
export function getImageOrientation(file: ExifFile, callback: Function) {
  EXIF.getData(file, () => {
    let orientation: ?number = file.exifdata.Orientation
    if (orientation === undefined) {
      orientation = 1
    }
    callback(orientation)
  })
}
