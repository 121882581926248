// FIXME: flow に対応したいが、 InputStore<V, P> の V について string と string[] が共存できなくてもう無理だよおっかさん

import InputStore from '~/stores/forms/builder/input_store'
import InputSingleSelectStore from '~/stores/forms/builder/input_single_select_store'
import InputNestedSingleSelectStore from '~/stores/forms/builder/input_nested_single_select_store'
import InputReactSelectAsyncStore from '~/stores/forms/builder/input_react_select_async_store'
import InputMultiSelectStore from '~/stores/forms/builder/input_multi_select_store'
import InputPhotosStore from '~/stores/forms/builder/input_photos_store'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'

class InputStoreFactory {

  constructor() {
  }

  createInputStore(param) {
    switch (param.type) {
    case formBuilderTypes.FORM_INPUT_TYPE_CHECKBOX:
      return new InputMultiSelectStore(param)
    case formBuilderTypes.FORM_INPUT_TYPE_SELECT:
    case formBuilderTypes.FORM_INPUT_TYPE_RADIO:
      return new InputSingleSelectStore(param)
    case formBuilderTypes.FORM_INPUT_TYPE_NESTED_SELECT:
      return new InputNestedSingleSelectStore(param)
    case formBuilderTypes.FORM_INPUT_TYPE_REACT_SELECT:
    case formBuilderTypes.FORM_INPUT_TYPE_TEXT_SUGGESTION:
      return new InputReactSelectAsyncStore(param)
    case formBuilderTypes.FORM_INPUT_TYPE_PHOTOS:
      return new InputPhotosStore(param)
    default:
      return new InputStore(param)
    }
  }
}
export default new InputStoreFactory()
