/*legacy codes*/
!(function() {

  if (typeof document === 'undefined') {
    return
  }

  /*価格を比較ボタンを押した時のアクション*/
  !(function($) {
    $(document).ready(function() {
      let btn_obj,
        scroll_target,
        scroll_top,
        body,
        scroll_margin = 100

      body = $("html, body")
      btn_obj = $(".btn_compare_price")
      scroll_target = $(".ranking_top")
      if (typeof btn_obj === "undefined") {
        return
      }
      if (typeof scroll_target === "undefined") {
        return
      }
      let offset = scroll_target.offset()
      if (typeof offset === "undefined") {
        return
      }
      scroll_top = offset.top - scroll_margin//上のバーの分マージンを引く
      btn_obj.click(function(e) {
        e.preventDefault()
        body.animate({scrollTop: scroll_top}, '1000', 'swing', function() {})
      })
    })
  }(jQuery))

  /*画像をリサイズする*/
  !(function($) {
    if (ju_kaitori_.isMobile() === true) {
      return
    }
    let img_root = $(".item_risize_target")
    let img_obj = $(".item_risize_target img")
    if (1 <= img_obj.width()) {
      ju_kaitori_.fitImageSize(img_obj, 150)
      img_root.stop().animate({opacity: 1,}, 400)
    }
    $('.item_risize_target img').bind("load", function() {
      ju_kaitori_.fitImageSize(img_obj, 150)
      img_root.stop().animate({opacity: 1,}, 400)
    })
  }(jQuery))
}())
