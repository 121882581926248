"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildEstimateStatInputProps = exports.buildUserFormInputProps = exports.buildPropertyFormProps = exports.companyInput = exports.userTownInput = exports.userCityInput = exports.propertyStreetInput = exports.townIdInput = exports.cityIdInput = void 0;
var input_store_factory_1 = require("@hikakaku/shared/stores/estimate_form/common/input/input_store_factory");
var cityIdInput = function (options) {
    if (options === void 0) { options = []; }
    return ({
        type: input_store_factory_1.InputType.SELECT,
        name: 'city_id',
        necessary: true,
        label: '市区町村を選択',
        options: options,
    });
};
exports.cityIdInput = cityIdInput;
var townIdInput = function (options) {
    if (options === void 0) { options = []; }
    return ({
        type: input_store_factory_1.InputType.SELECT,
        name: 'town_id',
        necessary: true,
        label: '町名を選択',
        options: options,
    });
};
exports.townIdInput = townIdInput;
var propertyStreetInput = function (options) {
    if (options === void 0) { options = []; }
    return ({
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'property_street',
        name: 'ieul_estimate_entry[property_street]',
        necessary: true,
        label: '物件の字/丁目',
        options: options,
    });
};
exports.propertyStreetInput = propertyStreetInput;
var userCityInput = function (options) {
    if (options === void 0) { options = []; }
    return ({
        type: input_store_factory_1.InputType.SELECT,
        name: 'user_city',
        necessary: true,
        label: '市区町村',
        options: options,
    });
};
exports.userCityInput = userCityInput;
var userTownInput = function (options) {
    if (options === void 0) { options = []; }
    return ({
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_town',
        name: 'ieul_estimate_entry[user_town]',
        necessary: true,
        label: 'ご住所の町名',
        options: options,
    });
};
exports.userTownInput = userTownInput;
var companyInput = function (options) {
    if (options === void 0) { options = []; }
    return ({
        type: input_store_factory_1.InputType.CHECKBOX,
        rawName: 'company',
        name: 'ieul_estimate_entry[company][]',
        necessary: true,
        label: '査定会社',
        options: options,
    });
};
exports.companyInput = companyInput;
var buildPropertyFormProps = function (props) { return [
    {
        type: input_store_factory_1.InputType.SELECT,
        name: 'pref_id',
        necessary: true,
        label: '都道府県を選択',
        options: props.propertyForm.prefIdOptions,
    },
    (0, exports.cityIdInput)(),
    (0, exports.townIdInput)(),
    (0, exports.propertyStreetInput)(),
    {
        type: input_store_factory_1.InputType.TEXT,
        rawName: 'property_address',
        name: 'ieul_estimate_entry[property_address]',
        necessary: true,
        label: '物件の番地',
    },
    props.propertyForm.propertyTypeItemNeeds.propertyCondominium &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_condominium',
            name: 'ieul_estimate_entry[property_condominium]',
            necessary: true,
            label: 'マンション名',
        },
    props.propertyForm.propertyTypeItemNeeds.propertyRoom &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_room',
            name: 'ieul_estimate_entry[property_room]',
            necessary: true,
            label: '部屋番号',
        },
    props.propertyForm.propertyTypeItemNeeds.propertyBuilding &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_building',
            name: 'ieul_estimate_entry[property_building]',
            necessary: true,
            label: '建物名',
        },
    {
        type: input_store_factory_1.InputType.RADIO,
        rawName: 'resident',
        name: 'ieul_estimate_entry[resident]',
        necessary: true,
        label: '物件に現在お客様が住んでいるかどうか',
        options: [{ label: 'はい', value: 1 }, { label: 'いいえ', value: 2 }],
    },
    props.propertyForm.propertyTypeItemNeeds.propertyExclusiveArea &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_exclusive_area',
            name: 'ieul_estimate_entry[property_exclusive_area]',
            necessary: true,
            label: '専有面積',
        },
    props.propertyForm.propertyTypeItemNeeds.propertyExclusiveArea &&
        {
            type: input_store_factory_1.InputType.RADIO,
            rawName: 'property_exclusive_area_unit',
            name: 'ieul_estimate_entry[property_exclusive_area_unit]',
            necessary: true,
            label: '専有面積の単位',
            options: [{ label: 'm2', value: 1 }, { label: '坪', value: 2 }],
        },
    props.propertyForm.propertyTypeItemNeeds.propertyLandArea &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_land_area',
            name: 'ieul_estimate_entry[property_land_area]',
            necessary: true,
            label: '土地面積',
        },
    props.propertyForm.propertyTypeItemNeeds.propertyLandArea &&
        {
            type: input_store_factory_1.InputType.RADIO,
            rawName: 'property_land_area_unit',
            name: 'ieul_estimate_entry[property_land_area_unit]',
            necessary: true,
            label: '土地面積の単位',
            options: [{ label: 'm2', value: 1 }, { label: '坪', value: 2 }],
        },
    props.propertyForm.propertyTypeItemNeeds.propertyBuildingArea &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_building_area',
            name: 'ieul_estimate_entry[property_building_area]',
            necessary: true,
            label: '建物面積',
        },
    props.propertyForm.propertyTypeItemNeeds.propertyBuildingArea &&
        {
            type: input_store_factory_1.InputType.RADIO,
            rawName: 'property_building_area_unit',
            name: 'ieul_estimate_entry[property_building_area_unit]',
            necessary: true,
            label: '建物面積の単位',
            options: [{ label: 'm2', value: 1 }, { label: '坪', value: 2 }],
        },
    props.propertyForm.propertyTypeItemNeeds.propertyFloorArea &&
        {
            type: input_store_factory_1.InputType.TEXT,
            rawName: 'property_floor_area',
            name: 'ieul_estimate_entry[property_floor_area]',
            necessary: true,
            label: '延べ床面積',
        },
    props.propertyForm.propertyTypeItemNeeds.propertyFloorArea &&
        {
            type: input_store_factory_1.InputType.RADIO,
            rawName: 'property_floor_area_unit',
            name: 'ieul_estimate_entry[property_floor_area_unit]',
            necessary: true,
            label: '延べ床面積の単位',
            options: [{ label: 'm2', value: 1 }, { label: '坪', value: 2 }],
        },
    props.propertyForm.propertyTypeItemNeeds.propertyRoomPlan &&
        {
            type: input_store_factory_1.InputType.SELECT,
            rawName: 'property_room_plan',
            name: 'ieul_estimate_entry[property_room_plan]',
            necessary: true,
            label: '間取り',
            options: props.propertyForm.propertyRoomPlanOptions,
        },
    props.propertyForm.propertyTypeItemNeeds.propertyConstructedYear &&
        {
            type: input_store_factory_1.InputType.SELECT,
            rawName: 'property_constructed_year',
            name: 'ieul_estimate_entry[property_constructed_year]',
            necessary: true,
            label: '築年数',
            options: props.propertyForm.propertyConstructedYearOptions,
        },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'property_status',
        name: 'ieul_estimate_entry[property_status]',
        necessary: true,
        label: '物件の状況',
        options: props.propertyForm.propertyStatusOptions,
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'property_user_relation',
        name: 'ieul_estimate_entry[property_user_relation]',
        necessary: true,
        label: '物件との関係',
        options: props.propertyForm.propertyUserRelationOptions,
    }
].filter(Boolean); };
exports.buildPropertyFormProps = buildPropertyFormProps;
var buildUserFormInputProps = function (props) { return [
    {
        type: input_store_factory_1.InputType.TEXT,
        rawName: 'user_name_last',
        name: 'ieul_estimate_entry[user_name_last]',
        necessary: true,
        label: 'お名前（姓）',
    },
    {
        type: input_store_factory_1.InputType.TEXT,
        rawName: 'user_name_first',
        name: 'ieul_estimate_entry[user_name_first]',
        necessary: true,
        label: 'お名前（名）',
    },
    {
        type: input_store_factory_1.InputType.TEXT,
        rawName: 'user_name_kana_last',
        name: 'ieul_estimate_entry[user_name_kana_last]',
        necessary: true,
        label: 'ふりがな（姓）',
    },
    {
        type: input_store_factory_1.InputType.TEXT,
        rawName: 'user_name_kana_first',
        name: 'ieul_estimate_entry[user_name_kana_first]',
        necessary: true,
        label: 'ふりがな（名）',
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_age',
        name: 'ieul_estimate_entry[user_age]',
        necessary: true,
        label: '年齢',
        options: props.userForm.userAgeOptions,
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        name: 'user_pref',
        necessary: true,
        label: '都道府県',
        options: props.userForm.userPrefOptions,
    },
    (0, exports.userCityInput)(),
    (0, exports.userTownInput)(),
    {
        type: input_store_factory_1.InputType.TEXT,
        rawName: 'user_address',
        name: 'ieul_estimate_entry[user_address]',
        necessary: true,
        label: 'ご住所の字/丁目以降',
    },
    {
        type: input_store_factory_1.InputType.TEL,
        rawName: 'user_tel',
        name: 'ieul_estimate_entry[user_tel]',
        necessary: true,
        label: '電話番号',
        description: 'ハイフンなしでご記入お願いします',
    },
    {
        type: input_store_factory_1.InputType.EMAIL,
        rawName: 'user_email',
        name: 'ieul_estimate_entry[user_email]',
        necessary: true,
        label: 'メールアドレス',
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_motivation',
        name: 'ieul_estimate_entry[user_motivation]',
        necessary: true,
        label: 'ご依頼の理由',
        options: props.userForm.userMotivationOptions,
    },
    {
        type: input_store_factory_1.InputType.RADIO,
        rawName: 'user_assessment_type',
        name: 'ieul_estimate_entry[user_assessment_type]',
        necessary: true,
        label: '査定方法',
        options: props.userForm.userAssessmentTypeOptions,
    }
]; };
exports.buildUserFormInputProps = buildUserFormInputProps;
var buildEstimateStatInputProps = function (props) { return [
    (0, exports.companyInput)(),
    {
        type: input_store_factory_1.InputType.CHECKBOX,
        rawName: 'user_demand',
        name: 'ieul_estimate_entry[user_demand][]',
        label: '査定会社への要望',
        necessary: false,
        options: props.estimateStart.userDemandOptions,
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_replacement',
        name: 'ieul_estimate_entry[user_replacement]',
        necessary: true,
        label: 'お買い換えの有無',
        options: props.estimateStart.userReplacementOptions,
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_desired_date',
        name: 'ieul_estimate_entry[user_desired_date]',
        necessary: true,
        label: '売却希望時期',
        options: props.estimateStart.userDesiredDateOptions,
    },
    {
        type: input_store_factory_1.InputType.CHECKBOX,
        rawName: 'user_desired_contact_day',
        name: 'ieul_estimate_entry[user_desired_contact_day][]',
        necessary: true,
        label: '希望連絡曜日',
        options: props.estimateStart.userDesiredContactDayOptions,
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_desired_contact_time_start',
        name: 'ieul_estimate_entry[user_desired_contact_time_start]',
        necessary: true,
        label: '希望連絡開始時間',
        options: props.estimateStart.userDesiredContactTimeStartOptions,
    },
    {
        type: input_store_factory_1.InputType.SELECT,
        rawName: 'user_desired_contact_time_end',
        name: 'ieul_estimate_entry[user_desired_contact_time_end]',
        necessary: true,
        label: '希望連絡終了時間',
        options: props.estimateStart.userDesiredContactTimeEndOptions,
    },
    {
        type: input_store_factory_1.InputType.TEXTAREA,
        rawName: 'user_comment',
        name: 'ieul_estimate_entry[user_comment]',
        necessary: false,
        label: 'コメント',
    }
]; };
exports.buildEstimateStatInputProps = buildEstimateStatInputProps;
