/* eslint-disable */
import Validator from '~/validator/validator'
import Util from '@hikakaku/shared/util/util'
import * as types from '~/constants/forms/form_builder_constants'
import * as texts from '~/constants/forms/form_builder_texts'

class FormBuilderValidator {
  constructor() {
  }

  validate(store) {
    let result = true
    let inputStores = store.getInputStores()
    inputStores.forEach((inputStore) => {
      let res = this.validateInput(inputStore)
      inputStore.setAlertText(res.alertText)
      result = (result === true) ? res.result : result
    })
    return result
  }

  validateInput(inputStore) {
    let alertText = ''
    let result = true
    let value = inputStore.getValue()
    //必須
    if (inputStore.necessary === true &&
      Validator.isEntered(value) === false) {
      return {
        result: false,
        alertText: texts.FORM_INPUT_TEXT_VALIDATE_NECESSALY
      }
    }

    switch (inputStore.type) {
    case types.FORM_INPUT_TYPE_EMAIL:
      this.convertMultiByteToSingleByte(inputStore, Validator.validateEmail)
      return this.validateEmail(inputStore.getValue())

      break
    case types.FORM_INPUT_TYPE_TEL:
      this.convertMultiByteToSingleByte(inputStore, Validator.validateTel)
      return this.validateTel(inputStore.getValue())
      break
    }
    return {
      result: result,
      alertText: alertText
    }
  }

  validateTel(value) {
    let result = this.validateFormat(value, Validator.validateTel)
    return {
      result: result,
      alertText: result ? '' : texts.FORM_INPUT_TEXT_VALIDATE_TEL
    }
  }

  validateEmail(value) {
    //フォーマットチェック
    let result = this.validateFormat(value, Validator.validateEmailFormat)
    if (result === false) {
      return {
        result: result,
        alertText: texts.FORM_INPUT_TEXT_VALIDATE_EMAIL_FORMAT
      }
    }

    //ヒカカクで対応しているメールアドレス形式かチェック
    result = this.validateFormat(value, Validator.validateEmail)
    return {
      result: result,
      alertText: result ? '' : texts.FORM_INPUT_TEXT_VALIDATE_EMAIL
    }
  }

  validateFormat(value, validator) {
    return Validator.isEntered(value) === false ||
      validator.bind(Validator)(value) === true
  }

  //全角を半角に変換
  convertMultiByteToSingleByte(inputStore, validator) {
    let value = inputStore.getValue()
    if (validator.bind(Validator)(value) === false) {
      return
    }
    inputStore.setValue(Util.multiByteStringToSingleByteString(value))
  }

  preValidateInput(inputStore) {
    const value = inputStore.getValue()

    if (Validator.isEntered(value) === false) {
      return false
    }

    switch (inputStore.type) {
    case types.FORM_INPUT_TYPE_EMAIL:
      return this.validateEmail(Util.multiByteStringToSingleByteString(value)).result
    case types.FORM_INPUT_TYPE_TEL:
      return this.validateTel(Util.multiByteStringToSingleByteString(value)).result
    }
    return true
  }
}

export default new FormBuilderValidator()
