import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerTargetServiceName extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object,
    title: PropTypes.string
  }

  constructor(props) {
    super(props)
  }

  renderServiceNameLabel() {
    const serviceId = this.props.reviewFormStore.selectedServiceId
    const targetService = this.props.reviewFormStore.services.find((i) => i.id === serviceId)
    if (targetService) {
      return (
        <div className="reviewpopup-Form_service_name">
          {targetService.name}
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="review_service">{this.props.title}</label><span className="required co-gray-2"></span>
        </div>
        <div>
          {this.renderServiceNameLabel()}
        </div>
      </div>
    )
  }
}
