import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'
import Select from 'react-select'

@observer
export default class ReviewFormInnerShop extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object,
    shops: PropTypes.arrayOf(PropTypes.object)
  }

  constructor(props) {
    super(props)
    this.handleChangeShop = this.handleChangeShop.bind(this)
  }

  handleChangeShop({value}) {
    this.props.reviewFormStore.selectedShopId = value
  }

  selectOptions = () => {
    return this.props.shops.map(({id, name}) => ({value: id, label: name}))
  }

  render() {
    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="reviewer_map">店舗</label><span className="optional co-gray-2">任意</span>
        </div>
        <Select
          className="reviewpopup-Form_selectInput"
          id="review_shop"
          name="review_shop"
          onChange={this.handleChangeShop}
          options={this.selectOptions()}
          placeholder="選択してください"/>
      </div>
    )
  }
}
