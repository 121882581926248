import React from 'react'
import LazyLoad from 'react-lazyload'
import {sprintf} from 'sprintf-js'
import UrlManager from '~/util/url_manager'
import Util from '@hikakaku/shared/util/util'
import _ from 'lodash'
import qs from 'qs'
import moment from 'moment'
import classNames from 'classnames'
import {toCamelcaseKeys} from '@hikakaku/shared/util/convert_case_keys'
import KaitoriHistoryItems from '~/components/single_products/kaitori_history_items'

class RankingCellSingleProduct extends React.Component {
  get MAX_TEXT_NUM_POSTAGE() {
    return 70 //送料のテキスト最大文字数
  }

  get MAX_TEXT_NUM_REMARKS() {
    return 70 //備考のテキスト最大文字数
  }

  get MAX_TEXT_NUM_REPORT()	{
    return 70 //特記事項のテキスト最大文字数
  }

  get CV_BTN_WIDE()	{
    return '/wp-content/themes/hikakaku_theme/images/cv_btn_wide.png'
  }
  get CV_BTN_BLUE()	{
    return '/wp-content/themes/hikakaku_theme/images/btn_cv_bu_bold.png'
  }
  get CV_BTN_WIDE_BOLD() {
    return '/wp-content/themes/hikakaku_theme/images/cv_btn_wide_bold.png'
  }
  get CV_BTN_DEFAULT() {
    return 'https://static.hikakaku.com/public/wp-content/uploads/2014/09/kokode.png'
  }
  get STORE_NOIMAGE_PATH() {
    return '/wp-content/themes/hikakaku_theme/images/company_noimage.jpg'
  }

  get ESTIMATE_BUTTON_TEXT() {
    return "無料で査定依頼する"
  }
  get CLICK_CONVERSION_BUTTON_TEXT() {
    return "公式サイトへ"
  }
  get DETAIL_BUTTON_TEXT() {
    return "詳細へ"
  }

  get GA_ESTIMATE_FORM_POP() {
    return "サイト内無料見積りフォーム click フォーム起動"
  }
  get GA_CLICK_TRANSFER_EVENT() {
    return "クリック送客(契約業者) click 無料見積りボタン"
  }
  get GA_AFFILIATE_EVENT() {
    return "クリック送客(アフィ) click 無料見積りボタン"
  }
  get GA_NO_CONTRACT_EVENT() {
    return "クリック送客(非契約業者) click 無料見積りボタン"
  }

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  hasActiveEstimateForm() {
    return this.props.data.store.store_contract.assessment_form && !this.props.data.store.suspended_estimate_form
  }

  showEstimateFormButton() {
    return !this.props.data.store.store_contract.cv_click_transfer_first && this.hasActiveEstimateForm()
  }

  hasActiveClickTransfer() {
    return this.props.data.store.store_contract.click_transfer && !this.props.data.store.suspended_click_transfer
  }

  getPriceObj() {
    let priceObj
    if (this.props.data.price <= 1) {
      priceObj = (
        <span className="si-result__price">
          <span className="item__ranking__cell__info__failed_price">
            要見積もり
          </span>
        </span>
      )
    } else {
      priceObj = (
        <span>
          <span className="item__ranking__cell__info__currency_unit">〜¥</span>
          <span className="item__ranking__cell__info__price">{this.props.data.price.toLocaleString()}</span>
        </span>
      )
    }
    return priceObj
  }

  getCreatedTime() {
    if (this.props.data.price <= 1 || !this.props.data.price_created_at) {
      return ''
    } else {
      const createdTime = moment(this.props.data.price_created_at).format('YYYY/M/D')
      return (
        <span className="created-time co-gray-2 fnt-size-8">({createdTime}時点)</span>
      )
    }
  }

  renderShopLink() {
    if (this.showEstimateFormButton()) {
      return this.getAssessmentPopupTag()
    } else if (this.hasActiveClickTransfer()) {
      return this.getClickConversionLinkTag()
    } else {
      return this.getDetailLinkTag()
    }
  }

  getSpecialNotes() {
    let ret = this.props.data.store.special_notes
    ret = Util.substr(ret, this.MAX_TEXT_NUM_REPORT)
    return ret
  }

  getPostage() {
    let ret = this.props.data.store.postage
    ret = Util.substr(ret, this.MAX_TEXT_NUM_POSTAGE)
    return ret
  }

  getPostageAttention() {
    let ret = this.props.data.store.postage_attention
    ret = Util.substr(ret, this.MAX_TEXT_NUM_REMARKS)
    return ret
  }

  getRankingbadge(rank) {

    switch (rank) {
    case 1: {
      return 'gold'
    }
    case 2: {
      return 'silver'
    }
    case 3: {
      return 'bronze'
    }
    default: {
      return 'normal'
    }
    }
  }

  getStoreLinkPath() {
    const store = this.props.data.store
    const item = this.props.item
    return store.major
      ? `/kaitoriinfo/multi_post/company/${store.url}/product/${item.url}`
      : `/company/${store.id}/`
  }

  renderCVBtnsSP() {
    let store = this.props.data.store
    let btn_class = store.is_phone_open ? "" : " wide"
    return (
      <div className="item__ranking__cell__cv">
        <div className={"item__ranking__cell__cv__btn" + btn_class}>
          {this.renderShopLink()}
        </div>
        {this.renderTelSP()}
      </div>
    )
  }

  renderTelSP() {
    let store = this.props.data.store
    if (store.is_phone_open === false) {
      return
    }
    return (
      <div className="item__ranking__cell__cv__tel">
        <a className="item__ranking__cell__cv__tel__text" href={"tel:" + store.phone_number}
          data-analytics-track-event={"電話（お問い合わせ） click"} >
          <div className="item__ranking__cell__cv__tel__btn">
            <div className="i fa fa-phone-volume item__ranking__cell__cv__tel__phone_icon" />
            電話をかける
          </div>
        </a>
        <p className="item__ranking__cell__cv__tel__time">
          受付時間: {store.phone_open_time}〜{store.phone_close_time}
        </p>
      </div>
    )
  }

  renderTelPC() {
    let store = this.props.data.store
    if (store.is_phone_open === false) {
      return
    }
    return (
      <div className="item__ranking__cell__cv__tel">
        <div className="item__ranking__cell__cv__tel__btn">
          <div className="item__ranking__cell__cv__tel__text">
            <div className="i fa fa-phone-volume item__ranking__cell__cv__tel__phone_icon" />
            {store.phone_number}
            <p className="item__ranking__cell__cv__tel__time">
              受付時間: {store.phone_open_time}〜{store.phone_close_time}
            </p>
          </div>
        </div>
      </div>
    )
  }

  getClickConversionLinkTag() {
    const link = this.hasActiveClickTransfer()
      ? this.props.data.store.store_contract.click_transfer_path
      : this.props.data.store.link_url
    return (
      <a href={link}
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="sw-LegacyButton sw-LegacyButton-fixed sw-LegacyButton-orange"
        data-analytics-track-event={this.getGAEvent()}>
        {this.CLICK_CONVERSION_BUTTON_TEXT}
      </a>
    )
  }

  getDetailLinkTag() {
    return (
      <a href={this.props.data.store.detail_page_path} className="sw-LegacyButton sw-LegacyButton-fixed sw-LegacyButton-blue">
        {this.DETAIL_BUTTON_TEXT}
      </a>
    )
  }

  /*査定フォームのポップアップタグ*/
  getAssessmentPopupTag() {
    const form_query = qs.stringify({
      store_id: this.props.data.store.id,
      item_id: this.props.item.id
    })
    return (
      <span>
        <a href={`/store_estimate_form/?${form_query}` }
          data-analytics-track-event={this.getGAEvent()}
          rel="nofollow"
          className="sw-LegacyButton sw-LegacyButton-fixed sw-LegacyButton-red">
          {this.ESTIMATE_BUTTON_TEXT}
        </a>
        <span data-jet_imp="page_ass_btn_imp-product_page" data-jet_options={"{\"company_id\":\"" + this.props.data.store.post_id + "\"}"} data-jet_settings={"{\"is_insert_once\":true}"} >
        </span>
      </span>
    )
  }

  getGAEvent() {
    const contract = this.props.data.store.store_contract
    if (contract.assessment_form) {
      return this.GA_ESTIMATE_FORM_POP
    } else if (contract.click_transfer) {
      return `${this.GA_CLICK_TRANSFER_EVENT} ${contract.cost_per_click}`
    } else if (contract.affiliate) {
      return this.GA_AFFILIATE_EVENT
    } else {
      return this.GA_NO_CONTRACT_EVENT
    }
  }

  renderAssessmentCountInner() {
    let store = this.props.data.store
    return (
      <p className="item__ranking__cell__assessment__num">
        {store.achievement_count}件
      </p>
    )
  }

  renderAssessmentCount() {
    let store = this.props.data.store
    if (store.achievement_count < 1) {
      return this.renderAssessmentCountInner()
    }

    return (
      <a href={UrlManager.getStoreAssessmentAchievementsPath(store.url)} >
        {this.renderAssessmentCountInner()}
      </a>
    )
  }

  createStarRatingClass() {
    let store = this.props.data.store
    let rating = Util.roundHalf(store.rating)
    rating = sprintf('%.1f', rating)
    rating = rating.replace('.', '-')
    let ret = 's' + rating
    return ret
  }

  getStoreImageUrl() {
    let store = this.props.data.store
    let url = store.logo_url
    if (typeof url !== 'string' || url.length < 1) {
      return this.STORE_NOIMAGE_PATH
    }
    return store.logo_url
  }

  createImageNone() {
    const ids = this.props.item.category_ids

    return (
      <div className={classNames('taxonomy', 'thumb_wrapper', 'hover_bg', ids.map((id) => `id${id}`))}>
        <div className={classNames('taxonomy', 'thumb', 'icon', ids.map((id) => `id${id}`))}>
        </div>
      </div>
    )
  }

  getKaitoriHistoriesByStore() {
    const props = toCamelcaseKeys({
      histories: this.props.data.kaitori_histories.map((history) => ({
        ...history,
        estimateFormPath: "/estimate_form?import_category_id=" + this.props.item.import_category_id
      }))
    })
    if (props.histories.length === 0) { return }

    // eslint-disable-next-line consistent-return
    return <KaitoriHistoryItems {...props}/>
  }

  getRenderSP() {
    let review_rate_star_style = {
      width: "100px",
      textAlign: "center"
    }
    let store = this.props.data.store

    return (
      <li key={store.id} className={"item__ranking__cell__" + this.getRankingbadge(this.props.index + 1) + "_border"}>

        <div className="flex">
          <div className="item__ranking__cell__badge">
            {this.props.pr ? (
              <div className="item__ranking__cell__badge__pr">
                PR
              </div>
            ) : (
              <div className={"item__ranking__cell__badge__" + this.getRankingbadge(this.props.index + 1)}>
                {this.props.index + 1}
              </div>
            )}
          </div>
          <span className="item__ranking__cell__name__text">
            <a href={this.getStoreLinkPath()}>
              {store.name}
            </a>
          </span>
        </div>

        <div className="item__ranking__cell__name block">

          <a href={this.getStoreLinkPath()}>
            <div className="item__ranking__cell__name__thumb">
              <LazyLoad height={80} style={{height: '80px'}}once>
                <img alt={store.name} src={this.getStoreImageUrl()} className="lazyload" />
              </LazyLoad>

              <div>
                <div className="item__ranking__cell__review">
                  <div className="item__ranking__cell__review__star-wrapper">
                    <div className={"review__stars xs block-center " + this.createStarRatingClass()} >
                    </div>
                  </div>
                  <div className="review_rate_number fnt-size-10 co-red">
                    {store.rating}
                  </div>
                  <div className="co-black fnt-size-6">
                    {"(" + store.review_count + "件のクチコミ)"}
                  </div>
                </div>

                <div className="item__ranking__cell__satisfaction_rate">
                  {Math.round(store.satisfaction_rate * 100)}%の人が査定結果に満足しています
                </div>

                <div className="item__ranking__cell__shopstyle">
                  <span className={"si-result__keishiki si-result__keishiki--type-" + ((store.purchase_method.substr(0, 1) === "1") ? "true" : "false")}>
                    宅配
                  </span>
                  <span className={"si-result__keishiki si-result__keishiki--type-" + ((store.purchase_method.substr(1, 1) === "1") ? "true" : "false")}>
                    店頭
                  </span>
                  <span className={"si-result__keishiki si-result__keishiki--type-" + ((store.purchase_method.substr(2, 1) === "1") ? "true" : "false")}>
                    出張
                  </span>
                </div>
              </div>
            </div>
          </a>

        </div>


        <div className="item__ranking__cell__maininfo block">

          <div className="item__ranking__cell__info">
            <h3 className="item__ranking__cell__title">
              最高買取価格
            </h3>
            <p className="item__ranking__cell__info__price_field">
              {this.getPriceObj()}
            </p>
            <p className="item__ranking__cell__info__createdtime">
              {this.getCreatedTime()}
            </p>
          </div>

          <div className="item__ranking__cell__info">
            <h3 className="item__ranking__cell__title">
              送料
            </h3>
            <div>
              <p className="item__ranking__cell__info__text">
                {this.getPostage()}
              </p>
              <p className="item__ranking__cell__info__text__postage_attention">
                {this.getPostageAttention()}
              </p>
            </div>
          </div>

          <div className="item__ranking__cell__info">
            <h3 className="item__ranking__cell__title">
              特記事項
            </h3>
            <p className="item__ranking__cell__info__text__note">
              {this.getSpecialNotes()}
            </p>
          </div>

          <div className="item__ranking__cell__info">
            <div className="item__ranking__cell__assessment">
              <div className="flex">
                <h3 className="item__ranking__cell__title">
                  査定実績
                </h3>
                {this.renderAssessmentCount()}
              </div>
              {this.getKaitoriHistoriesByStore()}
            </div>
          </div>
        </div>

        {this.renderCVBtnsSP()}

      </li>
    )
  }


  getRenderPC() {
    let review_rate_star_style = {
      width: "100%",
      textAlign: "center"
    }

    let store = this.props.data.store

    return (
      <li key={store.id} className="item__ranking__cell">

        <div className="flex">
          <div className="item__ranking__cell__badge">
            {this.props.pr ? (
              <div className="item__ranking__cell__badge__pr">
                PR
              </div>
            ) : (
              <div className={"item__ranking__cell__badge__" + this.getRankingbadge(this.props.index + 1)}>
                {this.props.index + 1}
              </div>
            )}
          </div>
          <span className="item__ranking__cell__name__text">
            <a href={this.getStoreLinkPath()}>
              {store.name}
            </a>
          </span>

          <div className="item__ranking__cell__cv__block">
            <div className="item__ranking__cell__cv__btn">
              {this.renderShopLink()}
            </div>
            {this.renderTelPC()}
          </div>
        </div>

        <a href={this.getStoreLinkPath()}>
          <div className="item__ranking__cell__name block">
            <div className="item__ranking__cell__name__thumb">
              <LazyLoad height={80} style={{height: '80px'}}once>
                <img alt={store.name} src={this.getStoreImageUrl()} className="lazyload" />
              </LazyLoad>
            </div>

            <div className="item__ranking__cell__review">
              <div className="item__ranking__cell__review__star-wrapper">
                <div className={"review__stars xs block-center " + this.createStarRatingClass()} >
                </div>
              </div>
              <div className="review_rate_number fnt-size-10 co-red">
                {store.rating}
              </div>
              <div className="co-black fnt-size-6">
                {"(" + store.review_count + "件のクチコミ)"}
              </div>
            </div>

            <div className="item__ranking__cell__satisfaction_rate">
              {Math.round(store.satisfaction_rate * 100)}%の人が査定結果に満足しています
            </div>

            <div className="item__ranking__cell__shopstyle">
              <span className={"si-result__keishiki si-result__keishiki--type-" + ((store.purchase_method.substr(0, 1) === "1") ? "true" : "false")}>
                宅配
              </span>
              <span className={"si-result__keishiki si-result__keishiki--type-" + ((store.purchase_method.substr(1, 1) === "1") ? "true" : "false")}>
                店頭
              </span>
              <span className={"si-result__keishiki si-result__keishiki--type-" + ((store.purchase_method.substr(2, 1) === "1") ? "true" : "false")}>
                出張
              </span>
            </div>
          </div>
        </a>


        <div className="item__ranking__cell__maininfo block">

          <div className="item__ranking__cell__info">
            <h3 className="item__ranking__cell__title">
              最高買取価格
            </h3>
            <p className="item__ranking__cell__info__price_field">
              {this.getPriceObj()}
            </p>
            <div className="item__ranking__cell__info__createdtime">
              {this.getCreatedTime()}
            </div>
          </div>

          <div className="item__ranking__cell__info">
            <h3 className="item__ranking__cell__title">
              送料
            </h3>
            <div>
              <p className="item__ranking__cell__info__text">
                {this.getPostage()}
              </p>
              <p className="item__ranking__cell__info__text__postage_attention">
                {this.getPostageAttention()}
              </p>
            </div>
          </div>

          <div className="item__ranking__cell__info">
            <h3 className="item__ranking__cell__title">
              特記事項
            </h3>
            <p className="item__ranking__cell__info__text__note">
              {this.getSpecialNotes()}
            </p>
          </div>

          <div className="item__ranking__cell__info">
            <div className="item__ranking__cell__assessment">
              <div className="flex">
                <h3 className="item__ranking__cell__title">
                  査定実績
                </h3>
                {this.renderAssessmentCount()}
              </div>
              {this.getKaitoriHistoriesByStore()}
            </div>
          </div>
        </div>

      </li>
    )
  }

  render() {
    if (typeof this.props.isMobile === "undefined" ||
      this.props.isMobile === null) {
      return this.getRenderPC()
    } else {
      return this.getRenderSP()
    }
  }
}

class RankingListSingleProduct extends React.Component {

  get RECOMMEND_SORT_LABEL() {
    return "おすすめ順"
  }
  get PRICE_SORT_LABEL() {
    return "上限買取価格順"
  }
  get REVIEW_SORT_LABEL() {
    return "評価順"
  }

  constructor(props) {
    super(props)

    const count = this.props.is_mobile ? 10 : 20

    this.state = {
      heighestPriceErrorVal: -9999,
      heihestPrice: this.carcHeighestPrice(),
      currentSortKey: "",
      sortKeys: {
        review: "store.rating",
        price: "price",
      },
      maxShowCount: count,
      loadCount: count,
    }

    this.resetSort = this.resetSort.bind(this)
    this.sortByReview = this.sortByReview.bind(this)
    this.sortByPrice = this.sortByPrice.bind(this)
    this.onLoadBtnClick = this.onLoadBtnClick.bind(this)
  }

  setInitialProps() {
    return {}
  }

  carcHeighestPrice() {
    const priceList = Util.objectArraySort(_.cloneDeep(this.props.data.prices), "price")
    return priceList[0].price
  }

  resetSort() {
    this.setState({currentSortKey: ""})
  }

  sortByReview() {
    this.setState({currentSortKey: "review"})
  }

  sortByPrice() {
    this.setState({currentSortKey: "price"})
  }

  onLoadBtnClick(e) {
    e.preventDefault()
    if (this.props.data.prices.length <= this.state.maxShowCount) {
      this.setState({maxShowCount: this.state.loadCount})
      return
    }
    this.setState({maxShowCount: this.state.maxShowCount + this.state.loadCount})
  }

  getLoadBtnText() {
    let ret
    if (this.props.data.prices.length <= this.state.maxShowCount) {
      ret = "閉じる"
    } else {
      ret = this.state.maxShowCount + 1
      ret += "位以下を見る"
    }
    return ret
  }

  UNSAFE_componentWillMount() {
    let loadCount = this.props.load_count
    if (1 < loadCount) {
      this.setState({loadCount: loadCount, maxShowCount: loadCount})
    }
  }

  update() {
    if (typeof window.hika_sp_popup_init === 'function') {
      window.hika_sp_popup_init()
    }
    if (window.jQuery !== undefined) {
      let obj = $('.item__ranking__list')
      if (window.jQuery.review_star !== undefined) {
        window.jQuery.review_star.set_star_size(obj)
      }
    }
  }

  componentDidMount() {
    this.update()
  }

  componentDidUpdate() {
    this.update()
  }

  getLoadBtn() {
    let ret = ""
    if (this.props.data.prices.length <= this.state.maxShowCount) {
      return ret
    }
    ret = (
      <div id="next" className="">
        <a href="" onClick={this.onLoadBtnClick}>
          <span className="btn-low fnt-size-10">{ this.getLoadBtnText()}</span>
        </a>
      </div>
    )
    return ret
  }

  /*@brief ランキングヘッダーを返す*/
  getRankingHeader() {
    return (
      <ul className="tabbar two">
        <li className={ "tabbar__cell " + ((this.state.currentSortKey === "") ? "active" : "")}>
          <button className="tabbar__cellbtn" id="toggle-ranking-sort-normal" onClick={this.resetSort}>{this.RECOMMEND_SORT_LABEL}</button>
        </li>
        <li className={ "tabbar__cell " + ((this.state.currentSortKey === "price") ? "active" : "")}>
          <button className="tabbar__cellbtn" id="toggle-ranking-sort-normal" onClick={this.sortByPrice}>{this.PRICE_SORT_LABEL}</button>
        </li>
        <li className={ "tabbar__cell " + ((this.state.currentSortKey === "review") ? "active" : "")}>
          <button className="tabbar__cellbtn" id="toggle-ranking-sort-review" onClick={this.sortByReview}>{this.REVIEW_SORT_LABEL}</button>
        </li>
      </ul>
    )
  }

  getRankingSelect() {
    return (
      <div className="item__ranking__header">
        <select onChange={(e) => this.setState({currentSortKey: e.target.value})} className="item__ranking__header__select">
          <option value="">{this.RECOMMEND_SORT_LABEL}</option>
          <option value="price">{this.PRICE_SORT_LABEL}</option>
          <option value="review">{this.REVIEW_SORT_LABEL}</option>
        </select>
      </div>
    )
  }


  render() {
    const store = this.props
    const sortKey = this.state.sortKeys[this.state.currentSortKey]
    let targetList = sortKey ? Util.objectArraySort(_.cloneDeep(this.props.data.prices), sortKey) : this.props.data.prices
    let elms = []
    targetList.some((obj, index) => {
      if (this.state.maxShowCount <= index) {
        return true
      }
      elms.push(<RankingCellSingleProduct
        key = {index}
        index = {index}
        data = {obj}
        item = {store.data.item}
        heighestPrice = {this.state.heihestPrice}
        isMobile = {store.is_mobile}
      />)
    })
    let loadBtn = this.getLoadBtn()
    return (
      <div>
        {store.is_mobile ? this.getRankingSelect() : this.getRankingHeader()}
        <ul className="item__ranking__list">
          {this.props.data.pr_prices.map((price) => (
            <RankingCellSingleProduct
              pr
              key={price.store.id}
              data={price}
              item={this.props.data.item}
              heighestPrice={this.state.heihestPrice}
              isMobile={store.is_mobile}/>
          ))}
          {elms}
        </ul>
        <div>
          <div className="alignright">
            <span className="paged_number fnt-size-10 ">
              { ((this.state.maxShowCount <= this.props.data.prices.length) ? this.state.maxShowCount : this.props.data.prices.length) + "/" + this.props.data.prices.length + "件表示中"}
            </span>
          </div>
          {loadBtn}
        </div>
      </div>
    )
  }
}

export default RankingListSingleProduct
