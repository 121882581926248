//star
$(function() {
  if ($("#kaitorishow").length) {

    let dom = $("#content__show__areasearch_sp")
    $('.rang-star').each(function() {
      let rating = parseFloat($(this).attr('data-rating'))
      if (dom.length) {
        var rating_width = parseInt(90 * (rating / 5))
      } else {
        var rating_width = parseInt(160 * (rating / 5))
      }
      $(this).css('width', rating_width + 'px')
    })

    if ($("#content__show__areasearch_sp").length) {
      $('[name=prefecture]').change(function() {
        let pref = $(this).val()
        let url = $(this).attr('dataurl')
        location.href = url + '/maps/' + pref
      })
    }//sp_search

  }//end
})
