// @flow
import {observable, action, computed} from 'mobx'
import type {IObservableValue, IObservableArray, IComputedValue} from 'mobx'
import axios from 'axios'
import qs from 'qs'
import {captureException} from '@sentry/browser'

type Name = string
type Slug = string

export default class CategorySelectorStore {
  @observable showPopup: IObservableValue<boolean> = false
  @observable loading: IObservableValue<boolean> = false
  @observable loaded: IObservableValue<boolean> = false
  @observable.ref childList: IObservableArray<{
    current_name: Name,
    current_slug: Slug,
  }> = []
  @observable currentName: IObservableValue<Name> = ''
  @observable currentSlug: IObservableValue<Slug> = ''
  @observable prevName: IObservableValue<Name> = ''
  @observable prevSlug: IObservableValue<Slug> = ''
  @observable parentName: ?IObservableValue<Name> = null
  @observable parentSlug: ?IObservableValue<Slug> = null
  @observable headName: IObservableValue<Name> = ''
  @observable headSlug: IObservableValue<Slug> = ''
  @observable listHeight: IObservableValue<number | string | null> = null
  isBack: boolean = false
  requestData: {
    slug: Slug,
    tax: string,
  } = {
    slug: '',
    tax: '',
  }
  node: HTMLElement

  constructor(props: any) {
    if (this) {
      this.currentName = props.name
      this.currentSlug = props.slug
      this.prevName = props.name
      this.prevSlug = props.slug
      this.headName = props.name
      this.headSlug = props.slug
      this.requestData = {
        slug: props.slug,
        tax: props.tax
      }
    }
  }

  @computed get hasParent(): IComputedValue<boolean> {
    return this.parentName !== null
  }

  @computed get listClassNames(): IComputedValue<string> {
    const classNames = ['table_view_list']
    if (this.loaded) {
      classNames.push('is_enter')
    } else if (this.loading) {
      classNames.push('is_leave')
    }
    if (this.isBack) {
      classNames.push('is_back')
    }
    return classNames.join(' ')
  }

  @action fetchList(isBack?: boolean = false) {
    this.isBack = isBack
    this.loaded = false
    this.loading = true
    this.requestData.slug = this.currentSlug
    axios.get(`/api/categories/lists/?${qs.stringify(this.requestData)}`)
      .then(({data}) => {
        this.childList = data.child_list
        this.parentName = data.parent_name
        this.parentSlug = data.parent_slug
        this.headName = data.current_name || this.getRootName()
        this.headSlug = data.current_slug || 'root'
      })
      .catch((error) => {
        console.error("dataの取得に失敗しました", error)
        captureException(error)
      })
      .then(() => {
        this.loading = false
        this.loaded = true
      })
  }

  @action.bound onClickOuter(e: Event) {
    if (e) {
      e.preventDefault()
    }
    console.log($(e.target).closest(this.node), this.node)
    if ($(e.target).closest(this.node).length > 0) {
      return
    }
    this.changeShowPopup(false)
  }

  @action.bound onClickTogglePopup(e: Event) {
    if (e) {
      e.preventDefault()
    }
    this.changeShowPopup(!this.showPopup)
  }

  @action.bound onClickFetch(slug: Slug, name: Name, isBack?: boolean = false) {
    return (e: Event) => {
      if (e) {
        e.preventDefault()
      }
      this.currentSlug = slug
      this.currentName = name
      this.fetchList(isBack)
    }
  }

  @action.bound onClickSelect(slug: Slug, name: Name) {
    return (e: Event) => {
      if (e) {
        e.preventDefault()
      }
      this.currentSlug = slug
      this.currentName = name
      this.prevSlug = this.currentSlug
      this.prevName = this.currentName
      this.changeShowPopup(false)
    }
  }

  @action changeShowPopup(showPopup: boolean) {
    this.showPopup = showPopup
    if (typeof document === 'undefined') {
      return
    }
    if (ju_kaitori_.isMobile()) {
      ju_kaitori_.scrollLock(this.showPopup)
    }
    if (this.showPopup) {
      if (this.childList.length === 0) {
        this.fetchList()
      }
      window.addEventListener('click', this.onClickOuter)
    } else {
      this.loading = false
      this.loaded = false
      window.removeEventListener('click', this.onClickOuter)
    }
  }

  @action calculateListHeight(node: ?HTMLElement) {
    if (!node || ju_kaitori_.isMobile()) {
      return
    }
    const defaultMargin = 20
    const scrollTop = $(window).scrollTop()
    const posY = $(node).offset().top
    const winHeight = $(window).height()
    const height = (winHeight - (posY - scrollTop)) - defaultMargin
    if (!this.loading && this.showPopup) {
      this.listHeight = height
    }
  }

  setNode(node: HTMLElement) {
    this.node = node
  }

  getRootName() {
    if (this.requestData.tax === 'maps') {
      return '全ての地域'
    } else if (this.requestData.tax === 'co_cats') {
      return '全てのカテゴリ'
    }
    return null
  }

  getSelectorClass() {
    if (this.requestData.tax === 'maps') {
      return 'selector_maps'
    } else if (this.requestData.tax === 'co_cats') {
      return 'selector_co_cats'
    } else if (this.requestData.tax === 'category') {
      return 'selector_category'
    }
    return null
  }

  onClickStopPropagation(e: Event) {
    // if(e){e.stopPropagation();}
  }
}
