import React from 'react'
import ReactDom from 'react-dom'
import {observer} from 'mobx-react'
import {CSSTransitionGroup} from 'react-transition-group'
import loadingBarStore from '~/stores/loading_bar_store'

@observer
class LoadingBar extends React.Component {
  constructor(props) {
    super(props)
  }

  renderLoading() {
    if (loadingBarStore.isLoading === false) {
      return <div style={{ display: 'none' }} />
    }
    return <div className="loading-bar" key="loading"></div>
  }

  render() {
    return (
      <div>
        <CSSTransitionGroup
          transitionName="loading-bar"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500} >
          {this.renderLoading()}
        </CSSTransitionGroup>
      </div>
    )
  }
}

if (typeof document !== 'undefined') {
  $(() => {
    const elm = document.getElementById('loading-bar-container')
    if (elm != null) {
      ReactDom.render(
        <LoadingBar />,
        elm
      )
    }
  })
}
