// @flow
import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputOptionStore from '~/stores/forms/builder/input_option_store'
import type InputMultiSelectStore from '~/stores/forms/builder/input_multi_select_store'
import type {OptionParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputDynamicOptionStore extends InputOptionStore {
  @observable checked = false;
  constructor(param: OptionParam) {
    super(param)
  }

  init(param: OptionParam) {
    super.init(param)
    this.checked = param.checked || false
  }

  enableCheck(enabled: boolean) {
    this.checked = enabled
    this.handleChange()
  }

  setDelegate(delegate: InputMultiSelectStore) {
    this.delegate = delegate
  }

  handleChange() {
    if (typeof this.delegate === 'undefined') {
      return
    }
    if (typeof this.delegate.handleChange !== 'function') {
      return
    }
    this.delegate.handleChange()
  }

  handleBlur() {
    if (typeof this.delegate === 'undefined') {
      return
    }
    if (typeof this.delegate.handleBlur !== 'function') {
      return
    }
    this.delegate.handleBlur()
  }

  isChecked() {
    return this.checked
  }
}
