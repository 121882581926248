// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type {SelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

/*
 * @brief input セレクト
 * */
@observer
class InputSelectView extends React.Component<Props<string, SelectParam>> {

  onChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const {store} = this.props
    store.setValue(e.target.value)
    store.validate()
  }

  onBlur = (): void => {
    const {store} = this.props
    store.handleBlur()
  }

  createRadioBoxs() {
    const {store} = this.props
    return store.options.map((option, index) => {
      const checked = store.value && store.value.toString() === option.value.toString()
      return (
        <label key={index} className="radio-wrapper">
          <input type="radio" name={store.name} value={option.value} checked={checked} onChange={this.onChange} onBlur={this.onBlur}/>
          {option.label}
        </label>
      )
    })
  }

  render() {
    const {store} = this.props
    return (
      <div>
        {this.createRadioBoxs()}
      </div>
    )
  }
}
export default InputSelectView
