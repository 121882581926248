"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var OPEN_BUTTON_HIDE_CLASS = 'blogcard-Accordion_OpenButton-hide';
var CLOSE_BUTTON_HIDE_CLASS = 'blogcard-Accordion_CloseButton-hide';
var BlogcardAccordionController = /** @class */ (function (_super) {
    __extends(BlogcardAccordionController, _super);
    function BlogcardAccordionController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.open = function () {
            if (!_this.containerTarget || !_this.openButtonTarget || !_this.closeButtonTarget) {
                return;
            }
            _this.containerTarget.style.height = "".concat(_this.containerTarget.scrollHeight, "px");
            _this.openButtonTarget.classList.add(OPEN_BUTTON_HIDE_CLASS);
            _this.closeButtonTarget.classList.remove(CLOSE_BUTTON_HIDE_CLASS);
        };
        _this.close = function () {
            if (!_this.containerTarget || !_this.openButtonTarget || !_this.closeButtonTarget) {
                return;
            }
            _this.containerTarget.style.height = '0px';
            _this.openButtonTarget.classList.remove(OPEN_BUTTON_HIDE_CLASS);
            _this.closeButtonTarget.classList.add(CLOSE_BUTTON_HIDE_CLASS);
        };
        return _this;
    }
    BlogcardAccordionController.targets = ['container', 'openButton', 'closeButton'];
    return BlogcardAccordionController;
}(stimulus_1.Controller));
exports.default = BlogcardAccordionController;
