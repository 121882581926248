// @flow
import * as React from 'react'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type InputNestedOptionStore from '~/stores/forms/builder/input_nested_option_store'
import type {NestedSelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

/*
 * @brief input 入れ子セレクト
 * */
@observer
class InputNestedSelectView extends React.Component<Props<string, NestedSelectParam>> {

  onChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const {store} = this.props
    store.setValue(e.target.value)
  }

  onFocus = (): void => {
    const {store} = this.props
    store.uncheck()
  }

  onBlur = (): void => {
    const {store} = this.props
    store.validate()
    store.handleBlur()
  }

  createOptions() {
    const {store} = this.props
    return store.options.map((data, index) => {
      return (
        <option name={data.input_name} key={index} value={data.value} >{data.label}</option>
      )
    })
  }

  renderChildren(option: ?InputNestedOptionStore): ?React.Node {
    if (!option || !option.children) { return undefined }

    return (
      <InputNestedSelectView store={option.children}/>
    )
  }

  render() {
    const {store} = this.props
    const option = store.findOptionRecursively()
    const value = (option && option.value) || ''

    return (
      <React.Fragment>
        <div className="nested-select-select-wrapper">
          <select onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={value} name={store.getName()} className="input">
            <option value="">選択してください</option>
            {this.createOptions()}
            {/* iosでのselectboxで選択肢を全文表示させるために空のoptgroupタグを挿入しています */}
            <optgroup label=""></optgroup>
          </select>
          {store.depth === 0 &&
            <div className="nested-select-clear-btn-wrapper">
              <span onClick={() => store.clear()} className="jirafficon jirafficon-close nested-select-clear-btn"></span>
              {Boolean(store.clearBtnHelpText) && <span className="nested-select-clear-btn-help">{store.clearBtnHelpText}</span>}
            </div>
          }
        </div>
        {this.renderChildren(option)}
      </React.Fragment>
    )
  }
}
export default InputNestedSelectView
