import $ from 'jquery'
import 'slick-carousel'
import CarouselBase from '~/jquery-modules/carousels/carousel_base'

export default class CategoryCarousel extends CarouselBase {

  get SELECTOR() {
    return ".category-carousel.slick"
  }

  constractor() {
  }

}
