"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var ScrollButtonController = /** @class */ (function (_super) {
    __extends(ScrollButtonController, _super);
    function ScrollButtonController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.connect = function () {
            _this.setScroll(".action_scroll_to_form", ".buyback_form");
            _this.setScroll(".action_scroll_to_document", ".required_documents");
        };
        _this.setScroll = function (btn_class, scroll_target_class) {
            var body = $("html, body");
            var btn_obj = $(btn_class);
            var scroll_target = $(scroll_target_class);
            var scroll_margin = 100;
            var offset = scroll_target.offset();
            var scroll_top = offset.top - scroll_margin; //上のバーの分マージンを引く
            btn_obj.on('click', function (event) {
                event.preventDefault();
                body.animate({ scrollTop: scroll_top }, '1000', 'swing', function () { });
            });
        };
        return _this;
    }
    return ScrollButtonController;
}(stimulus_1.Controller));
exports.default = ScrollButtonController;
