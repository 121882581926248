import $ from 'jquery'
import _ from 'lodash'
import eventManager from '~/util/event_manager'

export default class BulkEstimateBottomBtn {

  initialize(): void {
    this.hideWhileScrolling()
  }

  hideWhileScrolling(): void {
    const bulkEstimateBottomBtn = $('.js-bulk-estimate-bottom-btn')

    $(window).on('scroll touchmove', _.throttle(() => {
      bulkEstimateBottomBtn.stop()
      bulkEstimateBottomBtn.addClass('s-bulk-estimate-bottom-btn-hidden').delay(400).fadeIn('fast', () => {
        bulkEstimateBottomBtn.removeClass('s-bulk-estimate-bottom-btn-hidden')
      })
    }, 100))
  }
}

eventManager.onReady(() => {
  new BulkEstimateBottomBtn().initialize()
})
