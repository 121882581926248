// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type {SelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

/*
 * @brief input セレクト
 * */
@observer
class InputSelectView extends React.Component<Props<string, SelectParam>> {

  onChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const {store} = this.props
    store.setValue(e.target.value)
  }

  onFocus = (): void => {
    const {store} = this.props
    store.uncheck()
  }

  onBlur = (): void => {
    const {store} = this.props
    store.validate()
    store.handleBlur()
  }

  createOptions() {
    const {store} = this.props
    return store.options.map((data, index) => {
      return (
        <option name={data.input_name} key={index} value={data.value} >{data.label}</option>
      )
    })
  }

  render() {
    const {store} = this.props
    return (
      <select onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={store.getValue()} name={store.getName()} className="input">
        <option value="">選択してください</option>
        {this.createOptions()}
        {/* iosでのselectboxで選択肢を全文表示させるために空のoptgroupタグを挿入しています */}
        <optgroup label=""></optgroup>
      </select>
    )
  }
}
export default InputSelectView
