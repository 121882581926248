import ReactDOM from 'react-dom'
import Util from '@hikakaku/shared/util/util'

!(function($) {
  $(document).ready(function() {
    let popup_wrapper = $(".popup_wrapper")
    let popup_close_btn = $(".popup_header .close_btn")
    let popup_show_btn_with_react = $(".popup_show_btn_with_react")
    let popup_callbacks = {}
    let scroll_top = 0
    let scroll_wrapper = $(".popup_wrapper.base .scroll_wrapper")

    /*非推奨*/
    window.hika_sp_popup_scroll_to = function(scroll_num) {
      $.ji_popup.scroll_to(scroll_num)
    }

    /*非推奨*/
    window.hika_sp_popup_close = function(e) {
      $.ji_popup.close_popup(e)
    }

    /*非推奨*/
    window.hika_sp_popup_init = function() {
      $.ji_popup.init()
    }

    $.ji_popup = new function() {
      this.is_scroll = true
      let self_ = this

      this.on_close_callback = null
      /* default popup */
      this.show_popup = function(click_obj) {
        var click_obj = $(click_obj)
        let popup_id = 0
        popup_id = click_obj.data("showid")
        this.show_popup_with_id(popup_id)
      }

      this.show_popup_with_id = function(id) {
        let popup_obj = $('*[data-popupid="' + id + '"]')
        if (0 < popup_obj.length) {
          let container = popup_obj.find('.scroll_wrapper')
          container.hide()
          popup_obj.css("opacity", "1")
          popup_obj.css("top", "0")
          popup_obj.on("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend", function() {
            scroll_top = $(window).scrollTop()
            $('body').addClass('no_scroll').css('top', (-scroll_top) + 'px')
            container.fadeIn(200)
            popup_obj.off("oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend")
          })
        }
      }

      //ポップアップを閉じる
      this.close_popup = function(e) {

        let is_close = true
        if (typeof self_.on_close_callback === "function") {
          is_close = self_.on_close_callback()
        }
        //コールバックからfalseが返って来た場合は閉じない
        if (is_close === false) {
          return
        }
        $("body").removeClass("no_scroll").css('top', 0)
        $(window).scrollTop(scroll_top)
        popup_wrapper.css("top", "150%")
        popup_wrapper.css("opacity", "0")
        self_.reset_callback()
      }

      this.scroll_to = function(scroll_num) {
        scroll_wrapper.scrollTop(scroll_num)
      }

      this.is_scroll_enable = function(enabled) {
        this.is_scroll = enabled
      }

      this.on_touch_event = function(e) {
        if (self_.is_scroll === false) {
          e.preventDefault()
        }
      }

      this.set_close_callback = function(callback) {
        this.on_close_callback = callback
      }

      this.remove_close_callback = function() {
        this.set_close_callback(null)
      }

      this.reset_callback = function() {
        this.remove_close_callback()
      }

      this.init = function() {
        popup_show_btn_with_react = $(".popup_show_btn_with_react")
        popup_wrapper.css("opacity", "0")
        scroll_wrapper.on('touchstart', this.on_touch_event)
        scroll_wrapper.on('touchmove', this.on_touch_event)
        scroll_wrapper.on('touchend', this.on_touch_event)

        popup_close_btn.off('click')
        popup_close_btn.on(
          'click',
          function(e) {
            $.ji_popup.close_popup(e)
          }
        )

        popup_show_btn_with_react.off('click')
        popup_show_btn_with_react.on(
          'click',
          function(e) {
            e.preventDefault()
            let click_obj = $(this)
            let react_obj_str = click_obj.data("react_obj")
            let popup_data = click_obj.data("popupsend")
            self_.react_props_ = popup_data || {}
            $.ji_popup_with_react.react_props_ = popup_data || {}
            $.ji_popup_with_react.show_popup(reactComponents[react_obj_str])
          }
        )

        let touch_start_y
        scroll_wrapper.on(
          "touchstart",
          function(e) {
            touch_start_y = e.originalEvent.changedTouches[0].pageY
          }
        )
        scroll_wrapper.on(
          "touchmove",
          function(e) {
            let me = $(this)
            let sc_top_y = me.scrollTop()
            let touch_y = e.originalEvent.changedTouches[0].pageY
            let dist = touch_start_y - touch_y
            let inner_h = me.get(0).scrollHeight
            let offset_h = me.get(0).offsetHeight
            let bottom = offset_h + sc_top_y
            let diff = inner_h - bottom
            if (sc_top_y <= 0 && dist <= 0) {
              e.preventDefault()
            } else if (-2 < diff && diff < 2 && 0 < dist) {
              e.preventDefault()
            }
            let height = me.height()
          }
        )
      }
      this.init()
    }()

    $.ji_popup_with_react = new function() {
      let self_ = this
      this.react_props_ = {}
      this.init = function() {
        this.popup_inner_content = $(".popup_wrapper.base .popup_inner")
        this.popup_header_title = $(".popup_wrapper.base .popup_header .header_title")
        this.popup_loading = $(".popup_wrapper.base .popup_loading")
        this.popup_inner_content_pure = document.getElementById('common_popup_content')
      }

      this.show_popup_with_component_name = function(name, title, props) {
        self_.react_props_ = props || {}
        self_.show_popup(reactComponents[name], title)
      }

      this.show_popup = function(render_obj, title) {
        $.ji_popup.scroll_to(0)
        $.ji_popup.show_popup_with_id("popup_with_api")
        ReactDOM.unmountComponentAtNode(this.popup_inner_content_pure)
        if (typeof render_obj.prototype.popupWillShow === 'function') {
          render_obj.prototype.popupWillShow()
        }
        let render = render_obj.prototype.render()
        ReactDOM.render(render, this.popup_inner_content_pure)
        self_.popup_loading.hide()
        this.popup_header_title.html(title)
      }

      this.set_title = function(title) {
        this.popup_header_title.html(title)
      }

      this.init()
    }()

    popup_callbacks = {
      assessment_callback: function(e, data) {
        $.hika_assessment_form(popup_wrapper, data)
      },
    }
  })
}(jQuery))
