"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBeforePopStateEvent = void 0;
var react_1 = require("react");
var DUMMY_HISTORY_STATE = 'DUMMY_HISTORY_STATE';
var useBeforePopStateEvent = function (popStateCallback) {
    var handlePopState = function (event) {
        if (event.state === DUMMY_HISTORY_STATE) {
            popStateCallback();
        }
    };
    (0, react_1.useEffect)(function () {
        // こいつがないとこのページを離れるで離れられない
        window.history.replaceState(DUMMY_HISTORY_STATE, '', null);
        // こいつがないとChromeに対策されてるのでpopstateイベントが発火しない
        window.history.pushState(null, '', null);
        window.addEventListener('popstate', handlePopState, false);
    });
    return function () { window.history.go(-2); };
};
exports.useBeforePopStateEvent = useBeforePopStateEvent;
