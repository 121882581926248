import React from 'react'
import PrefectureSelectBtn from './prefecture_select_btn'

class PrefectureSelectorPopup extends React.Component {

  componentDidUpdate() {
    if (this.props.store.isOpenAccordion === false) {
      jQuery.ji_popup.close_popup()
    }
  }

  renderCell(param) {
    return (
      <li key={param.id} className="cat-item">
        <PrefectureSelectBtn param={param} store={this.props.store}/>
      </li>
    )
  }

  renderTitleCell(param) {
    return (
      <h3 key={param} className="">
        {param}
      </h3>
    )
  }

  renderList(key, params) {
    let title = this.renderTitleCell(key)
    let cells = params.map((param) => this.renderCell(param))
    return (
      <li className="category_navigation mini" key={key}>
        {title}
        <ul className="">
          {cells}
        </ul>
      </li>
    )
  }

  renderTable() {
    let prefs = this.props.store.prefectures
    let keys = Object.keys(prefs)
    let list = keys.map((key) => {
      let param = prefs[key]
      return this.renderList(key, param)
    })
    return (
      <ul >
        {list}
      </ul>
    )
  }

  renderHeader() {
    if (this.props.store.isOpenAccordion === false && this.props.store.isPrefectureSelected()) {
      return null
    }
    return (
      <div className="prefecture-selector__header">
        <div className="prefecture-selector__header__selectall">
          <PrefectureSelectBtn store={this.props.store} param={{name: "全国", id: undefined}} />
        </div>
        <p className="prefecture-selector__header__description">
          地域をクリックして絞り込む
        </p>
      </div>
    )
  }

  render() {
    let table = this.renderTable()

    return (
      <div >
        {table}
      </div>
    )
  }
}

class PrefectureSelectorPopupWrapper extends React.Component {
  render() {
    if (typeof document === 'undefined') {
      return (<div></div>)
    }
    return (
      <PrefectureSelectorPopup store={jQuery.ji_popup_with_react.react_props_.store} />
    )
  }
}

window.reactComponents.PrefectureSelectorPopupWrapper = PrefectureSelectorPopupWrapper
export default PrefectureSelectorPopupWrapper
