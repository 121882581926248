// FIXME: flow を導入する
import React from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import {observer} from 'mobx-react'

/*
 * @brief input Email
 * */
@observer
class AssessmentFormInputEmailView extends React.Component {

  constructor(props) {
    super(props)
    this.othersValue = 'others'
    this.domains = [
      'docomo.ne.jp',
      'softbank.ne.jp',
      'ezweb.ne.jp',
      'yahoo.co.jp',
      'gmail.com',
      'icloud.com',
    ]
    this.initState()
    this.onChangeLocal = this.onChangeLocal.bind(this)
    this.onChangeDomain = this.onChangeDomain.bind(this)
    this.onChangeFreeWord = this.onChangeFreeWord.bind(this)
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      nextState.local !== this.state.local ||
      nextState.domain !== this.state.domain ||
      nextState.freeWord !== this.state.freeWord
    ) {
      const {store} = this.props
      store.setValue([
        nextState.local,
        nextState.domain === this.othersValue ? nextState.freeWord : nextState.domain
      ].join('@'))
    }
  }

  initState() {
    const {store} = this.props
    const splitValue = store.getValue().split('@')
    const notOthers = ['', ...this.domains].some((domain) => domain === (splitValue[1] || ''))
    this.state = {
      local: splitValue[0] || '',
      domain: notOthers ? (splitValue[1] || '') : this.othersValue,
      freeWord: notOthers ? '' : splitValue[1],
    }
  }

  onChangeLocal(e) {
    this.setState({
      local: e.target.value,
    })
  }

  onChangeDomain(e) {
    this.setState({
      domain: e.target.value
    })
  }

  onChangeFreeWord(e) {
    this.setState({
      freeWord: e.target.value
    })
  }

  renderSelect() {
    return (
      <select className={classnames('input select', {
        'is-other': this.state.domain === this.othersValue,
      })} value={this.state.domain} onChange={this.onChangeDomain}>
        <option value="">選択してください</option>
        {this.domains.map((domain) => (<option key={domain} value={domain}>{domain}</option>))}
        <option value={this.othersValue}>その他</option>
      </select>
    )
  }

  renderFreeWord() {
    const {store} = this.props
    if (this.state.domain !== this.othersValue) {
      return null
    }
    return (
      <input onChange={this.onChangeFreeWord} value={this.state.freeWord} placeholder="example.com" type="text" className="input freeWord" />
    )
  }

  render() {
    const {store} = this.props
    return (
      <div className="email">
        <input onChange={this.onChangeLocal} value={this.state.local} type="text" className="input local" />
        <span>@</span>
        <span className="domain">
          {this.renderSelect()}
          {this.renderFreeWord()}
        </span>
        <input name={store.getName()} value={store.getValue()} type="hidden" />
      </div>
    )
  }
}
export default AssessmentFormInputEmailView

