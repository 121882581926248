// @flow
import React from 'react'
import {observer} from 'mobx-react'

type Props = {
  store: any,
}

@observer
class CategorySelectorList extends React.Component<Props, void> {
  list: ?HTMLElement

  componentDidUpdate() {
    this.props.store.calculateListHeight(this.list)
  }

  render() {
    const {store} = this.props
    if (!store.showPopup) {
      return null
    }
    return (
      <ul className={store.listClassNames} ref={(node) => {
        this.list = node
      }} style={{height: store.listHeight || undefined}}>
        {store.childList.map((item) => (
          <li
            key={item.current_name}
            className="table_view_cell active"
            onClick={item.has_child
              ? store.onClickFetch(item.current_slug, item.current_name)
              : store.onClickSelect(item.current_slug, item.current_name)
            }
          >
            <span className="title">{item.current_name}</span>
            {item.has_child ? (
              <span className="right_icon" />
            ) : (
              <span className="select">選択</span>
            )}
          </li>
        ))}
        {store.childList.length > 0 ? (
          <li className="table_view_cell reset"
            onClick={store.onClickSelect(store.prevSlug, store.prevName)}>
            <span className="title">リセットして閉じる</span>
            <span className="select">選択</span>
          </li>
        ) : null}
      </ul>
    )
  }
}

export default CategorySelectorList
