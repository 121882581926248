// @flow
import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import InputDynamicOptionStore from '~/stores/forms/builder/input_dynamic_option_store'
import InputStore from '~/stores/forms/builder/input_store'
import type {MultiSelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

export default class InputMultiSelectStore extends InputStore<string[], MultiSelectParam> {
  @observable options = [];
  constructor(param: MultiSelectParam) {
    super(param)
    const self = (this: any)
    self.handleChange = this.handleChange.bind(this)
  }

  init(param: MultiSelectParam) {
    super.init(param)
    param.options.forEach(((option) => {
      let store = new InputDynamicOptionStore(option)
      store.setDelegate(this)
      this.options.push(store)
    }))
    this.validate()
  }

  getValue(): string[] | typeof undefined {
    return this.createValue(this.options)
  }

  handleChange(): void {
    super.handleChange()
    this.validate()
  }

  createValue(options: InputDynamicOptionStore[]): string[] {
    return _.compact(options.map((option) => {
      return (option.isChecked() === true) ? option.value.toString() : undefined
    }))
  }

  getValueText(): string {
    const checkedLabels = _.compact(this.options.map((option) => {
      return (option.isChecked() === true) ? option.label : undefined
    }))
    return _.join(checkedLabels, ',')
  }
}
