import {init} from '@sentry/browser'
(() => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  init({
    dsn: 'https://d176ff07f4e98003a0b35d76f812a6c5:c04cfe62145356f66290e5340e3766f0@o504840.ingest.us.sentry.io/4508379006828544',
    // ビルド時に挿入すると docker build のキャッシュが効かなくなるので、実行時に挿入する (hypernova 以外)
    release: (window && window.gon && window.gon.release_tag) || RELEASE_TAG,
    environment: RAILS_ENV,
    ignoreErrors: [
      'Blocked a frame with origin "https://hikakaku.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
      'Blocked a frame with origin "https://hikakaku.com" from accessing a frame with origin "https://www.facebook.com". Protocols, domains, and ports must match.',
      // FIXME: HIKAKAKU-3180; このエラーは取るに足りないが、しかしこの ignore 条件は誤爆しかねないから直したい
      "undefined is not an object (evaluating 'e[0]')",
      "Cannot read property '0' of undefined",
      // FIXME: なんかエラー通知件数が多すぎるから無視。他のブラウザで同じエラーが捕捉できるから問題なし
      //        https://sentry.io/organizations/jiraffe-inc/issues/1674920070/?project=158320&query=is%3Aunresolved&sort=freq&statsPeriod=14d
      'TypeError: f is null',
      "SyntaxError: Unexpected token '<'"
    ],
    blacklistUrls: [
      /connect\.facebook\.net\/en_US\/fbevents\.js/,
      /extension:\/\//
    ]
  })
})()
