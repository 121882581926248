"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var Form = function (props) {
    var _a = (0, react_1.useState)(''), text = _a[0], setText = _a[1];
    return (react_1.default.createElement("div", { className: "unreadRequestWindow-RequestDetailForm_Wrapper" },
        react_1.default.createElement("textarea", { value: text, placeholder: "\u30E1\u30C3\u30BB\u30FC\u30B8\u3092\u5165\u529B...", onChange: function (e) { return setText(e.target.value); }, className: "unreadRequestWindow-RequestDetailForm_TextArea" }),
        react_1.default.createElement("button", { disabled: props.isMessageSubmitting || Boolean(!text), onClick: function () { return props.onClickSubmitButton(text); }, className: "sw-Button sw-Button-controlMain unreadRequestWindow-RequestDetailForm_SubmitButton" }, "\u9001\u4FE1\u3059\u308B")));
};
exports.default = Form;
