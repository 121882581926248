// @flow
/*ボタン押した時のスクロールアクション*/
/*任意のhtml（class/ID）から指定したタグまでスクロールします。*/
type Options = {|
  btnSelector: string,
  targetSelector: string,
|};

const PageScroll = function(options: Options) {
  let btnObj,
    scrollTarget,
    scrollTop,
    body,
    scrollMargin = 100

  body = $("html, body")
  btnObj = $(options.btnSelector)
  scrollTarget = $(options.targetSelector)
  if (typeof btnObj === "undefined") {
    return
  }

  btnObj.click((e: Event) => {
    e.preventDefault()
    const offset = scrollTarget.offset()
    scrollTop = offset.top - scrollMargin
    body.animate({scrollTop: scrollTop}, '1000', 'swing')
  })
}

$.fn.PageScroll = PageScroll

$(() => {
  const btnSelector = '.page_scroller'
  const ps = $(btnSelector)
  ps.PageScroll({
    btnSelector,
    targetSelector: ps.data('target_selector'),
  })
})

