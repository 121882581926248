"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var STICKY_CLASS = 'gadget_column_sidebar__recent-blogs--sticky';
var HIDDEN_CLASS = 'gadget_column_sidebar__recent-blogs--hidden';
var HIDE_BUTTON_VISIBLE_CLASS = 'gadget_column_sidebar__recent-blogs-hide-button--visible';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    default_1.prototype.connect = function () {
        var _this = this;
        var element = this.element;
        var border = window.pageYOffset + element.getBoundingClientRect().bottom;
        window.addEventListener('scroll', function () {
            var isScrollOver = window.pageYOffset + window.innerHeight > border;
            if (isScrollOver) {
                element.classList.add(STICKY_CLASS);
                _this.showHideButton();
            }
            else {
                element.classList.remove(STICKY_CLASS);
                _this.hideHideButton();
            }
        });
    };
    default_1.prototype.hide = function () {
        var element = this.element;
        element.classList.add(HIDDEN_CLASS);
    };
    default_1.prototype.showHideButton = function () {
        this.hideButtonTarget.classList.add(HIDE_BUTTON_VISIBLE_CLASS);
    };
    default_1.prototype.hideHideButton = function () {
        this.hideButtonTarget.classList.remove(HIDE_BUTTON_VISIBLE_CLASS);
    };
    default_1.targets = ['hideButton'];
    return default_1;
}(stimulus_1.Controller));
exports.default = default_1;
