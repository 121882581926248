// @flow
import _ from 'lodash'
import * as React from 'react'
import ReactDOM from 'react-dom'
import SelectAsync from 'react-select/async'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type {SelectParam} from '~/flowtyped/stores/forms/form_builder_store_types'

type Option = {
  value: string,
  label: string
}

/*
 * @brief input セレクト
 * */
@observer
export default class InputReactSelectAsyncView extends React.Component<Props<string, SelectParam>> {
  ref: {current: null | React.ElementRef<any>}

  constructor() {
    super()
    // あんまり使いたくないけどとりあえず findDOMNode(this) してコケてたから応急処置
    this.ref = React.createRef()
  }

  render() {
    const {store} = this.props
    const value = store.getValue()
    return (
      <div ref={this.ref} className={store.menu_status} style={store.reactSelectWrapperElmStyle} data-value={store.inputValue}>
        <SelectAsync
          name={store.getName()}
          value={value && {value: value, label: value}}
          onChange={this.onChange}
          onMenuOpen={store.onOpen}
          onMenuClose={store.onClose}
          onFocus={store.onFocus}
          onInputChange={store.onInputChange}
          loadOptions={store.loadOptions}
          cacheOptions={false}
          filterOption={store.filterOption}
          isClearable={true}
          placeholder="入力してください"
          loadingMessage={() => "検索中です…"}
          noOptionsMessage={() => "候補なし"}
        />
      </div>
    )
  }

  componentDidUpdate() {
    const {store} = this.props
    store.setReactSelectWrapper(this.ref.current)
    store.onUpdate()
  }

  componentDidMount() {
    const {store} = this.props
    store.setReactSelectWrapper(this.ref.current)
  }

  onChange = (e: Option): void => {
    const {store} = this.props
    if (e === null) {
      store.setValue('')
      return
    }
    store.setValue(e.value)
  }
}
