import React from 'react'
import PropTypes from 'prop-types'
import SearchStoreReviewFormInner from '~/components/forms/review_form/search_store_review_form_inner'
import ReviewFormStore from '~/stores/forms/reviews/fixed_review_form_store'

export default class FixedReviewForm extends React.Component {
  static propTypes = {
    is_history_backable: PropTypes.bool
  }

  constructor() {
    super()
    this.reviewFormStore = new ReviewFormStore()
  }

  render() {
    this.reviewFormStore.setIsHistoryBackable(this.props.is_history_backable)
    return <SearchStoreReviewFormInner reviewFormStore={this.reviewFormStore} />
  }
}
