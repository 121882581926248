import React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'

@observer
export default class RegisterUser extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {store} = this.props
    return (
      <div className="hikakaku_form">
        <div className="text-center">
          <p>
            {store.description()}
          </p>
        </div>
      </div>
    )
  }
}
