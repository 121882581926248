import React from 'react'
import ReactDOM from 'react-dom'
import PopupLoading from '~/components/popup/loading.jsx'
import RegisterUserForm from '~/components/forms/review_form/register_user_form.jsx'
import ReviewFormInner from '~/components/forms/review_form/review_form_inner.js'
import {observer} from 'mobx-react'

@observer
class ReviewForm extends React.Component {

  constructor(props) {
    super(props)
  }

  renderByState() {
    let reviewFormStore = this.props.reviewFormStore
    switch (reviewFormStore.state) {
    case reviewFormStore.STATE_INIT:
      return (<PopupLoading />)
      break
    case reviewFormStore.STATE_IDLE:
      return (<ReviewFormInner reviewFormStore={reviewFormStore} />)
      break
    case reviewFormStore.STATE_SUCCESS:
      return (<RegisterUserForm reviewFormStore={reviewFormStore.user_info}/>)
      break
    }
  }

  render() {
    return (
      <div>
        {this.renderByState()}
      </div>
    )
  }
}

export default ReviewForm
