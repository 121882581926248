import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import Util from '@hikakaku/shared/util/util'
class TopKeywordList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: []
    }
  }

  UNSAFE_componentWillMount() {
    let topKeywordsRequestData = {
      url: window.jis_.c_root_uri + "/api/search_keywords/popular",
      dataType: "json",
      type: 'GET',
      onSuccess: (function(json) {
        this.setState({
          items: this.state.items.concat(json)
        })
      }).bind(this),
      onError: function(request, textStatus, errorThrown) {
        // FIXME: HIKAKAKU-3030
        window.alert("API ERROR")
      }
    }

    window.JiApiManager.request(topKeywordsRequestData)
  }

  getRankingbadge(rank) {
    let ret = "normal"
    switch (rank) {
    case 1: {
      ret = "gold"
      break
    }
    case 2: {
      ret = "silver"
      break
    }
    case 3: {
      ret = "bronze"
      break
    }
    default: {
      ret = "normal"
    }
    }
    return ret
  }


  createList() {
    let list = _.map(this.state.items, "keyword")
    let ret = list.map((keyword, index) => {
      return (
        <li className="topKeywordList" key={index}>
          <a className="topKeyword" href={window.jis_.root_uri + "/kaitori/search?search_words=" + keyword}>
            <span className={"ranking_badge " + this.getRankingbadge(index + 1)}>
              {index + 1}
            </span>
            {Util.substr(keyword, 15)}
            <span className="topKeywordArrow"></span>
          </a>
        </li>
      )
    })
    return ret
  }

  render() {
    let items = this.createList()
    return (
      <ul className="buy_intro_list">
        {items}
      </ul>
    )
  }
}

export default TopKeywordList
