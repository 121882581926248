"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignItemValueToInputProps = void 0;
var id = function (x) { return x; };
var assignFn = function (item) { return ({
    name: function (props) { return (__assign(__assign({}, props), { defaultValue: item.name })); },
    category: function (props) { return (__assign(__assign({}, props), { defaultValue: item.categories.find(function (cat) { return props.options.some(function (opt) { return opt.value === cat; }); }) })); }
}); };
var rawName = function (props) {
    return props.rawName || props.name;
};
var assignItemValueToInputProps = function (item, inputProps) { return (inputProps.map(function (props) { return (assignFn(item)[rawName(props)] || id)(props); })); };
exports.assignItemValueToInputProps = assignItemValueToInputProps;
