import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'
import Util from "@hikakaku/shared/util/util"

@observer
export default class ReviewFormInnerRateStar extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  get reviewStarBg()	{
    return '/wp-content/themes/hikakaku_theme/images/review_star_bg.png'
  }

  componentDidMount() {
    this.initReviewStar()
  }

  initReviewStar() {
    if (typeof $ === "undefined") {
      return
    }
    if (typeof $.review_star === "undefined") {
      return
    }
    if (typeof $.review_star.set_star_size === "undefined") {
      return
    }
    $.review_star.set_star_size($(".popup_wrapper.base"))
  }

  constructor(props) {
    super(props)
    this.onChangeRateStar = this.handleChangeRateStar.bind(this)
    this.onClickRateStar = this.handleClickRateStar.bind(this)
    this.onMouseEnterRateStar = this.handleMouseEnterRateStar.bind(this)
    this.onMouseLeaveRateStar = this.handleMouseLeaveRateStar.bind(this)
  }

  handleChangeRateStar(e) {
    this.props.reviewFormStore.userInputEnterpriseName = e.target.value
  }

  handleMouseEnterRateStar(index) {
    return () => {
      this.props.reviewFormStore.setRateStarHoverIndex(index + 1)
    }
  }

  handleMouseLeaveRateStar() {
    this.props.reviewFormStore.setRateStarHoverIndex(null)
  }

  renderRateStar({rating = 0, max = 5, hover = true}) {
    const stars = [...Array(max)].map((v, index) => {
      const enableStar = index < (this.props.reviewFormStore.rateStarHoverIndex !== null ? this.props.reviewFormStore.rateStarHoverIndex : rating)
      const props = {
        className: "review_star",
        onClick: this.onClickRateStar(index),
      }
      if (!Util.isMobile() && hover) {
        props.onMouseEnter = this.onMouseEnterRateStar(index)
        props.onMouseLeave = this.onMouseLeaveRateStar
      }
      return (
        <div key={index} {...props} >
          <img src={this.reviewStarBg} className="lazyload" />
          <span className="star_wrapper" style={{backgroundSize: "100%", width: enableStar ? "100%" : 0}} />
        </div>
      )
    })
    return (
      <div className="reviewpopup-RateStar_wrapper">
        <div className="review_rate_star">
          {stars}
        </div>
      </div>
    )
  }

  handleClickRateStar(index) {
    return (e) => {
      e.preventDefault()
      this.props.reviewFormStore.setRating(index + 1)
    }
  }

  render() {
    return (
      <div className="reviewpopup-RateStar">
        <div className="reviewpopup-RateStar_title">総合評価</div>
        <div>
          {this.renderRateStar({rating: this.props.reviewFormStore.rating, max: 5, hover: true})}
        </div>
      </div>
    )
  }
}
