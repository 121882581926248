import $ from 'jquery'
import eventManager from '~/util/event_manager'

eventManager.onReady(() => {
  const nav_input = $('.js-nav-input')
  const body = $('body')
  const fixed = 'is-body-fixed'

  nav_input.click(function() {
    body.toggleClass(fixed)
  })

  if (nav_input.prop('checked')) {
    body.addClass(fixed)
  } else {
    body.removeClass(fixed)
  }
})
