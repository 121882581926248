import React from 'react'
import MagiReviewFormInner from '~/components/forms/review_form/magi_review_form_inner'
import MagiReviewFormStore from '~/stores/forms/reviews/magi_review_form_store'

export default class MagiReviewForm extends React.Component {
  constructor() {
    super()
    this.reviewFormStore = new MagiReviewFormStore()
  }

  render() {
    return <MagiReviewFormInner reviewFormStore={this.reviewFormStore} />
  }
}
