// @flow

const onClick = (name: string, features: string) => (e: MouseEvent) => {
  e.stopPropagation()
  e.preventDefault()
  const obj = $(e.currentTarget)
  const link = obj.data("link")
  window.open(
    link,
    name,
    features,
  )
}

if (typeof $ !== 'undefined') {
  $(() => {
    $(".facebook-iine").click(onClick(
      'sns_window',
      'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=!',
    ))
    $(".twitter-tweet").click(onClick(
      'sns_window',
      'width=550,height=450,personalbar=0,toolbar=0,scrollbars=1,resizable=1',
    ))
    $(".hatenabtn2").click(onClick(
      'HatenaWindow',
      'width=550, height=350, menubar=no, toolbar=no, scrollbars=yes',
    ))
    $(".gplusbtn").click(onClick(
      'Gwindow',
      'width=480, height=500, menubar=no, toolbar=no, scrollbars=yes',
    ))
    $(".feedlybtn").click(onClick(
      'Feedlywindow',
      'width=550, height=350, menubar=no, toolbar=no, scrollbars=yes',
    ))
  })
}
