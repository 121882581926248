import {action} from "mobx"
import ReviewFormStoreBase from "~/stores/forms/reviews/review_form_store_base"

const REVIEWS_PATH = '/reviews/new'
const MESSAGES_PATH = '/users/estimates/messages'

export default class StoreReviewFormStore extends ReviewFormStoreBase {
  init() {
    this.fetchReviewInit()
  }

  getInitApiUrl() {
    return "/api/reviews/stores/init"
  }

  getPostApiUrl() {
    return "/api/reviews/stores"
  }

  @action.bound
  handlePostSuccess() {
    location.href = this.comeFromMessagePage()
      ? MESSAGES_PATH
      : REVIEWS_PATH
  }

  comeFromMessagePage() {
    return (new URL(window.location.href)).searchParams.get('referred_from') === 'messages'
  }
}
