import $ from 'jquery'
import eventManager from '~/util/event_manager'
import GoogleAnalyticsEventTracker from '~/jquery-modules/google_analytics_event_tracker'

class BlogContentTracker {
  constructor() {
    eventManager.onReady((() => {
      this.onReady()
    }))
  }

  onReady() {
    $('.blog .entry-content a').on('click', this.onClick)
  }
  onClick(e) {
    const target = $(e.currentTarget)
    if (target.hasClass('js-ignore-blog-content-tracker')) { return }
    const url = target.attr('href')
    GoogleAnalyticsEventTracker.postGAEvent('ブログリンク', 'click', url)
  }
}
export default new BlogContentTracker()
