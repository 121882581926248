// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import {observer} from 'mobx-react'
import {type Props} from '~/components/forms/input_wrapper'
import type {InputParam} from '~/flowtyped/stores/forms/form_builder_store_types'

/*
 * @brief input電話番号
 * */
@observer
export default class InputTelView extends React.Component<Props<string, InputParam>> {

  onChange = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    const {store} = this.props
    const value = e.target.value.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
    })
    store.setValue(value)
  }

  onFocus = (): void => {
    const {store} = this.props
    store.uncheck()
  }

  onBlur = (): void => {
    const {store} = this.props
    store.validate()
    store.handleBlur()
  }

  render() {
    const {store} = this.props

    return (
      <input
        value={store.getValue()}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        type="tel"
        name={store.getName()}
        maxLength={store.maxlength}
        placeholder={store.placeholder}
        className="wide input" />
    )
  }
}
