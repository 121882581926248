import $ from 'jquery'
import ReactDOM from 'react-dom'

class BlogCardPhoneNumberCreater {

  constructor(obj) {
    this.init()
  }

  init() {

    let objs = $(".rails_phone_number")

    if (objs.length <= 0) {
      return
    }

    let post_ids = []
    objs.each((index, obj) => {
      let jqObj = $(obj)
      post_ids.push(jqObj.data('postid'))
    })

    let requestData = {
      url: window.jis_.c_root_uri + "/api/blog_card/posts",
      dataType: "json",
      type: 'POST',
      request_data: {post_ids: post_ids},
      onSuccess: function(json) {

        Object.keys(json).forEach((key) => {
          let param = json[key]

          if (typeof param === undefined ||
            typeof param === null) {
            return
          }

          if (param.post_type != "store") {
            return
          }

          if (param.is_phone_open === false) {
            return
          }

          let obj = $('.rails_phone_number[data-postid="' + key + '"]')
          obj.html("<div class='fnt-size-10'><a href='tel:" + param.phone_number + "'>" + param.phone_number + "</a>受付時間:" + param.phone_open_time + "〜" + param.phone_close_time)

        })

      },
      onError: function(request, textStatus, errorThrown) {
        // FIXME: HIKAKAKU-3030
        console.log(request, textStatus, errorThrown)
      }
    }
    window.JiApiManager.request(requestData)
  }

}

if (typeof document !== "undefined") {
  $(document).ready(function() {
    //new BlogCardPhoneNumberCreater();
  })
}
