import React from 'react'
import PropTypes from 'prop-types'
import {observer} from 'mobx-react'

@observer
export default class ReviewFormInnerMapInput extends React.Component {
  static propTypes = {
    reviewFormStore: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.onChangeMapName = this.handleChangeMapName.bind(this)
  }

  handleChangeMapName(e) {
    this.props.reviewFormStore.userInputMapName = e.target.value
  }

  render() {
    const reviewFormStore = this.props.reviewFormStore

    return (
      <div className="reviewpopup-Form_content">
        <div className="reviewpopup-Form_title">
          <label htmlFor="reviewer_map">地域</label><span className="optional">任意</span><span className="co-gray-2">(市区町村まで記載をお願いします)</span>
        </div>
        <div>
          <input className="reviewpopup-Form_textinput" type="text" id="reviewer_map" name="reviewer_map" placeholder="例:新宿区" value={reviewFormStore.userInputisMapName} onChange={this.onChangeMapName}/>
        </div>
      </div>
    )
  }
}
