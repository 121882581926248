// @flow
import _ from 'lodash'
import {observable, computed, reaction} from 'mobx'
import * as formBuilderTypes from '~/constants/forms/form_builder_constants'
import StoreBase from '~/stores/store_base'
import FormBuilderValidator from '~/validator/forms/form_builder_validator'

export default class InputStore<V, P> extends StoreBase {

  @observable value: ?V;
  @observable type = formBuilderTypes.FORM_INPUT_TYPE_TEXT;
  @observable label = '';
  @observable description = '';
  @observable name: string | typeof undefined;
  @observable placeholder = '';

  @observable necessary = false;
  @observable disabled = false;
  @observable alertText = '';
  @observable useAlert = false;
  @observable precheck = true;
  @observable isChecked = false;

  maxlength = 65535;

  constructor(param: P) {
    super()
    const self = (this: any)
    self.handleChange = this.handleChange.bind(this)
    self.handleBlur = this.handleBlur.bind(this)
    this.init(param)
  }

  init(param: P): void {
    param = Object.assign({}, formBuilderTypes.DEFAULT_INPUT_PARAM, param)
    this.name = param.name
    this.type = param.type
    this.label = param.label
    this.description = param.description
    this.value = param.defaultValue || ''
    this.maxlength = param.maxlength
    this.placeholder = param.placeholder
    this.disabled = param.disabled
    this.necessary = param.necessary
    this.precheck = param.precheck
    this.onChange = param.onChange
    this.onBlur = param.onBlur
    this.useAlert = param.useAlert
    this.validate()
  }

  // 値を送信したくない場合は false を返す想定
  active(): boolean {
    return true
  }

  getName(): string | typeof undefined {
    return this.active() ? this.name : undefined // name を設定しないことで無効なフィールドを作る
  }

  // force = true の場合は active でなくても表示する
  getValue(force: boolean = false): V | typeof undefined {
    if (!force && !this.active()) { return undefined }

    return this.value === null ? undefined : this.value
  }

  idForLabel(): string | typeof undefined {
    return this.name && this.name.replace(/[\][]/g, '_')
  }

  getValueText(): string | typeof undefined {
    const value = (this.getValue(): any)
    return value && value.toString ? value.toString() : undefined
  }

  setValue(value: ?V): void {
    this.value = value
    this.handleChange()
  }

  handleChange(): void {
    if (typeof this.onChange === 'undefined') {
      return
    }
    if (typeof this.onChange === 'object' && 0 < this.onChange.length) {
      this.onChange.forEach((handler) => {
        if (typeof handler !== 'function') {
          return
        }
        handler(this)
      })
      return
    }
    if (typeof this.onChange !== 'function') {
      return
    }
    this.onChange(this)
  }

  handleBlur(): void {
    if (typeof this.onBlur !== 'function') {
      return
    }
    this.onBlur(this)
  }

  uncheck(): void {
    this.isChecked = false
  }

  setAlertText(text: string): void {
    this.alertText = text
  }

  validate(): void {
    if (this.precheck) {
      this.isChecked = FormBuilderValidator.preValidateInput(this)
    }
  }
}
