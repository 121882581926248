import Util from '@hikakaku/shared/util/util'

!(function() {

  if (typeof document === 'undefined') {
    return
  }

  !(function($) {
    $(document).ready(function() {
      let flg = 0,
        scrollBase = 300 //固定メニューが切れるウインドウスクロール幅
      window.onscroll = function() {
        let scrollTop =
                    document.documentElement.scrollTop || // IE、Firefox、Opera
                    document.body.scrollTop // Chrome、Safari
        if (flg == 0 && scrollTop > scrollBase) {
          $("#topMenu").slideDown("slow")
          flg = 1
        }
        if (flg == 1 && scrollTop < scrollBase) {
          flg = 0
          $("#topMenu").slideUp("slow")
        }
      }
    })
  }(jQuery))

  //検索フォーム
  !(function($) {

    if (Util.isMobile() == true) {
      let text_form = $('.sfy_form.sfy_s')
      let scroll_margin = 10
      let body = $("html, body")

      text_form.on("focus", function() {
        let scroll_target = $(this)
        let offset = scroll_target.offset()
        let scroll_top = offset.top - scroll_margin //上のバーの分マージンを引く

        body.animate({scrollTop: scroll_top}, '500', 'swing', function() {
        })

      })
      text_form.on("focusout", function() {
        if (Util.isMobile() == true) {
        }
      })
    }

  }(jQuery))


  !(function($) {
    let tips_root,
      tips_search_box,
      search_box,
      search_box_text = [
        "iPhone6 64GB SoftBank",
        "iPad Air 32GB 第5世代",
        "SOL26",
      ],
      update_timer,
      current_show_text_index,
      max_wait_count = 15

    $(document).ready(function() {
      current_show_text_index = 0
      search_box = $(".sfy_wrap .sfy_form.sfy_s")
      tips_root = $(".tips_balloon")
      tips_search_box = $(".tips_search_box p")

      if (search_box.length <= 0 ||
                tips_root.length <= 0 ||
                tips_search_box <= 0) {
        return
      }
      if (Util.isMobile() == true) {
        return
      }
      tips_search_box.text("")
      search_box.focus(function() {
        show_balloon()
      }).blur(function() {
        hide_balloon()
      })
      let	form_offset = search_box.offset().top
      let is_focus_once = false
      let check_form_in_window = function() {
        return
        /*一回だけ*/
        if (is_focus_once === true) {
          return
        }
        let w_h = $(window).height()
        let current_scroll_y = $(window).scrollTop() + w_h - 100
        if (form_offset <= current_scroll_y && current_scroll_y < form_offset + w_h - 150) {

          is_focus_once = true
          show_balloon()
          search_box.focus()
        }
      }
      $(window).scroll(function() {
        check_form_in_window()
      })
      check_form_in_window()

    })

    /*PCは検索にフォーカスさせる*/
    var hide_balloon = function() {
      tips_root.removeClass("show")
      clearInterval(update_timer)
    }

    var show_balloon = function() {
      let text_count = 0
      tips_root.addClass("show")
      tips_search_box.text("")
      clearInterval(update_timer)
      let wait_count = max_wait_count
      update_timer = setInterval(function() {
        let txt = search_box_text[current_show_text_index].substr(0, text_count)
        tips_search_box.text(txt)
        ++text_count
        if (txt === search_box_text[current_show_text_index]) {
          if (wait_count <= 0) {
            tips_search_box.text("")
            ++current_show_text_index
            text_count = 0
            wait_count = max_wait_count
            if (search_box_text.length <= current_show_text_index) {
              current_show_text_index = 0
            }
          }
          --wait_count
        }
      }, 150)
    }
  }(jQuery))

}())
